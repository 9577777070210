import React, { Component } from 'react'
import './stylesheet.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import moment from 'moment';
import { storeValidationMessages } from 'actions/user.actions'

class CFormField extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e) {
    let { field, userData, handleChange } = this.props;

    // Remove email existing message
    if(field === 'email' && Object.keys(userData.validation_messages || {}).length) {
      this.props.storeValidationMessages({});
    }

    handleChange(e);
  }

  render() {
    let t = this.context.t
    let field = this.props.field || ''
    let error = this.props.error || ''
    let status = this.props.status || ''
    let placeholder = t(`${field}_placeholder`) || ""

    return (
      <Form.Group className={this.props.classNames || ""} controlId={`controlId-${field}`}>
        <Form.Label>
          {t(field)}&nbsp;
          { this.props.isRequired &&
            <span className='text-red'>*</span>
          }
        </Form.Label>
        <Form.Control
          readOnly={this.props.isSubmitting || false}
          autoComplete={this.props.autoComplete || 'on'}
          isInvalid={!!error || !!status}
          onChange={this.handleOnChange}
          onBlur={this.props.onBlur}
          defaultValue={this.props.values[field]}
          name={field}
          type={this.props.fieldType || 'text'}
          rows={this.props.rows}
          as={this.props.as}
          placeholder={placeholder}
          max={moment().format("YYYY-MM-DD")}
          disabled={this.props.disabled}
          checked={this.props.checked}
        />
        <Form.Control.Feedback type="invalid">
          {error || status}
        </Form.Control.Feedback>
      </Form.Group>
    )
  }
}

CFormField.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  userData: store.userData,
})

const mapDispatchToProps = {
  storeValidationMessages,
}

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(CFormField)
