import React, {Component, Fragment} from 'react';
import Routes from './config/routes'
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { connect } from 'react-redux'
import { COLOR_BY_ROLE, EMAILS_ABLE_TO_VIEW_BLACK_DESIGN } from 'config/constants'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CApp extends Component {
  render() {
    let { role, email } = this.props.currentUser;
    role = role === 'free_user' ? 'odin_user' : role
    let colorByRole = COLOR_BY_ROLE[role];
    colorByRole = EMAILS_ABLE_TO_VIEW_BLACK_DESIGN.includes(email) ? COLOR_BY_ROLE['black_design_user'] : colorByRole;

    if(!!colorByRole) {
      let style = document.documentElement.style;
      style.setProperty('--primary-color', colorByRole.primaryColor);
      style.setProperty('--darker-primary-color', colorByRole.darkerPrimaryColor);
      style.setProperty('--position-circle-border-color', colorByRole.positionCircleBorderColor);
      style.setProperty('--deck-darker-color', colorByRole.deckDarkerColor);
      style.setProperty('--deck-color', colorByRole.deckColor);
      style.setProperty('--position-circle-shadow-color', colorByRole.positionCircleShadowColor);
      style.setProperty('--selected-border-color', colorByRole.selectedBorderColor);
      style.setProperty('--selected-background-color', colorByRole.selectedBackgroundColor);
      style.setProperty('--text-color', colorByRole.textColor);
      style.setProperty('--header-background-color', colorByRole.headerBackgroundColor);
      style.setProperty('--page-background-color', colorByRole.pageBackgroundColor);
      style.setProperty('--page-background-deep-color', colorByRole.pageBackgroundDeepColor);
      style.setProperty('--page-background-light-color', colorByRole.pageBackgroundLightColor);
      style.setProperty('--button-background-color', colorByRole.buttonBackgroundColor);
      style.setProperty('--line-divider-color', colorByRole.lineDividerColor);
      style.setProperty('--matrix-square-background-color', colorByRole.matrixSquareBackgroundColor);
      style.setProperty('--position-circle-border-color-selected', colorByRole.positionCircleBorderColorSelected);
      style.setProperty('--button-subscribe-dark-color', colorByRole.buttonSubscribeDarkColor);
      style.setProperty('--button-subscribe-light-color', colorByRole.buttonSubscribeLightColor);
      style.setProperty('--button-subscribe-shadow', colorByRole.buttonSubscribeShadow);
      style.setProperty('--background-subscription-option-dark', colorByRole.backgroundSubscriptionOptionDark);
      style.setProperty('--background-subscription-option-light', colorByRole.backgroundSubscriptionOptionLight);
      style.setProperty('--special-price-color', colorByRole.specialPriceColor);
      style.setProperty('--button-special-subscribe-dark-color', colorByRole.buttonSpecialSubscribeDarkColor);
      style.setProperty('--button-special-subscribe-light-color', colorByRole.buttonSpecialSubscribeLightColor);
      style.setProperty('--title-subscription', colorByRole.titleSubscription);

    }

    return (
      <Fragment>
        <Routes/>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          limit={1}
         />
      </Fragment>
    );
  }
}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser.attributes,
})

const mapDispatchToProps = {}

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(CApp)
