import axios from 'axios';
import { batch } from 'react-redux'
import {
  gameDataGenerator,
} from './game_play_service';
import {
  updateGameData,
  fetchTurnRiverData
} from 'actions/game.actions';
import { updateDecisionFilter } from 'actions/hand-matrix.actions';
import { addDecisionToTree } from 'actions/decision.actions';
import { toast } from 'react-toastify';
import { notify } from 'utils/utils'
import { isEmpty, isUndefined } from 'lodash'

export const makeDecisionFlopRound = (dispatch, dataParams, decision) => {
  const { game_type, specs, stack_size, positions, sim_type, flops, node_count, nodes, view, currentPosition } = dataParams
  const params = {
    node_count: node_count + 1,
    game_type,
    specs,
    stack_size,
    positions,
    sim_type,
    flops,
    nodes,
    view,
    current_position: currentPosition
  }

  return axios.post('/nodes/get_nodes', params).then(res => {
    handleDispatch(dispatch, dataParams, decision, res.data.data)
    if(decision.action === 'f')
      notify("The hand is over", toast.info, {time: 3000});
  })
}

export const makeDecisionTurnRound = (dispatch, dataParams, decision, game) => {
  if(!dataParams.betCompleted && decision.action !== 'f') {
    return dispatch(fetchTurnRiverData({...dataParams, game: game}, decision))
  } else {
    handleDispatch(dispatch, dataParams, decision)
    if(decision.action === 'f')
      notify("The hand is over", toast.info, {time: 3000});
  }
  return 
}

const objectResultEndGame = (data, params) => {
  const newParams = gameDataGenerator(data, params)
  const { pot_oop, pot_ip, stackSize } = newParams
  const haveStackSize = !isEmpty(stackSize)
  const endGame = stackSize - pot_oop <= 0 && stackSize - pot_ip <= 0
  if (haveStackSize && endGame)  // only when this compare is true then set false
    newParams['betCompleted'] = false
  if (endGame)
    newParams['flops'] = []
  return newParams
}

const handleDispatch = (dispatch, params, decision, data = []) => {
  const newGameData = objectResultEndGame(data, params)
  newGameData['fetching'] = false
  batch(() => {
    dispatch(updateGameData(newGameData))
    dispatch(addDecisionToTree(decision))
    if (isUndefined(params.playTheBot)) dispatch(updateDecisionFilter(''))
  })
}
