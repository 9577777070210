import React, {Component} from 'react'
import { Spinner } from 'react-bootstrap'

class Loading extends Component {

  render() {
    return (
      <div className='loading-auth'>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }
}

export default Loading
