import axios from 'axios'

export const getAffiliateDiscountCode = async () => {
  let res = await axios.get('/affiliate_discount_code')

  return res
}

export const getAffiliateDiscountCodeTypes = (data, column = null) => {
  let result = []

  if (data.length > 0) {
    switch (column) {
      case 'AFFILIATE_CODES':
        result = data.map(element => element.affiliate_code); break
      case 'LINKS_LAYOUT':
        result = data.map(element => element.affiliate_url); break
      case 'PERCENT_DISCOUNT':
        let percentDiscount = {}

        data.forEach(element => {
          percentDiscount[element.affiliate_code] = element.discount_value
        })

        result = percentDiscount

        break
      case 'AFFILIATE_CODES_OBJECT':
        let affiliate_codes_object = {}

        data.forEach(element => {
          affiliate_codes_object[element.affiliate_code] = element.name
        })

        result = affiliate_codes_object

        break
      default:
        result = []; break
    }
  }

  return result
}

export const handleSortColumn = (sortColumn, isDefaultDirection) => {
}
