import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import I18n from "redux-i18n"
import {translations} from "./config/translations"
import './config/axios'
import { verifyCredentials } from './actions/redux-token-auth'
import 'fonts/futura/futur.otf'
import 'fonts/futura/futura-book.otf'

verifyCredentials(store)

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <I18n translations={translations} initialLang="en">
        <App />
      </I18n>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
