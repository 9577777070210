import { roundValueToNearest } from 'utils/matrix'
import {
  objectWithoutProperties,
  roundEVValue,
  formatToBigBlind,
  blindRatio,
  bigBlindValue,
  addPotValueToRawEvData
} from 'utils/utils'
import {
  COMBOS, POSITION_WITH_ORDER,
  UPPERCASE_POSITIONS_MAPPING,
  NUMBER_OF_COLOR_SCHEME_LEVELS
 } from 'config/constants';
import { convertHandCards, convertFlopCards } from './convert_cards_services'
import { isEqual, includes, min, max, orderBy, pickBy, round, without, capitalize, endsWith, isEmpty, isNaN} from 'lodash'

export const generateDecisionFromFlop = (flop, game, decisions) => {
  const { nodes, currentPosition, pot_ip, pot_oop } = game
  const node = flop.nodes.replace(`${nodes}:`, '')
  const action = node[0] || 'c'
  const pot_value = parseFloat(flop[`pot_${currentPosition}`]) - parseFloat(game[`pot_${currentPosition}`])
  const pot_value_in_bb = bigBlindValue(pot_value)
  let action_display = ''
  switch(action) {
    case 'b':
      action_display = node.slice(1, node.length)
      break;
    case 'c':
      action_display = isEqual(pot_ip, pot_oop) ? 'CHECK' : 'CALL'
      break;
    case 'f':
      action_display = 'FOLD'
      break;
    default:
      action_display = node.slice(1, node.length)
  }
  const betPercentOfPot = calculateBetPercentOfPot(action_display, game, decisions)
  return {...flop, action, action_display, pot_value, pot_value_in_bb, betPercentOfPot }
}

export const buildNodeFromDecision = ({ action, action_display }) => {
  return includes(['CH', 'CALL', 'FOLD'], action_display) ? action : `b${action_display}`
}

export const buildStrategyForEachCombos = (flops, nodes, preferences, flopCards, combos) => {
  const strategiesArray = flops.map((flop) => flop.strategy.split(' '))
  const roundStrategiesToClosest = preferences ? preferences.round_strategies_to_closest : 0
  const strategies = {}
  Object.keys(combos).forEach((combo) => {
    strategies[combo] = {}
    const idx = combos[combo]
    flops.forEach((flop, index) => {
      const node = flop.nodes.replace(`${nodes}:`, '')
      let value = strategiesArray[index][idx]
      value = isNaN(parseFloat(value)) ? 0.0 : parseFloat(value)
      const rounded = {'basic': value.toString(), 'rounded': roundValueToNearest(value, roundStrategiesToClosest).toString()}
      strategies[combo][node] = rounded
    })
  })
  return strategies
}

export const buildStrategyForEachCombosWithoutValue = (flops, nodes) => {
  const strategies = {}
  COMBOS.forEach((combo, idx) => {
    strategies[combo] = {}
    flops.forEach((flop, index) => {
      const node = flop.nodes.replace(`${nodes}:`, '')
      strategies[combo][node] = 0
    })
  })
  return strategies
}

export const buildStrategyEvForEachCombos = (flops, nodes, currentPosition, flopCards, combos, potValueObject) => {
  const ratio = blindRatio()
  const strategyEV = {}
  const evArray = flops.map((flop) => {
    const calculatedEvData = addPotValueToRawEvData(flop[`ev_${currentPosition}`], potValueObject[`pot_${currentPosition}`])
    return calculatedEvData.split(' ').map((item) => {
      const mapValue = isNaN(item) ? 0.0 : parseFloat(item)
      return mapValue / ratio
    })
  })
  Object.keys(combos).forEach((combo) => {
    const idx = combos[combo]
    strategyEV[combo] = {}
    flops.forEach((flop, index) => {
      const node = flop.nodes.replace(`${nodes}:`, '')
      if(!isEmpty(roundEVValue(evArray[index][idx]))) {
        strategyEV[combo][node] = evArray[index][idx]
      }
    })
  })
  return strategyEV
}

export const buildStrategyEvMinMax = (flops, currentPosition, displayInBB=true) => {
  let evArray = flops.map(flop => flop[`ev_${currentPosition}`]);
  evArray = evArray.join(' ').split(' ').map(item => isNaN(item) ? 0 : parseFloat(item));
  const minValue = min(evArray);
  const maxValue = max(evArray);
  const ratio = blindRatio()
  return {
    minValue: displayInBB ? minValue / ratio : minValue,
    maxValue: displayInBB ? maxValue / ratio : maxValue,
  };
}

export const buildCompareEvForEachCombos = (dataParams) => {
  const { flops, nodes, currentPosition, comparedDecisions, combos, potValue } = dataParams
  const comparedDecisionsData = {}
  let evArray = {}
  flops.forEach((flop) => {
    let node = flop.nodes.replace(`${nodes}:`, '')
    if(comparedDecisions.includes(node)) {
      evArray[node] = addPotValueToRawEvData(flop[`ev_${currentPosition}`], potValue).split(' ').map(item => isNaN(item) ? '0' : item)
    }
  })
  Object.keys(combos).forEach((combo) => {
    const idx = combos[combo]
    comparedDecisionsData[combo] = {}
    comparedDecisionsData[combo][comparedDecisions[0]] = '0';
    comparedDecisionsData[combo][comparedDecisions[1]] = '0';

    flops.forEach((flop, index) => {
      const node = flop.nodes.replace(`${nodes}:`, '')
      if(comparedDecisions.includes(node)) {
        comparedDecisionsData[combo][node] = round(parseFloat(evArray[node][idx]), 4)
      }
    })
  })
  return comparedDecisionsData;
}

export const buildRangesForEachCombos = (flops, nodes, currentPosition, flopCards) => {
  const oopRanges = {}
  const ipRanges = {}
  const rangesOopArray = flops.map((flop) => flop.ranges_oop.split(' '))
  const rangesIpArray = flops.map((flop) => flop.ranges_ip.split(' '))

  COMBOS.forEach((combo) => {
    const convertedCombo = convertHandCards(flopCards, combo)
    const idx = COMBOS.findIndex(combo => combo === convertedCombo)
    oopRanges[combo] = {}
    ipRanges[combo] = {}
    flops.forEach((flop, index) => {
      const node = flop.nodes.replace(`${nodes}:`, '')

      if(isEqual(currentPosition, 'oop') || (!isEqual(currentPosition, 'oop') && index === 0)) {
        oopRanges[combo][node] = rangesOopArray[index][idx]
      }

      if(isEqual(currentPosition, 'ip') || (!isEqual(currentPosition, 'ip') && index === 0)) {
        ipRanges[combo][node] = rangesIpArray[index][idx]
      }
    })
  })
  return { oopRanges, ipRanges }
}

export const getFlopDecision = (flopNodes, nodes) => {
  return flopNodes.replace(`${nodes}:`, '');
}

const getBetLevelStep = (flopNum, betLevelsNum) => {
  const step = round(betLevelsNum / flopNum);
  switch(flopNum) {
    case 3:
    case 4:
    case 5:
    case 9:
    case 10:
      return step + 1;
    default:
      return step;
  }
}

export const setBetLevelBaseOnValue = (game) => {
  const { flops, nodes } = game
  flops.forEach((flop) => {
    const flopDecision = getFlopDecision(flop.nodes, nodes)[0]
    if(isEqual(flopDecision, 'c')) {
      flop['bet_level'] = 0
    } else if (isEqual(flopDecision, 'f')) {
      flop['bet_level'] = -1
    }
  });
  const sortedFlops = flops.filter(flop => {
    const flopDecision = getFlopDecision(flop.nodes, nodes)[0]
    return !flopDecision.includes('f') && !flopDecision.includes('c')
  })
  sortedFlops.sort((a,b) => {
    const decisionA = getFlopDecision(a.nodes, nodes);
    const decisionB = getFlopDecision(b.nodes, nodes);
    const parseIntDecisionA = parseInt(decisionA.slice(1, decisionA.length))
    const parseIntDecisionB = parseInt(decisionB.slice(1, decisionB.length))
    return (parseIntDecisionA < parseIntDecisionB) ? 1 : ((parseIntDecisionA > parseIntDecisionB) ? -1 : 0)
  });
  const sortedFlopNum = sortedFlops.length;
  const betLevelStep = getBetLevelStep(sortedFlopNum, NUMBER_OF_COLOR_SCHEME_LEVELS);
  sortedFlops.forEach((flop, index) => {
    if(index === 0)
      return flop['bet_level'] = isEqual(sortedFlopNum, 2) ? 10 : NUMBER_OF_COLOR_SCHEME_LEVELS;

    if(index === sortedFlopNum - 1)
      return flop['bet_level'] = 1;

    if((sortedFlopNum === 8 && index >= 5) || (sortedFlopNum === 9 && index >= 4) || (sortedFlopNum === 10 && index >= 3)) {
      return flop['bet_level'] = sortedFlops[index - 1]['bet_level'] - 1;
    }

    return flop['bet_level'] = NUMBER_OF_COLOR_SCHEME_LEVELS - index*betLevelStep;
  })
  return sortFlopsByBetLevel(flops)
}

export const sortFlopsByBetLevel = (flops) => {
  return flops.sort((a,b) => a.bet_level < b.bet_level ? 1 : a.bet_level > b.bet_level ? -1 : 0)
}

export const gameDataGenerator = (flops, dataParams) => {
  const { nodes, betCompleted, currentRound, turnCard, riverCard, potMainDisplay, bettedPot } = dataParams
  const newFlops = setBetLevelBaseOnValue({flops, nodes})
  return {
    ...dataParams,
    betCompleted: betCompleted || false,
    currentRound: currentRound || 'flop',
    turnCard: turnCard || '',
    riverCard: riverCard || '',
    potMainDisplay: potMainDisplay || 0,
    flops: newFlops,
    bettedPot: bettedPot || {}
  }
}

export const findPreviousDecision =  (decisions, nodes, position) => {
  let previousNodes = nodes.split(':')
  let previousDecision = {}
  while(previousNodes.length > 1)
  {
    previousNodes = previousNodes.slice(0,previousNodes.length-1).join(':')
    let previousNodesFinded = previousNodes
    previousDecision = decisions.find(decision => {
      const { nodes, position, active } = decision
      return isEqual(nodes, previousNodesFinded) && isEqual(position, position) && !!active
    })
    previousNodes = previousNodes.split(':')
    if(!!previousDecision) break
  }
  return previousDecision
}

export const positionsCreator = (positions) => {
  let ipPosition = ''
  let oopPosition = ''
  if(positions.includes('b')) {
    ipPosition = 'b'
    oopPosition = positions.filter(p => p !== 'b')[0]
  } else {
    let postionsWithOrder = POSITION_WITH_ORDER.filter(pst => positions.includes(pst.position))
    postionsWithOrder = orderBy(postionsWithOrder, ['order'], ['asc'])
    ipPosition = postionsWithOrder[0].position
    oopPosition = postionsWithOrder[1].position
  }
  return {
    ipPosition,
    oopPosition
  }
}

export const positionsForDisplayBuilder = (positions) => {
  if(without(positions, '').length === 0) return '';

  const { ipPosition, oopPosition } = positionsCreator(positions)

  return `${position(oopPosition)} v ${position(ipPosition)}`
}

export const alternativePositionsForDisplayBuilder = (positions) => {
  if(without(positions, '').length === 0) return '';

  const { ipPosition, oopPosition } = positionsCreator(positions)

  return `${UPPERCASE_POSITIONS_MAPPING[position(oopPosition)]} v ${UPPERCASE_POSITIONS_MAPPING[position(ipPosition)]}`
}

export const positionsForQueryBuilder = (positions) => {
  if(without(positions, '').length === 0) return '';

  const { ipPosition, oopPosition } = positionsCreator(positions)

  return `${position(oopPosition)}v${position(ipPosition)}`
}

const position = (position) => {
  switch(position) {
    case 'b':
      return 'B'
    case 'bb':
      return 'BB';
    case 'sb':
      return 'SB';
    case 'b1':
      return 'B+1';
    case 'b2':
      return 'B+2';
    case 'b3':
      return 'B+3';
    case 'b4':
      return 'B+4';
    case 'b5':
      return 'B+5';
    default:
      return 'B'
  }
}

export const formatTurnStreetData = (data, dataParams) => {
  const { nodes } = dataParams
  const nextFlops = pickBy(data, (v, k) => k !== nodes && k.indexOf(nodes) !== -1);
  let nextFlopsArray = [];
  let currentNode = data[nodes] || {}

  for(const key in nextFlops) {
    const flop = nextFlops[key]
    const { pot_ip, pot_oop, pot_main, num_combos_ip, num_combos_oop, ev_ip, ev_ip2, ev_oop, ev_oop2, eq_ip, eq_oop, range_ip, range_oop } = flop
    let nodeArray = key.split(':');
    nextFlopsArray.push({
      nodes: key,
      node_count: nodeArray.length,
      strategy: currentNode.strategies[key.replace(`${nodes}:`, '')] || '',
      equity_ip: eq_ip,
      equity_oop: eq_oop,
      ranges_ip: range_ip,
      ranges_oop: range_oop,
      pot_ip,
      pot_oop,
      pot_main,
      num_combos_ip,
      num_combos_oop,
      ev_ip,
      ev_ip2,
      ev_oop,
      ev_oop2
    });
  }

  const { pot_ip, pot_oop, pot_main, num_combos_ip, num_combos_oop, ev_ip, ev_ip2, ev_oop, ev_oop2, eq_ip, eq_oop, range_ip, range_oop } = currentNode
  return {
    ...dataParams,
    flops: nextFlopsArray,
    equity_ip: eq_ip,
    equity_oop: eq_oop,
    ranges_ip: range_ip,
    ranges_oop: range_oop,
    pot_ip,
    pot_oop,
    pot_main,
    ev_ip,
    ev_ip2,
    ev_oop,
    ev_oop2,
    num_combos_ip,
    num_combos_oop
  }
}

export const isDoubleCheckDecisions = (currentNodes, nextNode) => {
  return endsWith(currentNodes, ':c') && nextNode === 'c'
}

export const addMoreToGameData = (currentFlop, game) => {
  const newFlops = game.flops.map(flop => {
    const idx = currentFlop.next_flops.findIndex(nf => nf.nodes === flop.nodes)
    if(idx !== -1) {
      flop = { ...flop, ...currentFlop.next_flops[idx]}
    }
    return flop
  })
  const newGameData = objectWithoutProperties({...game, ...currentFlop, flops: newFlops}, ['next_flops'])
  return newGameData
}

export const calcCurrentRoundPot = (decisions, game, position, displayInBB=false) => {
  const { currentRound } = game
  const filteredDecisions = isEmpty(decisions) ? [] : decisions.filter(decision => {
    const { active, position: decisionPosition, round, action, action_display } = decision
    return active &&
          isEqual(decisionPosition, position) &&
          isEqual(round, currentRound) &&
          (isEqual(action, 'b') || isEqual(action_display, 'CALL'))
  })
  let result = 0
  filteredDecisions.forEach(decision => {
    const { pot_value_in_bb, pot_value } = decision
    result += displayInBB ? parseFloat(pot_value_in_bb) : parseFloat(pot_value)
  })
  return round(result,1)
}

export const handleBackToHistory = (props, selectedDecision) => {
  const { currentUser: { strategy_selection }, game, handMatrix: {view: handMatrixView} } = props
  const strategySelection = JSON.parse(sessionStorage.getItem("strategy_selections")) || strategy_selection
  const { positions, flop_cards } = strategySelection
  const { turnCard, riverCard, pot_main, bettedPot } = game
  const { order_num, round, position, action } = selectedDecision
  let fullyDecisions = [...props.decisionsTree.decisions]
  let nodesRebuilt = 'r:0'

  fullyDecisions = fullyDecisions.map(decision => {
    if(decision.order_num >= order_num) {
      decision = {...decision, active: false}
    }
    return decision
  })
  fullyDecisions.filter(decision => decision.order_num < order_num && decision.active)
                .forEach(decision => {
                  const { action, action_display, action_value } = decision
                  nodesRebuilt += action === 'b' ? `:${action}${action_display}` : (action === 'c' || action === 'f') ? `:${action}` : `:${action_value}`
                })
  const potMainDisplay = isEqual(round, 'flop') ?
                          formatToBigBlind(pot_main) :
                          game[`potMain${capitalize(round)}Round`]
  if(nodesRebuilt === game.nodes) return;
  const isSelectCardAction = ['select_turn_card', 'select_river_card'].includes(action)
  const dataParams = {
    ...strategySelection,
    ...selectedDecision,
    positions: positionsForQueryBuilder(positions),
    flops: convertFlopCards(flop_cards, props.cardsConvertingTemplate).join(''),
    node_count: nodesRebuilt.split(':').length,
    currentPosition: isSelectCardAction ? 'oop' : position,
    betCompleted: isSelectCardAction,
    currentRound: round,
    view: handMatrixView,
    turnCard: 'select_turn_card' === action || round === 'flop' ? '' : turnCard,
    riverCard: isSelectCardAction || round !== 'river' ? '' : riverCard,
    pot_main,
    nodes: nodesRebuilt,
    potMainDisplay,
    bettedPot,
    game
  }
  props.rollbackDecision(dataParams, fullyDecisions)
  if(isEqual(handMatrixView, 'compare-ev')) {
    props.updateComparedDecisions([]);
    props.changeMatrixView('');
  }
}

export const calculateStrategyKey = (keyValue, game) => {
  const { currentPosition, currentRound, bettedPot } = game
  if(currentRound === 'flop') return keyValue

  const previousStreet = currentRound === 'turn' ? 'flop' : 'turn'
  const keyDisplay = parseInt(keyValue) - parseInt(bettedPot[previousStreet][`pot_${currentPosition}`])
  return keyDisplay
}

export const calculatePotMainDisplay = (decisions, game, displayInBB=false) => {
  const { pot_ip, pot_oop, pot_main, potMainDisplay } = game
  let totalPot = pot_ip + pot_oop + pot_main
  if (displayInBB) {
    const currentRoundBettingValueOop = calcCurrentRoundPot(decisions, game, 'oop', displayInBB) // just a treat, to prevent the chips animation is show when fetching data
    const currentRoundBettingValueIp = calcCurrentRoundPot(decisions, game, 'ip', displayInBB)
    const potMainValue = potMainDisplay === 0 ? potMainDisplay : parseFloat(potMainDisplay.replace('bb',''))
    totalPot = currentRoundBettingValueIp + currentRoundBettingValueOop + potMainValue
  }
  return round(totalPot, 1)
}

export const addBbSuffixToValue = (value, displayInBB=false) => {
  return displayInBB ? `${value}bb` : value
}

export const formatKeyToDisplay = (key, game, currentUser={}, isPreflopPage=false) => {
  const { strategy_selection: { stack_size } } = currentUser
  const { pot_ip, pot_oop } = game
  let keyShow = stack_size ?
              formatToBigBlind(key.length > 1 ? calculateStrategyKey(key.substring(1), game) : key)
              : key.replace("b","Bet ")
  keyShow = isEqual(pot_ip, pot_oop) ?
              keyShow.replace("c","Check") :
              keyShow.replace("c","Call")

  return isPreflopPage ? key.replace('fold', 'Fold') : keyShow.replace("f", "Fold")
}

export const currencyFormat = (num) => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const calculateBetPercentOfPot = (decisionValue, game, decisions) => {
  let { currentPosition, pot_ip, pot_oop, } = game
  switch (decisionValue) {
    case 'FOLD':
    case 'CHECK':
    case 'CALL':
      return ''
    default:
    const totalPot = calculatePotMainDisplay(decisions, game, false) // starting pot + pot_oop + pot_ip
    const chipRequiredForCall = Math.abs(pot_oop - pot_ip)
    const raiseTotal = decisionValue
    const contributedChips = currentPosition === 'oop' ? pot_oop : pot_ip
    const raiseSizeInChip = raiseTotal - contributedChips - chipRequiredForCall
    const pot = totalPot + chipRequiredForCall
    const raisePercent = raiseSizeInChip/pot*100
    
    return ` (${Math.round(raisePercent)}%)`
  }
}
