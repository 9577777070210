import { rangeExplorerActions } from '../constants/index'
import { INITIAL_HAND_CATEGORY_MATRIX } from 'config/constants'

const initialState = {
  suitsForFilter: {
    included: {
      first: '',
      second: ''
    },
    excluded: {
      first: '',
      second: ''
    }
  },
  decisionFilter: '',
  handClickedItem: '',
  hand_category: INITIAL_HAND_CATEGORY_MATRIX,
  fetching: false
};

export function rangeExplorer(state = initialState, action) {
  switch (action.type) {
    case rangeExplorerActions.UPDATE_SUITS_FOR_FILTER_RE:
      return {
        ...state,
        suitsForFilter: action.payload
      }
    case rangeExplorerActions.UPDATE_DECISION_FILTER_RE:
      return {
        ...state,
        decisionFilter: action.payload
      }
    case rangeExplorerActions.CHANGE_CLICKED_HAND_ITEM_RE:
      return {
        ...state,
        handClickedItem: action.payload
      }
    case rangeExplorerActions.UPDATE_HAND_CATEGORY:
      return {
        ...state,
        hand_category: action.payload
      }
    case rangeExplorerActions.FETCHING_RANGE_EXPLORER_DATA:
      return {
        ...state,
        fetching: action.payload
      }
    default:
      return state;
  }
}
