import { decisionActions } from '../constants/index';

const initialState = { decisions: [] };

export function decisionsTree(state = initialState, action) {
  switch (action.type) {
    case decisionActions.ADD_DECISION:
      return {
        ...state,
        decisions: state.decisions.concat(action.payload)
      }
    case decisionActions.UPDATE_DECISIONS_TREE:
      return {
        ...state,
        decisions: action.payload
      }
    case decisionActions.REFRESH_DECITIONS_TREE:
      return {
        ...initialState
      }
    default:
      return state;
  }
}