const authUrl = `${process.env.REACT_APP_API_URL}/auth`

export const config = {
  authUrl,
  userAttributes: {
    email: 'email',
    strategy_selection: 'strategy_selection',
    user_strategy_selection: 'user_strategy_selection',
    preferences: 'preferences',
    id: 'id',
    role: 'role',
    firstName: 'first_name',
    lastName: 'last_name',
    birthday: 'birthday',
    country: 'country',
    subscription: 'subscription',
    universal_note: 'universal_note',
    emailConfirmed: 'email_confirmed',
    ip: 'last_ip',
    sims: 'sim_ids',
    user_type: 'user_type',
    accessable_game_type: 'accessable_game_type',
    admin_access: 'admin_access',
    affiliate_code: 'affiliate_code',
    full_access: 'full_access',
    createdAt: 'created_at',
    allowAutoRenew: 'allow_auto_renew',
    alt_subscription_id: 'alt_subscription_id',
    alt_plan: 'alt_plan'
  },
  userRegistrationAttributes: {
    email: 'email',
    password: 'password',
    confirm_password: 'password_confirmation',
    first_name: 'first_name',
    last_name: 'last_name',
    birthday: 'birthday',
    country: 'country',
    affiliate_code: 'affiliate_code'
  },
}
