import { gameActions } from '../constants/index';
import { DEFAULT_BEGIN_ROUND_POSITION, INITIAL_TREE_INFO } from 'config/constants'

const initialState = {
  currentRound: 'flop',
  currentPosition: DEFAULT_BEGIN_ROUND_POSITION,
  flops: [],
  flopCards: [],
  nodes: "r:0",
  betCompleted: false,
  node_count: 2,
  turnCard: '',
  riverCard: '',
  ev_oop: '',
  ev_oop2: '',
  ev_ip: '',
  ev_ip2: '',
  equity_oop: '',
  equity_ip: '',
  ranges_oop: '',
  ranges_ip: '',
  num_combos_ip: 0,
  num_combos_oop: 0,
  comparedDecisions: [],
  fetching: false,
  fetchTurnRiverSuccess: false,
  bettedPot: {},
  potMainDisplay: 0,
  potMainTurnRound: 0,
  potMainRiverRound: 0,
  runoutsExplorerData: {},
  treeInfo: INITIAL_TREE_INFO,
  stackSize: 0,
  shouldReloaded: false,
  processingClickNode: false
};

export function game(state = initialState, action) {
  let payload = action.payload || {};

  switch (action.type) {
    case gameActions.UPDATE_GAME_DATA:
      return {
        ...state,
        ...payload,
        processingClickNode: false,
        node_count: payload.nodes ? payload.nodes.split(':').length : state.node_count
      }
    case gameActions.RECIEVE_FLOP_DATA:
      return {
        ...state,
        flopCards: action.payload.flop_cards,
        convertedFlopCards: action.payload.convertedFlopCards
      }
    case gameActions.UPDATE_BET_COMPLETED_STATUS:
      return {...state, ...action.payload }
    case gameActions.UPDATE_TURN_CARD:
      return {
        ...state,
        turnCard: action.payload
      }
    case gameActions.UPDATE_RIVER_CARD:
      return {
        ...state,
        riverCard: action.payload
      }
    case gameActions.CLEAR_CARDS:
      return {
        ...state,
        riverCard: '',
        turnCard: ''
      }
    case gameActions.UPDATE_COMPARED_DECISIONS:
      return {
        ...state,
        comparedDecisions: action.payload
      }
    case gameActions.FETCH_DATA_FOR_TURN_RIVER_ROUND:
      return {
        ...state,
        fetching: typeof payload.fetching !== 'undefined' ? payload.fetching : true,
        betCompleted: typeof payload.betCompleted !== 'undefined' ? payload.betCompleted : false,
      }
    case gameActions.FETCHED_DATA_SUCCESSFULLY:
      return {
        ...state,
        fetching: false
      }
    case gameActions.REFRESH_GAME_DATA:
      return {
        ...initialState
      }
    case gameActions.UPDATE_RUNOUTS_EXPLORER_DATA:
      return {
        ...state,
        fetching: false,
        runoutsExplorerData: {
          ...state.runoutsExplorerData,
          ...action.payload,
        }
      }
    case gameActions.CLEAR_RUNOUTS_EXPLORER_DATA:
      return {
        ...state,
        runoutsExplorerData: {},
      }
    case gameActions.UPDATE_TREE_INFO:
      return {
        ...state,
        treeInfo: action.payload || INITIAL_TREE_INFO
      }
    case gameActions.UPDATE_FETCH_TURN_RIVER_SUCCESS:
      return {
        ...state,
        fetchTurnRiverSuccess: action.payload,
        shouldReloaded: false
      }
    case gameActions.FETCH_STACKSIZE:
      return {
        ...state,
        stackSize: action.payload
      };
    case gameActions.UPDATE_PROCESSING_CLICK_NODE:
      return {
        ...state,
        processingClickNode: action.payload
      }
    default:
      return state;
  }
}
