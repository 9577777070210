import React, {Component} from 'react'
import './stylesheet.scss'
import PropTypes from 'prop-types'
import { buildSchema } from './validation'
import { Formik } from 'formik';
import { Link } from 'react-router-dom'
import { signInUser } from '../../actions/redux-token-auth'
import { Form } from 'react-bootstrap'
import LaddaButton from '../LaddaButton/component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import OdinLogo from '../OdinLogo/component'
import { storeUserData, clearUserData } from 'actions/user.actions'
import { toast } from 'react-toastify';
import { notify } from 'utils/utils'
import queryString from 'query-string'
import { isEmpty } from 'lodash'
import loadable from '@loadable/component'
const SignUpSuccessPopUp = loadable(() => import('components/SignUp/SignUpSuccessPopUp/SignUpSuccessPopUp'))

class CSignIn extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      registerEmail: ''
    }
  }

  componentDidMount() {
    const { location, history } = this.props
    if (location.success && location.email) {
      this.setState({ 
        open: true,
        registerEmail: location.email })
      history.replace('/signin')
    }
    const params =  queryString.parse(location.search)
    const t = this.context.t;
    if (!isEmpty(Object.keys(params))) {
      if(isEmpty(params['account_confirmation_status'])) {
        notify(t('toast.email_confirmation_link_expired'), toast.error);
        history.replace('/')
       } else {
        this.showConfirmEmailToastMessage(params['account_confirmation_status']);
        history.push('signin');
      }
    }
  }

  showConfirmEmailToastMessage(status) {
    const t = this.context.t;

    switch(status) {
      case 'success':
        notify(t('toast.account_activated_successfully'), toast.success);
        break;
      case 'confirmed':
        notify(t('toast.account_activated_before'), toast.error);
        break;
      case 'expired':
        notify(t('toast.email_confirmation_link_expired'), toast.error);
        break;
      case 'failed':
      default:
        notify(t('toast.email_confirmation_failed'), toast.error);
        break;
    }
  }

  handleOnLogIn(user, actions){
    const { signInUser, clearUserData, storeUserData, location, history } = this.props
    storeUserData({ email: user.email });
    signInUser(user).then(res => {
      if(location.state && location.state.backToLandingPage) history.replace('/')
      clearUserData();
    }).catch(res => {
      notify(res.response.data.errors[0], toast.error);
      actions.setSubmitting(false)
    })
  }

  privateForm = formikProps => {
    const t = this.context.t
    const { userData } = this.props
    let {
      handleSubmit,
      handleChange,
      isSubmitting,
      errors,
      values
    } = {...formikProps}

    if(Object.keys(userData).includes('email')) {
      values = {
        ...values,
        email: userData.email,
      }
    }

    return <Form
      noValidate
      className='fadeIn'
      onSubmit={handleSubmit}>
      <Form.Group controlId="signinform-email">
        <Form.Label>{t('email')}<span className='text-red'> *</span></Form.Label>
        <Form.Control
          readOnly={isSubmitting}
          isInvalid={!!errors.email}
          autoFocus
          onChange={handleChange}
          defaultValue={values.email}
          name='email'
          type="text"
          placeholder={t('email_placeholder')} />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="signinform-password">
        <div className='d-flex justify-content-between align-items-center'>
          <Form.Label>{t('password')}<span className='text-red'> *</span></Form.Label>
          <Link to={{ pathname: '/forgot-password'}} className='text-primary-color font-size-14' tabIndex="-1">
            {t('forgot_password')}
          </Link>
        </div>
        <Form.Control
          autoComplete="off"
          readOnly={isSubmitting}
          isInvalid={!!errors.password}
          onChange={handleChange}
          defaultValue={values.password}
          name='password'
          type="password"
          placeholder={t('password_placeholder')} />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <LaddaButton
          className='w-100 submit py-2 btn-primary font-size-18 font-weight-600'
          type='submit'
          text={t('log_in')}
          disabled={isSubmitting} />
      </Form.Group>
    </Form>
  }

  renderPopupSuccess() {
    const { open, registerEmail } = this.state
    if (open && registerEmail) {
      return (
        <SignUpSuccessPopUp
          email={registerEmail}
          popupShow={open}
          setModalShow={() => this.setState({open: false})}
        />
      )
    }
  }

  render(){
    const t = this.context.t
    const schemaValidation = buildSchema(this.props.i18nState.lang, t)
    const initialValues = {
      email: '',
      password: '',
      ...this.props.userData,
    }

    return (
      <div className='auth-screen signin'>
        {this.renderPopupSuccess()}

        <div className='form-wrappper bounceInDown sign-in'>
          <div className='form'>
            <div className='text-center w-100 d-flex align-items-center justify-content-center'>
              <OdinLogo />
            </div>
            <Formik
              component={this.privateForm}
              validationSchema={schemaValidation}
              onSubmit={this.handleOnLogIn.bind(this)}
              initialValues={initialValues}/>
            <div className='footer-sign-up-form'>
              <div className='pt-4 d-flex align-items-center justify-content-center'>
                {t('not_a_member_yet')}&nbsp;
                <Link to={{ pathname: '/signup'}} tabIndex="-1">
                  <b>{t('signup')}</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

CSignIn.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  userData: store.userData,
})

const mapDispatchToProps = {
  signInUser,
  storeUserData,
  clearUserData,
}

const SignIn = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CSignIn)

export default withRouter(SignIn)
