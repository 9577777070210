import React, {Component, Fragment} from 'react'
import './style.scss'
import Header from '../Header/component'
import {connect} from 'react-redux'
import Footer from 'components/Footer/component'
import {withRouter} from 'react-router'
import {toLower} from 'lodash'
import { updateOdinLoadingShowingValue } from 'actions/odin-loading.actions'
import OdinLoader from 'components/Shared/OdinLoader/OdinLoader'
import AdsBanner from './AdsBanner/AdsBanner'
import styled from 'styled-components';
import { getAffiliateDiscountCode, getAffiliateDiscountCodeTypes } from '../../services/affiliate_discount_code_service'
import { setAffiliateDiscountCodes } from 'actions/affiliate-discount-code.actions'

const HeaderWrapper = styled.div`
  width: 100%;
  z-index: 100000;
  position: fixed;
  top: ${props => props.adsBannerShowing ? "120px" : "0px"};

  @media (max-width: 1024px) {
    top: ${props => props.adsBannerShowing ? "146px" : "0px"};
  }

  @media (max-width: 768px) {
    top: ${props => props.adsBannerShowing ? "146px" : "0px"};
  }
`

const ContentWrapper = styled.div`
  ${(props) => props.adsBannerShowing && !props.isSignInSignUpPage ? `
    margin-top: calc(120px + ${props.contentMarginTop});
    @media (max-width: 1024px) {
      margin-top: calc(146px + ${props.contentMarginTop});
    }
  ` :
  `margin-top: ${props.contentMarginTop};`}
`

class Layout extends Component {

  constructor(props) {
    super(props)

    this.state = {
      adsBannerShowing: true,
      linkLayout: []
    }

    this.closeAdsBanner = this.closeAdsBanner.bind(this)
  }

  displayLoading = () => {
    this.props.updateOdinLoadingShowingValue('fetching')
  }

  hideLoading = () => {
    this.props.updateOdinLoadingShowingValue('done')
  }

  componentDidMount() {
    const closedAdsBanner = sessionStorage.getItem('closedAdsBanner') || false

    if(closedAdsBanner) {
      this.setState({adsBannerShowing: false})
    }

    getAffiliateDiscountCode().then(res => {
      if (res) {
        this.props.setAffiliateDiscountCodes(res.data.affiliate_discount_codes)
        this.setState({ linkLayout: getAffiliateDiscountCodeTypes(res.data.affiliate_discount_codes, 'LINK_LAYOUT') })
      }
    })
  }

  closeAdsBanner() {
    this.setState({
      adsBannerShowing: false
    })
    sessionStorage.setItem('closedAdsBanner', true)
  }

  render(){
    const {adsBannerShowing} = this.state
    const { location: { pathname: path }, currentUser, odinLoading } = this.props
    let isSignInSignUpPage = ['/signin', '/signup'].includes(path)
    let style = document.documentElement.style;
    let contentMarginTop = '75px'

    if (isSignInSignUpPage) {
      style.setProperty('--header-height', 0);
      contentMarginTop = '0px'
    } else {
      style.setProperty('--header-height', '75px');
    }

    return (
      <Fragment>
        {
          currentUser.isLoading ?
          <div> Loading ... </div> :
          <Fragment>
            {adsBannerShowing &&
              <AdsBanner
                closeAdsBanner={this.closeAdsBanner}
              />
            }
            <HeaderWrapper
              adsBannerShowing={adsBannerShowing}>
              <Header
                displayLoading={this.displayLoading}
                hideLoading={this.hideLoading}
              />
            </HeaderWrapper>

            { odinLoading.fetching === 'fetching' &&
              <OdinLoader
                handleCloseLoader={() => {}}
                disableProgressBar={true}
              />
            }
            <ContentWrapper
              adsBannerShowing={adsBannerShowing}
              contentMarginTop={contentMarginTop}
              isSignInSignUpPage={isSignInSignUpPage}
              className={`
                content-container hide-scrollbar
                ${path === '/' || this.state.linkLayout.includes(toLower(path)) ? 'dark-background' : ''}
                ${path === '/game-play' ? 'background-game-play' : ''}
              `}
            >
              {this.props.children}
              <Footer />
            </ContentWrapper>
          </Fragment>
        }
      </Fragment>
    )
  }

}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser,
  odinLoading: store.odinLoading,
  affiliateDiscountCodes: store.affiliateDiscountCodes
})

const mapDispatchToProps = {
  updateOdinLoadingShowingValue,
  setAffiliateDiscountCodes
}

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(Layout)
)
