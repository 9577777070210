export const getAccess = (userData) => {
  if(userData && userData.username && userData.password) {
    let token = window.btoa(encodeURIComponent(userData.username + ':' + userData.password))
    if(token === process.env.REACT_APP_BASIC_AUTH_TOKEN) {
      localStorage.setItem('basicAuthData', JSON.stringify(userData))
      return true
    }
    return false
  } else {
    return false
  }
}

export const giveSpecialAccess = () => {
  const userData = { username: process.env.REACT_APP_BASIC_AUTH_USERNAME, password: process.env.REACT_APP_BASIC_AUTH_PASSWORD }
  if(process.env.NODE_ENV === 'production') {
    localStorage.setItem('basicAuthData', JSON.stringify(userData))
  }
  return getAccess(userData)
}