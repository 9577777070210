import React, { Component, Fragment } from 'react'
import './OdinLoader.scss'
import logoFilled from 'images/odin-logo-filled.png'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ConfirmDialog from 'components/Shared/ConfirmDialog/ConfirmDialog'

class COdinLoader extends Component {
  constructor(props) {
    super(props);

    this.state = { showClosePopup: false };
    this.togglePopUp = this.togglePopUp.bind(this);
  }

  togglePopUp(value) {
    this.setState({ showClosePopup: value });
  }

  render() {
    const t = this.context.t
    const { handleCloseLoader, disableCloseBtn, disableProgressBar, disableOdinAnimation, odinLoading, loadingSpeed } = this.props;
    const { speed } = odinLoading
    const animationSpeed = loadingSpeed || speed
    const odinLogoAnimationDisabled = disableOdinAnimation ? 'odin-logo-animation-disabled' : ''

    return (
      <div className='fetching-div'>
        <div className='fetching-overlay'>
          <div
            className='cancel-button'
            onClick={ !disableCloseBtn ? handleCloseLoader : () => { this.togglePopUp(true) }}
          >×
          </div>
        </div>
        <div className='fetching-image'>
          <div className={`image-overlay ${odinLogoAnimationDisabled} ${animationSpeed}`}>
            <img  src={logoFilled} alt='logo-filled'/>
          </div>
          <div className='image'></div>
          { !disableProgressBar && (
              <Fragment>
                <div className={`loader-progress-bar ${animationSpeed}`}>
                  <div className='progressing'></div>
                </div>
                <div className='loader-message text-center w-100'>
                  {t('runouts_explorer.loader_message')}
                </div>
              </Fragment>
          )}
        </div>
        <ConfirmDialog
          isShown={this.state.showClosePopup}
          agreeBtnText="OK"
          disableDenyBtn={true}
          dialogMessage={t('runouts_explorer.close_loader_message')}
          handleClose={() => { this.togglePopUp(false) }}
          handleAgree={() => { this.togglePopUp(false) }}
        />
      </div>
    )
  }
}

COdinLoader.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  odinLoading: store.odinLoading
})

const mapDispatchToProps = {
}

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(COdinLoader)

