import React, { Component, Fragment } from 'react'
import './stylesheet.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { buildSchema } from './register_validation'
import { Link } from 'react-router-dom'
import { Formik }  from 'formik';
import {
  Form,
  Row,
  Col} from 'react-bootstrap'
import LaddaButton from '../LaddaButton/component'
import FormField from 'components/Shared/FormField/FormField'
import OdinLogo from '../OdinLogo/component'
import { storeUserData, clearUserData, storeValidationMessages } from 'actions/user.actions'
import { toast } from 'react-toastify';
import { notify } from 'utils/utils'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'


class CSignUp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      signUpSuccessed: false,
      resendingEmail: false,
      resentEmail: false,
      user: {},
      countdownTimer: 60
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleOnResendEmail = this.handleOnResendEmail.bind(this)
  }

  onSubmit(user, actions) {
    const t = this.context.t
    actions.setSubmitting(true)
    const { subscriptions: { code } } = this.props
    const params = { ...user, affiliate_code: code }

    axios.post('/auth/invitation', params).then(res => {
      if(res && res.data) {
        this.setState({
          signUpSuccessed: true,
          user: user
        })
      } else {
        notify(t('register_failed_message'), toast.error);
      }
      actions.setSubmitting(false)
    })
  }

  handleOnResendEmail() {
    const t = this.context.t
    const {user, resentEmail, countdownTimer} = this.state
    const params = {
      invitation: {
        ...user
      }
    }
    if(resentEmail) {
      notify(`We are resending the email, please check your inbox. If you don't receive an email, please resend again after ${countdownTimer} seconds`, toast.info);
    } else {
      this.setState({resendingEmail: true, resentEmail: true})
      axios.post('/auth/invitation', params).then(res => {
        if(res && res.data) {
          this.intervalId = setInterval(() => {
            let newCount = this.state.countdownTimer - 1;
            if(newCount >= 0) {
              this.setState({ resendingEmail: false, countdownTimer: newCount });
            } else {
              clearInterval(this.intervalId)
              this.setState({resentEmail: false, countdownTimer: 60})
            }
          }, 1000)
        } else {
          notify(t('register_failed_message'), toast.error);
        }
      })
    }
  }

  componentWillUnmount() {
    if(this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  privateForm = formikProps => {
    const t = this.context.t
    const { userData } = this.props
    const {signUpSuccessed} = this.state
    let {
      handleSubmit,
      handleChange,
      isSubmitting,
      errors,
      values,
      touched,
      handleBlur,
      status,
    } = {...formikProps}

    if(userData.validation_messages) {
      status = { ...userData.validation_messages }
    }

    const signUpFields = [
      {field: 'email', fieldType: 'text'}
    ]

    return <Form
      noValidate
      className='fadeIn mb-4'
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <Row>
        {
          signUpFields.map((item, idx) => {
            const { field, fieldType } = item
            return (
              <Col key={idx} sm={field === 'email' ? 12 : 6}>
                <FormField
                  field={field}
                  fieldType={fieldType}
                  error={touched[field] && errors[field]}
                  status={status && status[field]}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  values={values}
                  isRequired={true}
                  onBlur={handleBlur}
                />
              </Col>
            )
          })
        }
        {!signUpSuccessed &&
          <Col className='mt-4' sm={12}>
            <LaddaButton
              type='submit'
              text={t('register')}
              className='w-100 submit my-3 py-2 btn-primary font-size-18 font-weight-600'
              disabled={isSubmitting}
            />
          </Col>
        }
      </Row>
    </Form>
  }

  render() {
    const t = this.context.t
    const schemaValidation = buildSchema(this.props.i18nState.lang, t)
    const initialValues = {
      email: ''
    }
    const {signUpSuccessed, resendingEmail} = this.state

    return (
      <div className='auth-screen register'>
        <div className='form-wrappper'>
          <div className='form'>
            <div className='text-center w-100 d-flex align-items-center justify-content-center'>
              <OdinLogo />
            </div>
            <Formik
              component={this.privateForm}
              validationSchema={schemaValidation}
              onSubmit={this.onSubmit}
              initialValues={initialValues}
            />
            {signUpSuccessed &&
              <Fragment>
                <p className='font-size-14 text-center mb-1'>Thank you for signing up! Your verification email has been sent to your inbox.</p>
                <p className='font-size-14 text-center'>In case you don't receive an email, please click <strong>Resend Email</strong> or contact: <a href={`mailto:${t('odin_support_email')}`}> {t('odin_support_email')}.</a></p>
                <button className='w-100 submit my-3 py-2 btn-primary font-size-18 font-weight-600' onClick={e => this.handleOnResendEmail()}>
                  { resendingEmail ? <Spinner animation="border" size="sm" /> : `Resend Email` }
                </button>
              </Fragment>
            }
            <div className='footer-sign-up-form'>
              <div className='pt-4 d-flex align-items-center justify-content-center'>
                {t('already_have_an_account')}&nbsp;
                <Link to='/signin' className='text-blue' tabIndex="-1"><b>{t('log_in')}</b></Link>
              </div>
            </div>
           </div>
        </div>
      </div>
    )
  }
}

CSignUp.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  userData: store.userData,
  subscriptions: store.subscriptions
})

const mapDispatchToProps = {
  storeUserData,
  clearUserData,
  storeValidationMessages,
}

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(CSignUp)
