import { round, sortBy, isEqual, sum, isEmpty, toLower, max, sample, range, replace, xor, isNull } from 'lodash'
import { generateDecisionFromFlop, positionsForQueryBuilder } from '../services/game_play_service'
import {
  DEFAULT_BLINDS,
  SPECIAL_SIMS,
  FREE_TRIAL_USER_EMAILS,
  DECISION_PERCENT_COMBOS_PRECISION,
  DECISION_NUM_COMBOS_PRECISION,
  CASH_TYPE_POSITION_OPTIONS,
  MMT_TYPE_POSITION_OPTIONS,
  PARTY_SITE_STAKE_OPTIOINS,
  STARS_SITE_STAKE_OPTIOINS,
  DEFAULT_OPEN_SIZE,
  URL_TO_AFFILIATE_CODE_MAPPING,
  FLOP_WEIGHTS,
  MMT_TYPE_STACK_OPTIONS,
  TYPE_OPTIONS,
  CARD_OPTIONS,
  PTB_NODE_SCORE_DECIMAL,
  FREE_A_WEEK_ACCESS_EMAILS
} from 'config/constants'
import {
  renderNotPairedCards,
  renderPairedCards,
  renderTripsCard,
  renderCards,
  renderJustRainbowCards,
  renderJustFlushDrawCards,
  renderJustMonotoneCards,
  renderJustNotPairedCards,
  renderJustPairedCards,
  renderJustTripsCard,
  renderJustCards,
  renderJustDisconnectedCards,
  renderJustMediumCards,
  renderJustConnectedCards,
  renderSuitCard,
  renderSuitConnectedness,
  renderSuitPaired,
  renderCardConnectedness,
  renderCardPaired,
  renderConnectednessPaired
} from 'services/randomFlopCards'
import moment from 'moment-timezone'
import axios from 'axios'
import { convertToRealFlopCards, generateRealCardTemplateListFormConvertedCard } from 'services/convert_cards_services'
import freeAccess30DaysList from '../constants/free_30_days_access.json'

export const decisionCombosButtons = (game, preferences, currentPosition, decisions, PTB=false) => {
  let { fold_color, check_and_call_color, bet_and_raise_color, hide_strategies_less_than } = preferences
  let totalCombosNumber = 0.0
  const decisionButtons = game.flops.map((flop, index) => {
    let flopOption = generateDecisionFromFlop(flop, game, decisions)
    let combos = parseFloat(flopOption[`num_combos_${currentPosition}`])
    const { action_display: flopActionDisplay, action, bet_level, betPercentOfPot, id } = flopOption
    let action_display = isNaN(flopActionDisplay) ? flopActionDisplay : `B${flopActionDisplay}`
    let colorScheme = action === 'b' ? `${bet_and_raise_color}-scheme` : action === 'c' ? `${check_and_call_color}-scheme` : `${fold_color}-scheme`
    let colorLevel = bet_level > 0 ? `level-${bet_level}` : 'base'
    let nodeArray = flop.nodes.split(':')
    totalCombosNumber += combos

    return {
      id,
      title: action_display,
      info: combos,
      colorClass: `${colorScheme} ${colorLevel}`,
      value: nodeArray[nodeArray.length-1],
      raw_value: flopActionDisplay,
      betPercentOfPot: betPercentOfPot,
    }
  })
  totalCombosNumber = round(totalCombosNumber, DECISION_NUM_COMBOS_PRECISION)

  //calculate percent combos
  decisionButtons.forEach((comboElement, index) => {
    comboElement['percent'] = totalCombosNumber === 0.0 ? '0%' : `${ round( comboElement.info / totalCombosNumber * 100, DECISION_PERCENT_COMBOS_PRECISION) }%`
    comboElement['info'] = `${round(comboElement.info, DECISION_NUM_COMBOS_PRECISION)}c`
  })
  let betDecisionArray = decisionButtons.filter(comboElement => !['CHECK', 'FOLD', 'CALL'].includes(comboElement.raw_value))
  let maxPercent = max(betDecisionArray.map( decision => parseFloat(decision['percent'].replace('%', '')) ))

  //Apply hide strategy less than function and apply highlight for bigest percent decision
  decisionButtons.forEach( comboElement => {
    comboElement['isMaxPercent'] = ['CHECK', 'FOLD', 'CALL'].includes(comboElement.raw_value) ? false : parseFloat(comboElement['percent'].replace('%', '')) === maxPercent
  })
  //if it have more than 1 hightest percent value, don't set isMax for any element
  let isOneHighestFrequency = decisionButtons.filter( comboElement => comboElement['isMaxPercent'] === true).length === 1
  if(!isOneHighestFrequency) {
    decisionButtons.forEach( comboElement => { comboElement['isMaxPercent'] = false })
  }

  const numberHideStrategies = PTB ? 0.0 : hide_strategies_less_than

  let decisionButtonsDisplay = decisionButtons.filter(
    comboElement => parseFloat(comboElement.percent.replaceAll('%', '')) >= round(numberHideStrategies*100, 2)
    || [comboElement.raw_value, comboElement.title].includes('CHECK') //alway show CHECK action
  )

  decisionButtonsDisplay.push({
    title: 'ALL',
    info: `${totalCombosNumber}c`,
    colorClass: `bg-white`,
    value: 'all',
    raw_value: 'ALL',
    percent: '100%',
    combosNumber: totalCombosNumber,
  })

  return decisionButtonsDisplay
}

export const generateCompareEVoptions = (game, decisions) => {
  const options = game.flops.map((flop, index) => {
    let flopOption = generateDecisionFromFlop(flop, game, decisions)
    let action_display = flopOption.action_display;

    return {
      text: isNaN(action_display) ? action_display : `BET ${action_display}`,
      value: isNaN(action_display) ? action_display.toLowerCase()[0] : `b${action_display}`,
      raw_value: action_display,
    }
  })

  return options;
}

export const orderCompareEVOptions = (options) => {
  let keys = Object.keys(options)
  let preOrderKeys = keys.map((key) => {
    if(key === 'f') {
      return { key: key, order: 1 }
    } else if(key === 'c') {
      return { key: key, order: 2 }
    } else {
      return { key: key, order: 3 }
    }
  })

  if(preOrderKeys.filter(key => key.order === 3).length === 2) {
    return sortBy(preOrderKeys, keyObj => { return parseFloat(keyObj.key.replace('b', '')) }, ['asc']).map(orderedKey => {
      return orderedKey.key
    })
  } else {
    return sortBy(preOrderKeys, 'order', ['asc']).map(orderedKey => {
      return orderedKey.key
    })
  }
}

export const checkIsSpecialSim = () => {
  const strategySelection = JSON.parse(sessionStorage.getItem("strategy_selections"))
  if (isNull(strategySelection)) return false
  const { sim_type, stack_size, positions } = strategySelection
  const simType = simTypeMapping(sim_type)
  const positionsBuilder = positionsForQueryBuilder(positions)
  const sim = `${stack_size}-${simType}-${positionsBuilder}`
  const simReverse = `${stack_size}-${simType}-${positionsBuilder.split('v').reverse().join('v')}`
  return [sim, simReverse].some( simName => SPECIAL_SIMS.includes(simName) )
}

export const formatToBigBlind = (chips, precision=1) => {
  if(isNaN(chips))
    return chips
  const value = bigBlindValue(chips, precision)
  return `${value}bb`;
}

export const bigBlindValue = (chips, precision=1) => {
  return round(chips / blindRatio(), precision)
}

export const blindRatio = () => {
  return checkIsSpecialSim() ? 10 : DEFAULT_BLINDS.big_blind
}

export const roundEVValue = (value, displayInBB=false, precision=2) => {
  return !round(value, precision) ? '' : displayInBB ? `${round(value, precision)}bb` : `${round(value, precision)}`
}

export const formatFromBigBlind = (bigBlind='') => {
  return parseFloat(bigBlind.split('bb')[0] || 0)*100;
}

export const objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const randomInts = (n, min, max, minSum, maxSum) => {
  if (min * n > maxSum || max * n < minSum) {
    return
  }

  var ints = [];
  while (n--) {
      // calculate a lower bound for this number
      // n * max is the max of the next n numbers
      var thisMin = Math.max(min, minSum - n * max);
      // calculate an upper bound for this number
      // n * min is the min of the next n numbers
      var thisMax = Math.min(max, maxSum - n * min);

      var int = getRandomInt(thisMin, thisMax);
      minSum -= int;
      maxSum -= int;
      ints.push(int);
  }
  return ints;
}

// this function is used to fix the editor library's formatting issue
export const formatHTMLText = (text) => {
  return text.replaceAll('<p></p>', '').trim();
}

export const notify = (message, notifyFunction, config={}) => {
  notifyFunction(message, {
    position: "top-center",
    autoClose: config['time'],
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: 0,
  });
}

export const checkFreeTrialUser = (email) => {
  return FREE_TRIAL_USER_EMAILS.includes(email);
}

export const simTypeMapping = (simType) => {
  switch(simType) {
    case 'limped':
     return 'limp'
    case '3bet':
      return '3bp'
    case '4bet':
      return '4bp'
    case 'l3bet':
      return 'l3b'
    case 'l4bet':
      return 'l4b'
    case 'iso':
      return 'liso'
    default:
      return simType
  }
}

export const objectRequestSims = () => {
  return JSON.parse(localStorage.getItem("requests")) || {}
}

export const keysRequestSims = () => {
  return Object.keys(objectRequestSims()) || []
}

export const checkRemoveTypeSRP = (strategySelection) => {
  const { game_type, stack, positions } = strategySelection;
  const positionsTemp = [...positions]

  return isEqual(game_type, 'mtt') && (
          (isEqual(parseInt(stack), parseInt('20')) &&
            ( isEqual(positionsTemp.sort(), ['b1', 'b3'].sort()) ||
              isEqual(positionsTemp.sort(), ['b3', 'b5'].sort())
            )
          )
        )
}

export const checkRemoveTypeLimp = (strategySelection) => {
  const { game_type, stack, positions } = strategySelection;
  const positionsTemp = [...positions]
  return (isEqual(game_type, 'mtt') && (
          ( isEqual(parseInt(stack), parseInt('15')) && (
              isEqual(positionsTemp.sort(), ['bb', 'b3'].sort()) ||
              isEqual(positionsTemp.sort(), ['bb', 'b4'].sort())
            )
          )))
}

export const browser = () => {
  let sBrowser, sUsrAg = navigator.userAgent;
    // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  return sBrowser
}

export const device = () => {
  let device = "Unknown";
  const ua = {
      "Generic Linux": /Linux/i,
      "Android": /Android/i,
      "BlackBerry": /BlackBerry/i,
      "Bluebird": /EF500/i,
      "Chrome OS": /CrOS/i,
      "Datalogic": /DL-AXIS/i,
      "Honeywell": /CT50/i,
      "iPad": /iPad/i,
      "iPhone": /iPhone/i,
      "iPod": /iPod/i,
      "macOS": /Macintosh/i,
      "Windows": /IEMobile|Windows/i,
      "Zebra": /TC70|TC55/i,
  }
  Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}

export const recalculateStrategyObjectRE = (objData, decimal=0) => {
  if(!Object.keys(objData).length) return {};

  Object.keys(objData).forEach(key => {
    objData[key] = round(objData[key]*100, decimal);
  });

  let sumVal = sum(Object.values(objData));
  let diff = 100 - sumVal;
  let largestDecision = Object.keys(objData).reduce((a, b) => objData[a] > objData[b] ? a : b);

  objData[largestDecision] = round(objData[largestDecision] + diff, decimal);
  return objData;
}

export const addPotValueToRawEvData = (evArray, potValue) => {
  if(isEmpty(evArray)) return ''

  evArray = Array.isArray(evArray) ? evArray : evArray.split(' ')
  return evArray.map( evValue => isNaN(evValue) ? 0.0 : parseFloat(evValue) + parseFloat(potValue)).join(' ')
}

export const addPotValueToRawEvRescaleData = (evArray, potValue) => {
  if(isEmpty(evArray)) return ''

  evArray = Array.isArray(evArray) ? evArray : evArray.split(' ')
  return evArray.map( evValue => isNaN(evValue) ? evValue : parseFloat(evValue) + parseFloat(potValue)).join(' ')
}

export const addPotValueToEvObjectData = (evObject, potValue) => {
  for( let key in evObject) {
    evObject[key] = parseFloat(evObject[key]) + parseFloat(potValue)
  }
  return evObject
}

export const fullAccessWithFreeAWeek = (code='', createdDateString='', email = '') => {
  const expiredDate = new Date("2023-03-28 00:00:00 PDT").getTime()
  const currentDate = new Date(moment().tz("America/Los_Angeles").format("YYYY-MM-DD HH:mm:ss z")).getTime()
  return (FREE_A_WEEK_ACCESS_EMAILS.includes(email) && currentDate < expiredDate) || includedFreeAccessFromApr11(email)
}

const includedFreeAccessFromApr11 = (email) => {
  const startTime = new Date("2023-04-11 10:00:00 EDT").getTime()
  const expiredDate = new Date("2023-05-11 00:00:00 EDT").getTime()
  const currentDate = new Date(moment().tz("America/North_America").format("YYYY-MM-DD HH:mm:ss z")).getTime()

  return freeAccess30DaysList["users"].includes(email) && startTime < currentDate && currentDate < expiredDate
}

export const userSubscriptionInvalid = (user) => {
  const nonAccess = ((isEmpty(user.admin_access) || isEqual(user.admin_access, 'free')) &&
         isEmpty(user.accessable_game_type))
  const nonAlt = isNull(user.alt_subscription_id)
  return !isEmpty(user) &&
         !isEqual(user.role, 'admin') &&
         ( nonAccess && nonAlt )
}

export const urlFromAffiliate = (affiliateCodesList) => {
  if (!Array.isArray(affiliateCodesList)) return ""

  return affiliateCodesList.map((affiliate) => affiliate["affiliate_url"])
}

export const getAffiliateCode = (affiliateCode, affiliateCodesList) => {
  if (!Array.isArray(affiliateCodesList)) return ""

  const defaultCode = affiliateCodesList.find((affiliate) => affiliate["is_default"])
  const defaultAffiliateCode = defaultCode ? defaultCode["affiliate_code"] : ""

  if (isEmpty(affiliateCode)) {
    return defaultAffiliateCode
  } else {
    const affiliateDiscountCode = affiliateCodesList.find((affiliate) =>
    affiliate["affiliate_code"] === affiliateCode)

    return affiliateDiscountCode ? affiliateDiscountCode["affiliate_code"] : defaultAffiliateCode
  }
}

export const discountCodeFromAffiliate = (code, affiliateCodesList) => {
  if (!Array.isArray(affiliateCodesList)) return ""
  const defaultCode = affiliateCodesList.find((affiliate) => affiliate["is_default"])
  const affiliateCode = affiliateCodesList.find((affiliate) => affiliate["affiliate_code"] === code)

  return affiliateCode ? affiliateCode["discount_code"] : defaultCode["discount_code"]
}

export const codeDiscountFromAffiliate = (code) => {
  switch(code) {
    case 'pokercode':
      return 'PC45'
    case 'pc50':
      return 'PC50'
    case 'pc':
      return 'PC66'
    case 'pads':
      return 'PADS66'
    case 'pav':
      return 'PAV66'
    case 'bbz':
      return "BBZ66"
    case 'elliot':
      return "ELLIOT66"
    case 'cavalitopoker':
      return 'CAVALITO66'
    case 'lex':
      return 'LEX66'
    case 'sixpoker666':
      return 'SIXPOKER-66'
    case 'reglife':
      return 'REGLIFE66'
    case 'spraggy':
      return 'SPRAGGY66'
    default:
      return code
  }
}

export const revertCodeSubscribe = (pathname, affiliateCodesList) => {
  if (!Array.isArray(affiliateCodesList)) return ""

  const defaultCode = affiliateCodesList.find((affiliate) => affiliate["is_default"])
  const defaultAffiliateCode = defaultCode ? defaultCode["affiliate_code"] : ""

  if(toLower(pathname)) {
    const affiliateCode = affiliateCodesList.find((affiliate) =>
    affiliate["affiliate_url"] === pathname)
    return affiliateCode ? affiliateCode["affiliate_code"] : defaultAffiliateCode
  } else {
    return defaultAffiliateCode
  }
}

export const randomStackSize = (gameType) => {
  if (gameType === 'cash')
    return '100'
  return sample(MMT_TYPE_STACK_OPTIONS)
}

export const randomPositions = ({ players, street, game_type, specs }) => {
  if (street === 'preflop')
    return ['', '']
  const optionSpec = players || specs
  if (game_type === 'cash' && optionSpec === 'hu')
    return ['bb', 'b']
  return randomPositionsWithNumberPlayers(game_type, optionSpec)
}

export const randomPositionsWithNumberPlayers = (gameType, player) => {
  const positionsClone = gameType === 'mtt' ? [...MMT_TYPE_POSITION_OPTIONS] : player === '6max' ? [...CASH_TYPE_POSITION_OPTIONS] : [...MMT_TYPE_POSITION_OPTIONS]
  const positions = []
  range(0,2,1).forEach((item) => {
    const position = sample(positionsClone)
    positionsClone.splice(positionsClone.findIndex(i => i === position), 1)
    positions.push(position)
  })
  return positions
}

export const randomFeatures = (strategySelection) => new Promise ((resolve, reject) => {
  const initStrategy = {...strategySelection}
  const { suit, paired, card, connectedness, flop_type, hero_seat, game_type, stack, random, selectTypes } = initStrategy
  let flops = ''
  const options = [...suit, ...paired, ...card, ...connectedness]
  if (flop_type === 'random' || isEmpty(options)) {
    const flopsArray = Object.keys(FLOP_WEIGHTS)
    const weightArray = Object.values(FLOP_WEIGHTS)
    const convertedFlopCard = randomFlopCardBasedOnWeight(flopsArray, weightArray).match(/.{1,2}/g)
    console.log('===================================================================')
    console.log('ramdom converted flops:', convertedFlopCard)
    const convertRealCardTemplateList = generateRealCardTemplateListFormConvertedCard(convertedFlopCard)
    const ramdomTemplate = sample(convertRealCardTemplateList)
    flops = convertToRealFlopCards(convertedFlopCard, ramdomTemplate)
  } else {
    const selects = { suit: suit[0], paired: paired[0], card: card[0], connectedness: connectedness[0] }
    console.log(`select type flops: ${JSON.stringify(selects)}`)
    flops = generateCustomFlops(options, selects)
  }
  initStrategy['flops'] = flops
  if (hero_seat === 'random')
    initStrategy['hero_seat'] = sample(['ip', 'oop'])
  let selectTypeRandom = selectTypes
  if (stack === 'random') {
    initStrategy['stack'] = randomStackSize(game_type)
    selectTypeRandom = generateListTypes({random, typeOptions: ['srp', '3bp', '4bp'], strategySelection: initStrategy }, true, true)
  }
  if (random) {
    getRandomPositions(initStrategy, selectTypeRandom).then(res => {
      resolve(res)
    })
  } else {
    initStrategy['sim_type'] = sample(selectTypeRandom)
    resolve(initStrategy)
  }
})

const generateCustomFlops = (options, selects) => {
  switch (options.length) {
    case 4:
      return mergeOptionsFlopCards(selects)
    case 3:
      return randomFlopCards3Options(selects)
    case 2:
      return randomFlopCard2Options(selects)
    default:
      return randomFlopCards(options[0])
  }
}

const getRandomPositions = async (initStrategy, selectTypes) => {
  const {game_type, sim_type, stack, players} = initStrategy
  const typeOptionsForRandom =  simTypeOptionsForRandom(selectTypes, game_type)
  const response = await axios.get(`/unique_sims?game_type=${game_type}&sim_type=${sim_type}&stack_size=${stack}&players=${players}`)
  let uniqueSims = response.data.unique_sims
  uniqueSims = uniqueSims.filter(uniqueSim => typeOptionsForRandom.includes(uniqueSim.sim.split('-')[1]))
  const sim = sample(uniqueSims.map(sim => sim.sim))
  initStrategy['sim_type'] = simTypeMappingRevert(sim.split('-')[1])
  initStrategy['positions'] = sim.split('-')[2].split('v').map(position => replace(position.toLowerCase(), '+', ''))

  return initStrategy
}

const simTypeMappingRevert = (simType) => {
  switch(simType) {
    case 'limp':
     return 'limped'
    case 'l3b':
      return 'l3bet'
    case 'l4b':
      return 'l4bet'
    case 'liso':
      return 'iso'
    default:
      return simType
  }
}

const simTypeOptionsForRandom = (selectTypes, game_type) => {
  let simTypeOptions = []
  selectTypes.forEach(type => {
    if(type === 'srp') {
      simTypeOptions = game_type === 'mtt' ? simTypeOptions.concat(['srp', 'limp', 'liso']) : simTypeOptions.concat(['srp'])
    } else if(type === '3bet' ) {
      simTypeOptions = game_type === 'mtt' ? simTypeOptions.concat(['3bp', 'l3b']) : simTypeOptions.concat(['3bp'])
    } else if(type === '4bet') {
      simTypeOptions = game_type === 'mtt' ? simTypeOptions.concat(['4bp', 'l4b']) : simTypeOptions.concat(['4bp'])
    } else {
      simTypeOptions = simTypeOptions.concat([type])
    }
  })
  return simTypeOptions
}

export const generateListTypes = (state, playTheBot=false, filter=false) => {
  const { random, strategySelection: { selectTypes, game_type, stack }, typeOptions } = state
  if (random && playTheBot) {
    if (game_type === 'cash') {
      return ['srp', '3bet', '4bet']
    } else {
      if (['15', '20'].includes(stack)) {
        return ['srp']
      } else if (['25', '30', '40', '50'].includes(stack)) {
        return filter ? selectTypes : ['srp', '3bet']
      } else {
        return filter ? selectTypes : ['srp', '3bet', '4bet']
      }
    }
  } else {
    return typeOptions
  }
}

export const filterTypeOptions = (data, strategySelection) => {
  let typeOptions = data.type_options || [];

  if (checkRemoveTypeSRP(strategySelection))
    typeOptions = typeOptions.filter(option => !isEqual(option, 'srp')) || TYPE_OPTIONS

  if (checkRemoveTypeLimp(strategySelection))
    typeOptions = typeOptions.filter(option => !isEqual(option, 'limped')) || TYPE_OPTIONS
  return typeOptions
}

export const randomFlopCards = (value) => {
  switch (value) {
    case 'rainbow':
      return renderJustRainbowCards()
    case 'flush_draw':
      return renderJustFlushDrawCards()
    case 'monotone':
      return renderJustMonotoneCards()
    case 'not_paired':
      return renderJustNotPairedCards()
    case 'paired':
      return renderJustPairedCards()
    case 'trips':
      return renderJustTripsCard()
    case 'high':
    case 'mid':
    case 'low':
      return renderJustCards(value)
    case 'disconnected':
      return renderJustDisconnectedCards()
    case 'medium':
      return renderJustMediumCards()
    case 'connected':
      return renderJustConnectedCards()
    default:
      return []
  }
}

const mergeOptionsFlopCards = (options) => {
  const { suit, paired, connectedness, card } = options

  if (suit === 'rainbow') {
    switch (paired) {
      case 'not_paired':
        return renderNotPairedCards(card, suit, connectedness)
      case 'trips':
        return renderTripsCard(card, suit, connectedness)
      default:
        return renderPairedCards(card, suit, connectedness)
    }
  }
  if (suit === 'flush_draw') {
    const newPaired = paired === 'trips' ? sample(['not_paired', 'paired']) : paired
    switch (newPaired) {
      case 'not_paired':
        return renderNotPairedCards(card, suit, connectedness)
      default:
        return renderPairedCards(card, suit, connectedness)
    }
  }
  if (suit === 'monotone') {
    return renderCards(card, suit, connectedness)
  }
}

const randomFlopCard2Options = (options) => {
  const { suit, paired, connectedness, card } = options
  if (!isEmpty(suit)) {
    if (!isEmpty(card) && isEmpty(connectedness) && isEmpty(paired)) {
      return renderSuitCard(options)
    }
    if (isEmpty(card) && !isEmpty(connectedness) && isEmpty(paired)) {
      return renderSuitConnectedness(options)
    }
    if (isEmpty(card) && isEmpty(connectedness) && !isEmpty(paired)) {
      return renderSuitPaired(options)
    }
  } else if (isEmpty(card)) {
    if (!isEmpty(connectedness) && !isEmpty(paired)) {
      return renderConnectednessPaired(options)
    }
  } else {
    if (!isEmpty(connectedness) && isEmpty(paired)) {
      return renderCardConnectedness(options)
    }
    if (isEmpty(connectedness) && !isEmpty(paired)) {
      return renderCardPaired(options)
    }
  }
}

const randomFlopCards3Options = (options) => {
  const { suit, paired, connectedness, card } = options
  if (isEmpty(paired)) { return renderCards(card, suit, connectedness) }
  if (isEmpty(card)) { return renderSuitConnectedness(options) }
  if (isEmpty(suit)) { return renderCardConnectedness(options) }
  if (isEmpty(connectedness)) {
    if (['rainbow', 'flush_draw'].includes(suit)) {
      switch (paired) {
        case 'not_paired':
          return renderNotPairedCards(card, suit, '')
        case 'paired':
          return renderPairedCards(card, suit, '')
        default:
          return renderTripsCard(card, suit, '')
      }
    } else {
      return renderNotPairedCards(card, suit, '')
    }
  }
}

export const payloadNewSim = (initialize) => {
  const { game_type, street, players, specs, sim_type, flops, randomPositions, stack, site, positions, random } = initialize
  if (isEmpty(sim_type)) { console.log('error null sim type') }
  const valueSpecs = players || specs
  let payload = {}
  if (isEqual(street, 'preflop')) {
    if (game_type === 'cash') {
      const stake = site === 'party' ?
                              PARTY_SITE_STAKE_OPTIOINS[0].value :
                              STARS_SITE_STAKE_OPTIOINS[0].value
      const openSize = DEFAULT_OPEN_SIZE
      payload = { street, game_type, site, stack, stake, specs: valueSpecs, flops: ['','',''], open_size: openSize, positions: ['', ''] }
    } else {
      payload = { street, stack, game_type, specs: valueSpecs, positions: ['', ''],  flops: ['','',''] }
    }
  } else {
    payload = { game_type, street, sim_type, flops, stack, specs: valueSpecs, positions: random ? positions : randomPositions }
  }
  return payload
}

export const inValidFlopsCard = (strategySelection) => {
  const { game_type, street, stack, positions, sim_type } = strategySelection
  const inValidPositons = [['b1', 'b2'].sort(), ['b', 'b1'].sort()]
  return isEqual(street, 'postflop') &&
         isEqual(game_type, 'mtt') &&
         isEqual(stack, '50') &&
         isEqual(sim_type, '4bp') &&
         (isEqual(inValidPositons[0], positions.sort()) || isEqual(inValidPositons[1], positions.sort()))
}

export const findFlopIndexBasedOnRandomWeight = (prefix, random, start, end) => {
  let mid
  while (start < end) {
    mid = Math.floor((start + end) / 2)
    if (random > prefix[mid]) {
        start = mid + 1
    } else {
        end = mid
    }
  }
  return (prefix[start] >= random) ? start : -1
}

export const randomFlopCardBasedOnWeight = (flops, weights) => {
  const size = flops.length
  const prefix = [] // Create and fill prefix array
  prefix[0] = weights[0]
  for (let i = 1; i < size; ++i) {
      prefix[i] = prefix[i - 1] + weights[i]
  }
  // prefix[n-1] is sum of all frequencies.
  // Generate a random number with value from 1 to this sum
  const randomWeight = Math.floor((Math.random()* prefix[size - 1])) + 1
  // Find index of ceiling of randomWeight in prefix array
  const flopIndex = findFlopIndexBasedOnRandomWeight(prefix, randomWeight, 0, size - 1)
  return flops[flopIndex]
}

export const nodeScore = (highest, score) => {
  let result = 0.0

  if (highest > 0 && highest <= 1.5) {
    result = 100 - (300 * (highest - score))
  } else {
    result = 100 - (1000 * ((highest - score) / highest))
  }
  return round(result, PTB_NODE_SCORE_DECIMAL)
}

export const statusScoreEV = (score) => {
  if (score === 100) {
    return 'perfect'
  } else if (score < 100 && score >= 80 ) {
    return 'good'
  } else if (score < 80 && score >= 50 ) {
    return 'better'
  } else if (score < 50 && score >= 1 ) {
    return 'mistake'
  } else {
    return 'punt'
  }
}

export const calcNumStatusScoreEV = (scores) => {
  let statusGroup = {
    perfect: 0,
    good: 0,
    better: 0,
    mistake: 0,
    punt: 0
  }

  scores.forEach(score => {
    if(score < 1) {
      statusGroup['punt'] += 1
    } else if (score < 50 && score >= 1 ) {
      statusGroup['mistake'] += 1
    } else if (score < 80 && score >= 49 )  {
      statusGroup['better'] += 1
    } else if (score < 100 && score >= 80 ) {
      statusGroup['good'] += 1
    }  else {
      statusGroup['perfect'] += 1
    }
  })
  return statusGroup
}

export const randomTurnRiverCard = (flops, holdCardsOop, holdCardsIp, turnCard) => {
  const cards = CARD_OPTIONS.map((suitedCards, index) => {
    return `${suitedCards.rank === '10' ? 'T' : suitedCards.rank}${suitedCards.suit}`
  })
  const existCards = [...flops, ...holdCardsOop.match(/.{1,2}/g), ...holdCardsIp.match(/.{1,2}/g)]
  if (!isEmpty(turnCard)) existCards.push(turnCard)
  const extantCards = xor(cards, existCards)
  return sample(extantCards)

}
