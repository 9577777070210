import { parseInt } from 'lodash';

class Response {

  constructor({ data, headers, pages, page, sorting, pageSize, requestStartedAt }) {
    this.data = data;
    this.headers = headers;
    this.pages = parseInt(pages);
    this.page = parseInt(page);
    this.pageSize = parseInt(pageSize);
    this.sorting = sorting;
    this.requestStartedAt = parseInt(requestStartedAt);
  }
}

export default Response;
