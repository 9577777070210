export const gameActions = {
  REFRESH_GAME_DATA: 'REFRESH_GAME_DATA',
  UPDATE_FLOPS: 'UPDATE_FLOPS',
  UPDATE_GAME_DATA: 'UPDATE_GAME_DATA',
  RECIEVE_FLOP_DATA: 'RECIEVE_FLOP_DATA',
  UPDATE_BET_COMPLETED_STATUS: 'UPDATE_BET_COMPLETED_STATUS',
  UPDATE_TURN_CARD: 'UPDATE_TURN_CARD',
  UPDATE_RIVER_CARD: 'UPDATE_RIVER_CARD',
  CLEAR_CARDS: 'CLEAR_CARDS',
  UPDATE_COMPARED_DECISIONS: 'UPDATE_COMPARED_DECISIONS',
  FETCH_DATA_FOR_TURN_RIVER_ROUND: 'FETCH_DATA_FOR_TURN_RIVER_ROUND',
  FETCHED_DATA_SUCCESSFULLY: 'FETCHED_DATA_SUCCESSFULLY',
  UPDATE_RUNOUTS_EXPLORER_DATA: 'UPDATE_RUNOUTS_EXPLORER_DATA',
  CLEAR_RUNOUTS_EXPLORER_DATA: 'CLEAR_RUNOUTS_EXPLORER_DATA',
  UPDATE_TREE_INFO: 'UPDATE_TREE_INFO',
  UPDATE_FETCH_TURN_RIVER_SUCCESS: 'UPDATE_FETCH_TURN_RIVER_SUCCESS',
  FETCH_STACKSIZE: 'FETCH_STACKSIZE',
  UPDATE_PROCESSING_CLICK_NODE: 'UPDATE_PROCESSING_CLICK_NODE'
}
