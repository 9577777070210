const initialState = [];

export function historyGamePlay(state = initialState, action) {
  switch (action.type) {
    case 'ADD_SIM':
      return state.concat(action.payload)
    case 'UPDATE_SIM':
      return action.payload
    case 'RESET_SIMS':
      return []
    default:
      return state;
  }
}
