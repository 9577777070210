const initialState = {};

export function bot(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_BOT':
      return {
        ...state,
        ...action.payload
      }
    case 'ADD_HOLDCARDS':
      return {
        ...state,
        holdCards: action.payload
      }
    case 'REMOVE_BOT':
      return {}
    default:
      return state;
  }
}
