import { affiliateDiscountCodeActions } from '../constants/index';

let initialState = {}

export function affiliateDiscountCodes(state = initialState, action) {
  switch (action.type) {
    case affiliateDiscountCodeActions.SET_AFFILIATE_DISCOUNT_CODES:
      return action.payload
    default:
      return state;
  }
}
