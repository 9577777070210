export const TONES = ['h', 'd', 's', 'c']
export const HIGH = ['Q', 'K', 'A']
export const MEDIUM = ['9', '10', 'J']
export const LOW = ['2', '3', '4', '5', '6', '7', '8']
export const STREET_SELECT_OPTIONS = ['preflop', 'postflop']
export const DEFAULT_STREET_OPTIONS = STREET_SELECT_OPTIONS[1]
export const GAME_TYPES = ['cash', 'mtt']
export const PLAYERS_OPTIONS = ['6max', 'hu', '8max']
export const DEFAULT_GAME_TYPE = GAME_TYPES[0]
export const DEFAULT_PLAYERS = PLAYERS_OPTIONS[0]
export const FULL_CARDS = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A']
export const CASH_TYPE_STACK_OPTIONS = ['100']
export const MMT_TYPE_STACK_OPTIONS = ['15', '20', '25', '30', '40', '50', '100']
export const DEFAULT_STACK_SIZE = {
  cash: CASH_TYPE_STACK_OPTIONS[0],
  mtt: MMT_TYPE_STACK_OPTIONS[0]
}
export const DEFAULT_STACK_OPTIONS = {
  cash: CASH_TYPE_STACK_OPTIONS,
  mtt: MMT_TYPE_STACK_OPTIONS
}

export const SUITS = ['Rainbow', 'Flush Draw', 'Monotone']
export const PAIRED = ['Not Paired', 'Paired', 'Trips']
export const LEVEL_CARDS = ['High', 'Mid', 'Low']
export const CONNECTEDNESS = ['Disconnected', 'Medium', 'Connected']
export const RANDOM_FLOPS = ['rainbow', 'flush_draw', 'monotone', 'not_paired', 'paired', 'trips', 'high', 'mid', 'low', 'disconnected', 'medium', 'connected']
export const HERO_SEAT = ['IP', 'OOP']

export const SUBSRIPTIONS_OBJECT = {
  free: 'None',
  mtts: 'MTTs',
  cash: 'CASH',
  ultimate: 'ULTIMATE'
}

export const SUBSCRIPTION_PAID_STATUSES = {
  true: 'Paid',
  false: 'Failed'
}

export const SUBSCRIPTION_TYPES = {
  'mtts': 'MTTs',
  'cash': 'CASH',
  'ultimate': 'ULTIMATE',
  'mtts-alt': 'MTTs - ALT',
  'cash-alt': 'CASH - ALT',
  'ultimate-alt': 'ULTIMATE - ALT'
}

export const CASH_TYPE_POSITION_OPTIONS = ['bb', 'sb', 'b', 'b1', 'b2', 'b3']
export const MMT_TYPE_POSITION_OPTIONS = ['bb', 'sb', 'b', 'b1', 'b2', 'b3', 'b4', 'b5']
export const POSITIONS_COUNT = 2
export const DEFAULT_POSITION_OPTIONS = {
  cash: CASH_TYPE_POSITION_OPTIONS,
  mtt: MMT_TYPE_POSITION_OPTIONS
}
export const DEFAULT_POSITIONS = {
  cash: CASH_TYPE_POSITION_OPTIONS.slice(0, 2),
  mtt: MMT_TYPE_POSITION_OPTIONS.slice(0, 2)
}

export const TWO_MINUTES = 120000

export const AFFILIATE_CODES = [
  'ggplatinum', 'pokercode', 'ginge', 'elliot', 'bts', 'streamteam', 'cavalitopoker', 'pc', 'kmart', 'pc50', 'pads', 'pav', 'jnandez', 'reglife', 'obex',
  'bbz', 'sixpoker666', 'pokerdetox', 'jeff', 'lex', 'apestyles', 'jargo', 'rodrigo', 'gtoexploit', 'luckyfish89', 'spraggy', 'easywithaces', 'james', 'wolf', 'robinpoker', 'conor', 'prometheus', 'solcity'
]
export const LINKS_LAYOUT = [
  '/gg', '/pokercode', '/ginge', '/elliot', '/bts', '/subscribe', '/pokercode/subscribe', '/streamteam', '/cavalitopoker', '/pc', '/kmart', '/pads', '/pav', '/jnandez', '/robinpoker',
  '/reglife', '/obex', '/bbz', '/sixpoker666', '/pokerdetox', '/jeff', '/lex', '/apestyles', '/jargo', '/rodrigo', '/gtoexploit', '/luckyfish89', '/spraggy', '/james', '/wolf', '/easywithaces', '/conor', '/solcity'
]
export const LINKS = [
  '/', '/gg', '/pokercode', '/ginge', '/elliot', '/bts', '/streamteam', '/cavalitopoker','/pc', '/kmart', '/pads', '/pav',
  '/jnandez', '/reglife', '/obex', '/bbz', '/sixpoker666', '/pokerdetox', '/jeff', '/lex', '/apestyles', '/jargo', '/rodrigo', '/gtoexploit', '/luckyfish89', '/spraggy', '/james','/wolf', '/easywithaces', '/robinpoker', '/conor', '/solcity'
]

export const PERCENT_DISCOUNT = {
  // code --> affiliate code, value --> discount percent
  pokercode: 0.45,
  pc50: 0.5,
  ggplatinum: 0.35,
  ginge: 0.35,
  elliot: 0.56,
  bts: 0.35,
  streamteam: 0.35,
  cavalitopoker: 0.56,
  launch35: 0.35,
  pc: 0.56,
  kmart: 0.35,
  pads: 0.56,
  pav: 0.56,
  jnandez: 0.35,
  apestyles: 0.35,
  reglife: 0.56,
  obex: 0.35,
  bbz: 0.56,
  sixpoker666: 0.56,
  pokerdetox: 0.35,
  jeff: 0.35,
  lex: 0.56,
  luckyfish89: 0.35,
  jargo: 0.35,
  rodrigo: 0.35,
  gtoexploit: 0.35,
  spraggy: 0.56,
  wolf: 0.35,
  easywithaces: 0.35,
  robinpoker: 0.35,
  james: 0.35,
  conor: 0.35,
  prometheus: 0.56,
  solcity: 0.56
}

export const AFFILIATE_CODES_OBJECT = {
  ggplatinum: 'GG',
  pokercode: 'Pokercode',
  ginge: 'Ginge',
  elliot: 'Elliot',
  bts: 'BTS',
  streamteam: 'Streamteam',
  cavalitopoker: 'Cavalitopoker',
  pc: 'PC',
  launch35: 'No Affiliate',
  kmart: 'Kmart',
  pav: 'PAV35',
  jnandez: 'JNANDEZ',
  pads: 'PADS',
  reglife: 'REGLIFE',
  obex: 'OBEX',
  bbz: 'BBZ',
  sixpoker666: 'SixPoker666',
  pokerdetox: 'Pokerdetox',
  jeff: 'JEFF',
  lex: 'LEX',
  apestyles: 'APESTYLES',
  luckyfish89: 'LUCKYFISH89',
  jargo: 'JARGO',
  rodrigo: 'RODRIGO',
  gtoexploit: 'GTOEXPLOIT',
  spraggy: 'SPRAGGY',
  easywithaces: "Easywithaces",
  wolf: 'WOLF',
  robinpoker: "RobinPoker",
  james: 'JAMES',
  xmas35: 'No Affiliate',
  prometheus: 'PROMETHEUS',
  conor: 'CONOR',
  solcity: 'SolCity'
}

export const HUNDRED_PERCENT = 1

export const TYPE_OPTIONS = []

export const SITE_OPTIONS = ['stars', 'party']
export const SITE_OPTIONS_HU_PLAYER = ['stars']
export const DEFAULT_SITE = SITE_OPTIONS[0]

export const STARS_SITE_STAKE_OPTIOINS = [{ label: '$2.50 / 5 Zoom', value: '$2.50_per_5_zoom' }]
export const PARTY_SITE_STAKE_OPTIOINS = [{ label: '$2.50 / 5 Fastforward', value: '$2.50_per_5_fastforward' }]
export const HU_PLAYER_STAKE_OPTIOINS = [{ label: '$50 / 100', value: '$50_per_100' }]

export const DEFAULT_STAKE_OPTIONS = {
  '6max': {
    stars: STARS_SITE_STAKE_OPTIOINS,
    party: PARTY_SITE_STAKE_OPTIOINS,
  },
  '8max': {
    stars: STARS_SITE_STAKE_OPTIOINS,
    party: PARTY_SITE_STAKE_OPTIOINS,
  },
  'hu': {
    stars: HU_PLAYER_STAKE_OPTIOINS,
  }
}

export const OPEN_SIZE_OPTIONS = ['2.5bb']
export const DEFAULT_OPEN_SIZE = OPEN_SIZE_OPTIONS[0]

export const ALTERNATE_BLIND_VS_BLIND_STRATEGIES_OPTIONS = ['4x','4x & Limp','3x & Limp','Limp only']
export const DEFAULT_ALTERNATE_BLIND_VS_BLIND_STRATEGIES = ALTERNATE_BLIND_VS_BLIND_STRATEGIES_OPTIONS[0]

export const PREFLOP_CASH_8MAX_FIELDS = {
  positions: ['', ''],
  flops: ['', '', ''],
  site: '',
  stake: '',
  open_size: '',
  sim_type: '',
  alternate_blind_vs_blind_strategies: ''
}

export const PREFLOP_CASH_BLANK_FIELDS = {
  positions: ['', ''],
  sim_type: '',
  flops: ['', '', ''],
}
export const PREFLOP_MMT_BLANK_FIELDS = {
  positions: ['', ''],
  sim_type: '',
  flops: ['', '', ''],
  site: '',
  stake: '',
  open_size: ''
}
export const POSTFLOP_BLANK_FIELDS = {
  site: '',
  stake: '',
  open_size: '',
}

export const CARD_OPTIONS = [
  { rank: '2', suit: 'c' }, { rank: '3', suit: 'c' }, { rank: '4', suit: 'c' },
  { rank: '5', suit: 'c' }, { rank: '6', suit: 'c' }, { rank: '7', suit: 'c' },
  { rank: '8', suit: 'c' }, { rank: '9', suit: 'c' }, { rank: '10', suit: 'c' },
  { rank: 'J', suit: 'c' }, { rank: 'Q', suit: 'c' }, { rank: 'K', suit: 'c' },
  { rank: 'A', suit: 'c' },

  { rank: '2', suit: 'd' }, { rank: '3', suit: 'd' }, { rank: '4', suit: 'd' },
  { rank: '5', suit: 'd' }, { rank: '6', suit: 'd' }, { rank: '7', suit: 'd' },
  { rank: '8', suit: 'd' }, { rank: '9', suit: 'd' }, { rank: '10', suit: 'd' },
  { rank: 'J', suit: 'd' }, { rank: 'Q', suit: 'd' }, { rank: 'K', suit: 'd' },
  { rank: 'A', suit: 'd' },

  { rank: '2', suit: 'h' }, { rank: '3', suit: 'h' }, { rank: '4', suit: 'h' },
  { rank: '5', suit: 'h' }, { rank: '6', suit: 'h' }, { rank: '7', suit: 'h' },
  { rank: '8', suit: 'h' }, { rank: '9', suit: 'h' }, { rank: '10', suit: 'h' },
  { rank: 'J', suit: 'h' }, { rank: 'Q', suit: 'h' }, { rank: 'K', suit: 'h' },
  { rank: 'A', suit: 'h' },

  { rank: '2', suit: 's' }, { rank: '3', suit: 's' }, { rank: '4', suit: 's' },
  { rank: '5', suit: 's' }, { rank: '6', suit: 's' }, { rank: '7', suit: 's' },
  { rank: '8', suit: 's' }, { rank: '9', suit: 's' }, { rank: '10', suit: 's' },
  { rank: 'J', suit: 's' }, { rank: 'Q', suit: 's' }, { rank: 'K', suit: 's' },
  { rank: 'A', suit: 's' }
]

export const FLOP_CARDS_COUNT = 3
export const DEFAULT_FLOP_CARDS = ['', '', '']

export const STREET_OPTIONS = [
  { label: 'Preflop', value: 'preflop'},
  { label: 'Flop', value: 'flop'},
  { label: 'Flop and turn', value: 'flop_and_turn'},
  { label: 'Flop, turn and river', value: 'flop_turn_and_river'},
];

export const PLAYER_OPTIONS = [
  { label: 'OOP', value: 'oop' },
  { label: 'IP', value: 'ip' }
]

export const SIMPLIFIER = {
  hideStrategiesLessThan: {
    defaultValue: 0.02,
    minValue: 0.0,
    maxValue: 0.1,
    stepValue: 0.05,
    percentageOptions: [
      {label: '0%', value: 0.0 },
      {label: '0.1%', value: 0.001 },
      {label: '0.5%', value: 0.005 },
      {label: '1%', value: 0.01 },
      {label: '2%', value: 0.02 },
      {label: '3%', value: 0.03 },
      {label: '4%', value: 0.04 },
      {label: '5%', value: 0.05 },
      {label: '7.5%', value: 0.075 },
      {label: '10%', value: 0.1 }
    ]
  },
  roundStrategiesToClosest: {
    defaultValue: 0.0,
    minValue: 0.0,
    percentageOptions: [
      {label: '0%', value: 0.00 },
      {label: '1%', value: 0.01 },
      {label: '5%', value: 0.05 },
      {label: '10%', value: 0.1 },
      {label: '20%', value: 0.2 },
      {label: '25%', value: 0.25 },
      {label: '50%', value: 0.5 },
      {label: '100%', value: 1 }
    ]
  }
}

export const CARD_RANK_ORDER = {
  '2': 1, '3': 2, '4': 3, '5': 4, '6': 5, '7': 6, '8': 7, '9': 8,
  '10': 9, 'J': 10, 'Q': 11, 'K': 12, 'A': 13
}
export const ACE_LOW_CARD_RANK_ORDER = {
  'A': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
  '10': 10, 'J': 11, 'Q': 12, 'K': 13
}

export const MIN_EV_PER_EQ_COLOR = "#E23752"
export const MAX_EV_PER_EQ_COLOR = "#06E1C2"

/* merge 2 constants files*/
export const LEFT_SIZE_COORDINATE = 0
export const NUMBER_OF_ROW_IN_EACH_COL = 4
export const OPTIONS_IN_A_ROW = 2
export const OPTIONS_LEFT = [
  { label: 'strategy', value: 'strategy' },
  { label: 'range', value: 'ranges' },
  { label: 'equity', value: 'equity' },
  { label: 'ev', value: 'ev' }
]
export const OPTIONS_RIGHT = [
'range-explorer', 'compare-ev', 'tree-building',
'runouts-explorer', 'notes', 'options'
]

export const ROOT_NODE = "r:0"
export const DEFAULT_INITIAL_STACK_SIZE = 1300
export const DEFAULT_POT_IP = 0
export const DEFAULT_POT_OOP = 0
export const DEFAULT_POT_MAIN = 2300
export const DEFAULT_TOTAL_EQ_EV = 500
export const DEFAULT_BEGIN_ROUND_POSITION = "oop"
export const DEFAULT_NODE_COUNT = 2
export const COLOR_SECTION_TITLES = ['fold', 'check_and_call', 'bet_and_raise']
export const DEFAULT_SIM_ITEM_PER_PAGE = 20
export const DEFAULT_UNIVERSAL_NOTE_ITEMS_PER_PAGE = 10
export const DEFAULT_SUBSCRIPTION_ITEMS_PER_PAGE = 12
export const DEFAULT_AFFILIATE_CODE_PER_PAGE = 12
export const DEFAULT_IP_HISTORY_ITEM_PER_PAGE = 2

export const HANDS_LIST = [
  "AA", "AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s", "A5s", "A4s", "A3s", "A2s",
  "AKo", "KK", "KQs", "KJs", "KTs", "K9s", "K8s", "K7s", "K6s", "K5s", "K4s", "K3s", "K2s",
  "AQo", "KQo", "QQ", "QJs", "QTs", "Q9s", "Q8s", "Q7s", "Q6s", "Q5s", "Q4s", "Q3s", "Q2s",
  "AJo", "KJo", "QJo", "JJ", "JTs", "J9s", "J8s", "J7s", "J6s", "J5s", "J4s", "J3s", "J2s",
  "ATo", "KTo", "QTo", "JTo", "TT", "T9s", "T8s", "T7s", "T6s", "T5s", "T4s", "T3s", "T2s",
  "A9o", "K9o", "Q9o", "J9o", "T9o", "99", "98s", "97s", "96s", "95s", "94s", "93s", "92s",
  "A8o", "K8o", "Q8o", "J8o", "T8o", "98o", "88", "87s", "86s", "85s", "84s", "83s", "82s",
  "A7o", "K7o", "Q7o", "J7o", "T7o", "97o", "87o", "77", "76s", "75s", "74s", "73s", "72s",
  "A6o", "K6o", "Q6o", "J6o", "T6o", "96o", "86o", "76o", "66", "65s", "64s", "63s", "62s",
  "A5o", "K5o", "Q5o", "J5o", "T5o", "95o", "85o", "75o", "65o", "55", "54s", "53s", "52s",
  "A4o", "K4o", "Q4o", "J4o", "T4o", "94o", "84o", "74o", "64o", "54o", "44", "43s", "42s",
  "A3o", "K3o", "Q3o", "J3o", "T3o", "93o", "83o", "73o", "63o", "53o", "43o", "33", "32s",
  "A2o", "K2o", "Q2o", "J2o", "T2o", "92o", "82o", "72o", "62o", "52o", "42o", "32o", "22"
];

export const COMBOS = [
  '2d2c', '2h2c', '2h2d', '2s2c', '2s2d', '2s2h', '3c2c', '3c2d', '3c2h', '3c2s', '3d2c', '3d2d', '3d2h', '3d2s', '3d3c', '3h2c', '3h2d', '3h2h', '3h2s', '3h3c', '3h3d',
  '3s2c', '3s2d', '3s2h', '3s2s','3s3c', '3s3d', '3s3h', '4c2c', '4c2d', '4c2h', '4c2s', '4c3c', '4c3d', '4c3h', '4c3s', '4d2c', '4d2d', '4d2h', '4d2s', '4d3c', '4d3d',
  '4d3h', '4d3s', '4d4c', '4h2c', '4h2d', '4h2h', '4h2s', '4h3c', '4h3d','4h3h', '4h3s', '4h4c', '4h4d', '4s2c', '4s2d', '4s2h', '4s2s', '4s3c', '4s3d', '4s3h', '4s3s',
  '4s4c', '4s4d', '4s4h', '5c2c', '5c2d', '5c2h', '5c2s', '5c3c', '5c3d', '5c3h', '5c3s', '5c4c', '5c4d', '5c4h','5c4s', '5d2c', '5d2d', '5d2h', '5d2s', '5d3c', '5d3d',
  '5d3h', '5d3s', '5d4c', '5d4d', '5d4h', '5d4s', '5d5c', '5h2c', '5h2d', '5h2h', '5h2s', '5h3c', '5h3d', '5h3h', '5h3s', '5h4c', '5h4d', '5h4h', '5h4s','5h5c', '5h5d',
  '5s2c', '5s2d', '5s2h', '5s2s', '5s3c', '5s3d', '5s3h', '5s3s', '5s4c', '5s4d', '5s4h', '5s4s', '5s5c', '5s5d', '5s5h', '6c2c', '6c2d', '6c2h', '6c2s', '6c3c', '6c3d',
  '6c3h', '6c3s', '6c4c','6c4d', '6c4h', '6c4s', '6c5c', '6c5d', '6c5h', '6c5s', '6d2c', '6d2d', '6d2h', '6d2s', '6d3c', '6d3d', '6d3h', '6d3s', '6d4c', '6d4d', '6d4h',
  '6d4s', '6d5c', '6d5d', '6d5h', '6d5s', '6d6c', '6h2c', '6h2d','6h2h', '6h2s', '6h3c', '6h3d', '6h3h', '6h3s', '6h4c', '6h4d', '6h4h', '6h4s', '6h5c', '6h5d', '6h5h',
  '6h5s', '6h6c', '6h6d', '6s2c', '6s2d', '6s2h', '6s2s', '6s3c', '6s3d', '6s3h', '6s3s', '6s4c', '6s4d','6s4h', '6s4s', '6s5c', '6s5d', '6s5h', '6s5s', '6s6c', '6s6d',
  '6s6h', '7c2c', '7c2d', '7c2h', '7c2s', '7c3c', '7c3d', '7c3h', '7c3s', '7c4c', '7c4d', '7c4h', '7c4s', '7c5c', '7c5d', '7c5h', '7c5s', '7c6c','7c6d', '7c6h', '7c6s',
  '7d2c', '7d2d', '7d2h', '7d2s', '7d3c', '7d3d', '7d3h', '7d3s', '7d4c', '7d4d', '7d4h', '7d4s', '7d5c', '7d5d', '7d5h', '7d5s', '7d6c', '7d6d', '7d6h', '7d6s', '7d7c',
  '7h2c', '7h2d','7h2h', '7h2s', '7h3c', '7h3d', '7h3h', '7h3s', '7h4c', '7h4d', '7h4h', '7h4s', '7h5c', '7h5d', '7h5h', '7h5s', '7h6c', '7h6d', '7h6h', '7h6s', '7h7c',
  '7h7d', '7s2c', '7s2d', '7s2h', '7s2s', '7s3c', '7s3d','7s3h', '7s3s', '7s4c', '7s4d', '7s4h', '7s4s', '7s5c', '7s5d', '7s5h', '7s5s', '7s6c', '7s6d', '7s6h', '7s6s',
  '7s7c', '7s7d', '7s7h', '8c2c', '8c2d', '8c2h', '8c2s', '8c3c', '8c3d', '8c3h', '8c3s', '8c4c','8c4d', '8c4h', '8c4s', '8c5c', '8c5d', '8c5h', '8c5s', '8c6c', '8c6d',
  '8c6h', '8c6s', '8c7c', '8c7d', '8c7h', '8c7s', '8d2c', '8d2d', '8d2h', '8d2s', '8d3c', '8d3d', '8d3h', '8d3s', '8d4c', '8d4d', '8d4h','8d4s', '8d5c', '8d5d', '8d5h',
  '8d5s', '8d6c', '8d6d', '8d6h', '8d6s', '8d7c', '8d7d', '8d7h', '8d7s', '8d8c', '8h2c', '8h2d', '8h2h', '8h2s', '8h3c', '8h3d', '8h3h', '8h3s', '8h4c', '8h4d', '8h4h',
  '8h4s','8h5c', '8h5d', '8h5h', '8h5s', '8h6c', '8h6d', '8h6h', '8h6s', '8h7c', '8h7d', '8h7h', '8h7s', '8h8c', '8h8d', '8s2c', '8s2d', '8s2h', '8s2s', '8s3c', '8s3d',
  '8s3h', '8s3s', '8s4c', '8s4d', '8s4h', '8s4s','8s5c', '8s5d', '8s5h', '8s5s', '8s6c', '8s6d', '8s6h', '8s6s', '8s7c', '8s7d', '8s7h', '8s7s', '8s8c', '8s8d', '8s8h',
  '9c2c', '9c2d', '9c2h', '9c2s', '9c3c', '9c3d', '9c3h', '9c3s', '9c4c', '9c4d', '9c4h','9c4s', '9c5c', '9c5d', '9c5h', '9c5s', '9c6c', '9c6d', '9c6h', '9c6s', '9c7c',
  '9c7d', '9c7h', '9c7s', '9c8c', '9c8d', '9c8h', '9c8s', '9d2c', '9d2d', '9d2h', '9d2s', '9d3c', '9d3d', '9d3h', '9d3s', '9d4c','9d4d', '9d4h', '9d4s', '9d5c', '9d5d',
  '9d5h', '9d5s', '9d6c', '9d6d', '9d6h', '9d6s', '9d7c', '9d7d', '9d7h', '9d7s', '9d8c', '9d8d', '9d8h', '9d8s', '9d9c', '9h2c', '9h2d', '9h2h', '9h2s', '9h3c', '9h3d',
  '9h3h', '9h3s', '9h4c', '9h4d', '9h4h', '9h4s', '9h5c', '9h5d', '9h5h', '9h5s', '9h6c', '9h6d', '9h6h', '9h6s', '9h7c', '9h7d', '9h7h', '9h7s', '9h8c', '9h8d', '9h8h',
  '9h8s', '9h9c', '9h9d', '9s2c', '9s2d', '9s2h', '9s2s', '9s3c', '9s3d', '9s3h', '9s3s', '9s4c', '9s4d', '9s4h', '9s4s', '9s5c', '9s5d', '9s5h', '9s5s', '9s6c', '9s6d',
  '9s6h', '9s6s', '9s7c', '9s7d', '9s7h', '9s7s', '9s8c', '9s8d', '9s8h', '9s8s','9s9c', '9s9d', '9s9h', 'Tc2c', 'Tc2d', 'Tc2h', 'Tc2s', 'Tc3c', 'Tc3d', 'Tc3h', 'Tc3s',
  'Tc4c', 'Tc4d', 'Tc4h', 'Tc4s', 'Tc5c', 'Tc5d', 'Tc5h', 'Tc5s', 'Tc6c', 'Tc6d', 'Tc6h', 'Tc6s', 'Tc7c', 'Tc7d', 'Tc7h','Tc7s', 'Tc8c', 'Tc8d', 'Tc8h', 'Tc8s', 'Tc9c',
  'Tc9d', 'Tc9h', 'Tc9s', 'Td2c', 'Td2d', 'Td2h', 'Td2s', 'Td3c', 'Td3d', 'Td3h', 'Td3s', 'Td4c', 'Td4d', 'Td4h', 'Td4s', 'Td5c', 'Td5d', 'Td5h', 'Td5s', 'Td6c','Td6d',
  'Td6h', 'Td6s', 'Td7c', 'Td7d', 'Td7h', 'Td7s', 'Td8c', 'Td8d', 'Td8h', 'Td8s', 'Td9c', 'Td9d', 'Td9h', 'Td9s', 'TdTc', 'Th2c', 'Th2d', 'Th2h', 'Th2s', 'Th3c', 'Th3d',
  'Th3h', 'Th3s', 'Th4c', 'Th4d','Th4h', 'Th4s', 'Th5c', 'Th5d', 'Th5h', 'Th5s', 'Th6c', 'Th6d', 'Th6h', 'Th6s', 'Th7c', 'Th7d', 'Th7h', 'Th7s', 'Th8c', 'Th8d', 'Th8h',
  'Th8s', 'Th9c', 'Th9d', 'Th9h', 'Th9s', 'ThTc', 'ThTd', 'Ts2c', 'Ts2d','Ts2h', 'Ts2s', 'Ts3c', 'Ts3d', 'Ts3h', 'Ts3s', 'Ts4c', 'Ts4d', 'Ts4h', 'Ts4s', 'Ts5c', 'Ts5d',
  'Ts5h', 'Ts5s', 'Ts6c', 'Ts6d', 'Ts6h', 'Ts6s', 'Ts7c', 'Ts7d', 'Ts7h', 'Ts7s', 'Ts8c', 'Ts8d', 'Ts8h', 'Ts8s','Ts9c', 'Ts9d', 'Ts9h', 'Ts9s', 'TsTc', 'TsTd', 'TsTh',
  'Jc2c', 'Jc2d', 'Jc2h', 'Jc2s', 'Jc3c', 'Jc3d', 'Jc3h', 'Jc3s', 'Jc4c', 'Jc4d', 'Jc4h', 'Jc4s', 'Jc5c', 'Jc5d', 'Jc5h', 'Jc5s', 'Jc6c', 'Jc6d', 'Jc6h','Jc6s', 'Jc7c',
  'Jc7d', 'Jc7h', 'Jc7s', 'Jc8c', 'Jc8d', 'Jc8h', 'Jc8s', 'Jc9c', 'Jc9d', 'Jc9h', 'Jc9s', 'JcTc', 'JcTd', 'JcTh', 'JcTs', 'Jd2c', 'Jd2d', 'Jd2h', 'Jd2s', 'Jd3c', 'Jd3d',
  'Jd3h', 'Jd3s', 'Jd4c','Jd4d', 'Jd4h', 'Jd4s', 'Jd5c', 'Jd5d', 'Jd5h', 'Jd5s', 'Jd6c', 'Jd6d', 'Jd6h', 'Jd6s', 'Jd7c', 'Jd7d', 'Jd7h', 'Jd7s', 'Jd8c', 'Jd8d', 'Jd8h',
  'Jd8s', 'Jd9c', 'Jd9d', 'Jd9h', 'Jd9s', 'JdTc', 'JdTd', 'JdTh','JdTs', 'JdJc', 'Jh2c', 'Jh2d', 'Jh2h', 'Jh2s', 'Jh3c', 'Jh3d', 'Jh3h', 'Jh3s', 'Jh4c', 'Jh4d', 'Jh4h',
  'Jh4s', 'Jh5c', 'Jh5d', 'Jh5h', 'Jh5s', 'Jh6c', 'Jh6d', 'Jh6h', 'Jh6s', 'Jh7c', 'Jh7d', 'Jh7h', 'Jh7s','Jh8c', 'Jh8d', 'Jh8h', 'Jh8s', 'Jh9c', 'Jh9d', 'Jh9h', 'Jh9s',
  'JhTc', 'JhTd', 'JhTh', 'JhTs', 'JhJc', 'JhJd', 'Js2c', 'Js2d', 'Js2h', 'Js2s', 'Js3c', 'Js3d', 'Js3h', 'Js3s', 'Js4c', 'Js4d', 'Js4h', 'Js4s','Js5c', 'Js5d', 'Js5h',
  'Js5s', 'Js6c', 'Js6d', 'Js6h', 'Js6s', 'Js7c', 'Js7d', 'Js7h', 'Js7s', 'Js8c', 'Js8d', 'Js8h', 'Js8s', 'Js9c', 'Js9d', 'Js9h', 'Js9s', 'JsTc', 'JsTd', 'JsTh', 'JsTs',
  'JsJc', 'JsJd','JsJh', 'Qc2c', 'Qc2d', 'Qc2h', 'Qc2s', 'Qc3c', 'Qc3d', 'Qc3h', 'Qc3s', 'Qc4c', 'Qc4d', 'Qc4h', 'Qc4s', 'Qc5c', 'Qc5d', 'Qc5h', 'Qc5s', 'Qc6c', 'Qc6d',
  'Qc6h', 'Qc6s', 'Qc7c', 'Qc7d', 'Qc7h', 'Qc7s', 'Qc8c','Qc8d', 'Qc8h', 'Qc8s', 'Qc9c', 'Qc9d', 'Qc9h', 'Qc9s', 'QcTc', 'QcTd', 'QcTh', 'QcTs', 'QcJc', 'QcJd', 'QcJh',
  'QcJs', 'Qd2c', 'Qd2d', 'Qd2h', 'Qd2s', 'Qd3c', 'Qd3d', 'Qd3h', 'Qd3s', 'Qd4c', 'Qd4d', 'Qd4h','Qd4s', 'Qd5c', 'Qd5d', 'Qd5h', 'Qd5s', 'Qd6c', 'Qd6d', 'Qd6h', 'Qd6s',
  'Qd7c', 'Qd7d', 'Qd7h', 'Qd7s', 'Qd8c', 'Qd8d', 'Qd8h', 'Qd8s', 'Qd9c', 'Qd9d', 'Qd9h', 'Qd9s', 'QdTc', 'QdTd', 'QdTh', 'QdTs', 'QdJc','QdJd', 'QdJh', 'QdJs', 'QdQc',
  'Qh2c', 'Qh2d', 'Qh2h', 'Qh2s', 'Qh3c', 'Qh3d', 'Qh3h', 'Qh3s', 'Qh4c', 'Qh4d', 'Qh4h', 'Qh4s', 'Qh5c', 'Qh5d', 'Qh5h', 'Qh5s', 'Qh6c', 'Qh6d', 'Qh6h', 'Qh6s', 'Qh7c',
  'Qh7d','Qh7h', 'Qh7s', 'Qh8c', 'Qh8d', 'Qh8h', 'Qh8s', 'Qh9c', 'Qh9d', 'Qh9h', 'Qh9s', 'QhTc', 'QhTd', 'QhTh', 'QhTs', 'QhJc', 'QhJd', 'QhJh', 'QhJs', 'QhQc', 'QhQd',
  'Qs2c', 'Qs2d', 'Qs2h', 'Qs2s', 'Qs3c', 'Qs3d','Qs3h', 'Qs3s', 'Qs4c', 'Qs4d', 'Qs4h', 'Qs4s', 'Qs5c', 'Qs5d', 'Qs5h', 'Qs5s', 'Qs6c', 'Qs6d', 'Qs6h', 'Qs6s', 'Qs7c',
  'Qs7d', 'Qs7h', 'Qs7s', 'Qs8c', 'Qs8d', 'Qs8h', 'Qs8s', 'Qs9c', 'Qs9d', 'Qs9h', 'Qs9s','QsTc', 'QsTd', 'QsTh', 'QsTs', 'QsJc', 'QsJd', 'QsJh', 'QsJs', 'QsQc', 'QsQd',
  'QsQh', 'Kc2c', 'Kc2d', 'Kc2h', 'Kc2s', 'Kc3c', 'Kc3d', 'Kc3h', 'Kc3s', 'Kc4c', 'Kc4d', 'Kc4h', 'Kc4s', 'Kc5c', 'Kc5d', 'Kc5h','Kc5s', 'Kc6c', 'Kc6d', 'Kc6h', 'Kc6s',
  'Kc7c', 'Kc7d', 'Kc7h', 'Kc7s', 'Kc8c', 'Kc8d', 'Kc8h', 'Kc8s', 'Kc9c', 'Kc9d', 'Kc9h', 'Kc9s', 'KcTc', 'KcTd', 'KcTh', 'KcTs', 'KcJc', 'KcJd', 'KcJh', 'KcJs', 'KcQc',
  'KcQd', 'KcQh', 'KcQs', 'Kd2c', 'Kd2d', 'Kd2h', 'Kd2s', 'Kd3c', 'Kd3d', 'Kd3h', 'Kd3s', 'Kd4c', 'Kd4d', 'Kd4h', 'Kd4s', 'Kd5c', 'Kd5d', 'Kd5h', 'Kd5s', 'Kd6c', 'Kd6d',
  'Kd6h', 'Kd6s', 'Kd7c', 'Kd7d', 'Kd7h','Kd7s', 'Kd8c', 'Kd8d', 'Kd8h', 'Kd8s', 'Kd9c', 'Kd9d', 'Kd9h', 'Kd9s', 'KdTc', 'KdTd', 'KdTh', 'KdTs', 'KdJc', 'KdJd', 'KdJh',
  'KdJs', 'KdQc', 'KdQd', 'KdQh', 'KdQs', 'KdKc', 'Kh2c', 'Kh2d', 'Kh2h', 'Kh2s','Kh3c', 'Kh3d', 'Kh3h', 'Kh3s', 'Kh4c', 'Kh4d', 'Kh4h', 'Kh4s', 'Kh5c', 'Kh5d', 'Kh5h',
  'Kh5s', 'Kh6c', 'Kh6d', 'Kh6h', 'Kh6s', 'Kh7c', 'Kh7d', 'Kh7h', 'Kh7s', 'Kh8c', 'Kh8d', 'Kh8h', 'Kh8s', 'Kh9c', 'Kh9d','Kh9h', 'Kh9s', 'KhTc', 'KhTd', 'KhTh', 'KhTs',
  'KhJc', 'KhJd', 'KhJh', 'KhJs', 'KhQc', 'KhQd', 'KhQh', 'KhQs', 'KhKc', 'KhKd', 'Ks2c', 'Ks2d', 'Ks2h', 'Ks2s', 'Ks3c', 'Ks3d', 'Ks3h', 'Ks3s', 'Ks4c', 'Ks4d','Ks4h',
  'Ks4s', 'Ks5c', 'Ks5d', 'Ks5h', 'Ks5s', 'Ks6c', 'Ks6d', 'Ks6h', 'Ks6s', 'Ks7c', 'Ks7d', 'Ks7h', 'Ks7s', 'Ks8c', 'Ks8d', 'Ks8h', 'Ks8s', 'Ks9c', 'Ks9d', 'Ks9h', 'Ks9s',
  'KsTc', 'KsTd', 'KsTh', 'KsTs','KsJc', 'KsJd', 'KsJh', 'KsJs', 'KsQc', 'KsQd', 'KsQh', 'KsQs', 'KsKc', 'KsKd', 'KsKh', 'Ac2c', 'Ac2d', 'Ac2h', 'Ac2s', 'Ac3c', 'Ac3d',
  'Ac3h', 'Ac3s', 'Ac4c', 'Ac4d', 'Ac4h', 'Ac4s', 'Ac5c', 'Ac5d', 'Ac5h','Ac5s', 'Ac6c', 'Ac6d', 'Ac6h', 'Ac6s', 'Ac7c', 'Ac7d', 'Ac7h', 'Ac7s', 'Ac8c', 'Ac8d', 'Ac8h',
  'Ac8s', 'Ac9c', 'Ac9d', 'Ac9h', 'Ac9s', 'AcTc', 'AcTd', 'AcTh', 'AcTs', 'AcJc', 'AcJd', 'AcJh', 'AcJs', 'AcQc','AcQd', 'AcQh', 'AcQs', 'AcKc', 'AcKd', 'AcKh', 'AcKs',
  'Ad2c', 'Ad2d', 'Ad2h', 'Ad2s', 'Ad3c', 'Ad3d', 'Ad3h', 'Ad3s', 'Ad4c', 'Ad4d', 'Ad4h', 'Ad4s', 'Ad5c', 'Ad5d', 'Ad5h', 'Ad5s', 'Ad6c', 'Ad6d', 'Ad6h','Ad6s', 'Ad7c',
  'Ad7d', 'Ad7h', 'Ad7s', 'Ad8c', 'Ad8d', 'Ad8h', 'Ad8s', 'Ad9c', 'Ad9d', 'Ad9h', 'Ad9s', 'AdTc', 'AdTd', 'AdTh', 'AdTs', 'AdJc', 'AdJd', 'AdJh', 'AdJs', 'AdQc', 'AdQd',
  'AdQh', 'AdQs', 'AdKc','AdKd', 'AdKh', 'AdKs', 'AdAc', 'Ah2c', 'Ah2d', 'Ah2h', 'Ah2s', 'Ah3c', 'Ah3d', 'Ah3h', 'Ah3s', 'Ah4c', 'Ah4d', 'Ah4h', 'Ah4s', 'Ah5c', 'Ah5d',
  'Ah5h', 'Ah5s', 'Ah6c', 'Ah6d', 'Ah6h', 'Ah6s', 'Ah7c', 'Ah7d','Ah7h', 'Ah7s', 'Ah8c', 'Ah8d', 'Ah8h', 'Ah8s', 'Ah9c', 'Ah9d', 'Ah9h', 'Ah9s', 'AhTc', 'AhTd', 'AhTh',
  'AhTs', 'AhJc', 'AhJd', 'AhJh', 'AhJs', 'AhQc', 'AhQd', 'AhQh', 'AhQs', 'AhKc', 'AhKd', 'AhKh', 'AhKs','AhAc', 'AhAd', 'As2c', 'As2d', 'As2h', 'As2s', 'As3c', 'As3d',
  'As3h', 'As3s', 'As4c', 'As4d', 'As4h', 'As4s', 'As5c', 'As5d', 'As5h', 'As5s', 'As6c', 'As6d', 'As6h', 'As6s', 'As7c', 'As7d', 'As7h', 'As7s','As8c', 'As8d', 'As8h',
  'As8s', 'As9c', 'As9d', 'As9h', 'As9s', 'AsTc', 'AsTd', 'AsTh', 'AsTs', 'AsJc', 'AsJd', 'AsJh', 'AsJs', 'AsQc', 'AsQd', 'AsQh', 'AsQs', 'AsKc', 'AsKd', 'AsKh', 'AsKs',
  'AsAc', 'AsAd', 'AsAh'
]

export const CARDS = [
  '2c', '3c', '4c', '5c', '6c', '7c', '8c', '9c', 'Tc', 'Jc', 'Qc', 'Kc', 'Ac',
  '2d', '3d', '4d', '5d', '6d', '7d', '8d', '9d', 'Td', 'Jd', 'Qd', 'Kd', 'Ad',
  '2s', '3s', '4s', '5s', '6s', '7s', '8s', '9s', 'Ts', 'Js', 'Qs', 'Ks', 'As',
  '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', 'Th', 'Jh', 'Qh', 'Kh', 'Ah',
];

export const COLORS_SCHEME = {
  blue: {
    base: '#46ABF9',
    level_1: '#D2ECFF',
    level_2: '#BBE2FF',
    level_3: '#A4D8FF',
    level_4: '#8DCDFE',
    level_5: '#75C2FC',
    level_6: '#5EB6FB',
    level_7: '#52B1FA',
    level_8: '#46ABF9',
    level_9: '#2B4FDB',
    level_10: '#1A7ECA',
    level_11: '#0467B2',
    level_12: '#035899',
  },
  orange: {
    base: '#F59B53',
    level_1: '#FFE8C9',
    level_2: '#FFDCAE',
    level_3: '#FFD093',
    level_4: '#FDC383',
    level_5: '#FAB673',
    level_6: '#F8A863',
    level_7: '#F7A25B',
    level_8: '#F59B53',
    level_9: '#EB7F44',
    level_10: '#E26435',
    level_11: '#D84826',
    level_12: '#B9EE21',
  },
  yellow: {
    base: '#FFD254',
    level_1: '#FFF9C8',
    level_2: '#FFF6AD',
    level_3: '#FFF391',
    level_4: '#FFEB82',
    level_5: '#FFE373',
    level_6: '#FFDA63',
    level_7: '#FFD65C',
    level_8: '#FFD254',
    level_9: '#F2BF38',
    level_10: '#E6AC1C',
    level_11: '#D99900',
    level_12: '#BA8300',
  },
  green: {
    base: '#2AD699',
    level_1: '#C6FDEA',
    level_2: '#AAFCDF',
    level_3: '#8DFBD4',
    level_4: '#74F2C5',
    level_5: '#5CE9B7',
    level_6: '#43DFA8',
    level_7: '#0EB379',
    level_8: '#2AD699',
    level_9: '#1CC589',
    level_10: '#0EB379',
    level_11: '#00A269',
    level_12: '#008B5A',
  },
  red: {
    base: '#E23752',
    level_1: '#FFCADC',
    level_2: '#FFB0CA',
    level_3: '#FF95B8',
    level_4: '#F87E9F',
    level_5: '#F16685',
    level_6: '#E94F6C',
    level_7: '#E6435F',
    level_8: '#E23752',
    level_9: '#C82A43',
    level_10: '#AF1D33',
    level_11: '#951024',
    level_12: '#800E1F',
  },
  purple: {
    base: '#BD80E3',
    level_1: '#FFEAF0',
    level_2: '#FFDFE8',
    level_3: '#FFD4E0',
    level_4: '#EFBFE1',
    level_5: '#DEAAE2',
    level_6: '#CE95E2',
    level_7: '#C68BE3',
    level_8: '#BD80E3',
    level_9: '#A25FDA',
    level_10: '#883ED0',
    level_11: '#6D1DC7',
    level_12: '#5D19AB',
  }
}

export const MIN_EV_COLOR = "#E23752"
export const MAX_EV_COLOR = "#2AD699"

export const EV_COLOR_STRIP = {
  level_1: '#AF1D33',
  level_2: '#E23752',
  level_3: '#EB7F44',
  level_4: '#FFD254',
  level_5: '#AAFCDC',
  level_6: '#37DBA1',
  level_7: '#00A269',
}

export const COMPARE_EV_COLOR_STRIP = {
  level_1: '#C82A43',
  level_2: '#FFA500',
  level_3: '#FFFF00',
  level_4: '#90EE90',
  level_5: '#B3FF03'
}

export const POSITION_WITH_ORDER = [
  { position: 'sb', order: 6 },
  { position: 'bb', order: 7 },
  { position: 'b',  order: 0 },
  { position: 'b1', order: 1 },
  { position: 'b2', order: 2 },
  { position: 'b3', order: 3 },
  { position: 'b4', order: 4 },
  { position: 'b5', order: 5 }
]

export const RUNOUTS_EXPLORER_VIEWS = [
  { text: 'OOP Equity', value: 'eq_oop' },
  { text: 'IP Equity', value: 'eq_ip' },
  { text: 'OOP EV', value: 'ev_oop' },
  { text: 'IP EV', value: 'ev_ip' },
  { text: 'Strategy', value: 'strategy' },
];

export const RUNOUTS_EXPLORER_DEFAULT_VIEW = 'strategy';
export const RUNOUTS_EXPLORER_EV_DECIMAL = 2;
export const RUNOUTS_EXPLORER_STRATEGY_DECIMAL = 2;

export const VIEW_OPTIONS = [
  { label: 'Strategy', value: '' },
  { label: 'EV rescaled', value: 'ev_rescale'},
  { label: 'Equity', value: 'equity'},
  { label: 'EV/Equity', value: 'ev_per_eq' }
];

export const DUAL_RANGE_VIEW_OPTIONS = [
  { label: 'EV rescaled', value: 'ev_rescale'},
  { label: 'Equity', value: 'equity'},
  { label: 'EV/Equity', value: 'ev_per_eq' },
  { label: 'Strategy', value: '' },
];

export const CARDS_ORDERED_BY_RANK_AND_SUIT = [
  { card: 'As', order: 1 }, { card: 'Ah', order: 2 }, { card: 'Ad', order: 3 }, { card: 'Ac', order: 4 },
  { card: 'Ks', order: 5 }, { card: 'Kh', order: 6 }, { card: 'Kd', order: 7 }, { card: 'Kc', order: 8 },
  { card: 'Qs', order: 9 }, { card: 'Qh', order: 10 }, { card: 'Qd', order: 11 }, { card: 'Qc', order: 12 },
  { card: 'Js', order: 13 }, { card: 'Jh', order: 14 }, { card: 'Jd', order: 15 }, { card: 'Jc', order: 16 },
  { card: 'Ts', order: 17 }, { card: 'Th', order: 18 }, { card: 'Td', order: 19 }, { card: 'Tc', order: 20 },
  { card: '9s', order: 21 }, { card: '9h', order: 22 }, { card: '9d', order: 23 }, { card: '9c', order: 24 },
  { card: '8s', order: 25 }, { card: '8h', order: 26 }, { card: '8d', order: 27 }, { card: '8c', order: 28 },
  { card: '7s', order: 29 }, { card: '7h', order: 30 }, { card: '7d', order: 31 }, { card: '7c', order: 32 },
  { card: '6s', order: 33 }, { card: '6h', order: 34 }, { card: '6d', order: 35 }, { card: '6c', order: 36 },
  { card: '5s', order: 37 }, { card: '5h', order: 38 }, { card: '5d', order: 39 }, { card: '5c', order: 40 },
  { card: '4s', order: 41 }, { card: '4h', order: 42 }, { card: '4d', order: 43 }, { card: '4c', order: 44 },
  { card: '3s', order: 45 }, { card: '3h', order: 46 }, { card: '3d', order: 47 }, { card: '3c', order: 48 },
  { card: '2s', order: 49 }, { card: '2h', order: 50 }, { card: '2d', order: 51 }, { card: '2c', order: 52 },
]

export const CONVERTED_CARDS_ORDERED_BY_RANK_AND_SUIT = [
  { card: 'As', order: 1 }, { card: 'Ad', order: 2 }, { card: 'Ac', order: 3 }, { card: 'Ah', order: 4 },
  { card: 'Ks', order: 5 }, { card: 'Kd', order: 6 }, { card: 'Kc', order: 7 }, { card: 'Kh', order: 8 },
  { card: 'Qs', order: 9 }, { card: 'Qd', order: 10 }, { card: 'Qc', order: 11 }, { card: 'Qh', order: 12 },
  { card: 'Js', order: 13 }, { card: 'Jd', order: 14 }, { card: 'Jc', order: 15 }, { card: 'Jh', order: 16 },
  { card: 'Ts', order: 17 }, { card: 'Td', order: 18 }, { card: 'Tc', order: 19 }, { card: 'Th', order: 20 },
  { card: '9s', order: 21 }, { card: '9d', order: 22 }, { card: '9c', order: 23 }, { card: '9h', order: 24 },
  { card: '8s', order: 25 }, { card: '8d', order: 26 }, { card: '8c', order: 27 }, { card: '8h', order: 28 },
  { card: '7s', order: 29 }, { card: '7d', order: 30 }, { card: '7c', order: 31 }, { card: '7h', order: 32 },
  { card: '6s', order: 33 }, { card: '6d', order: 34 }, { card: '6c', order: 35 }, { card: '6h', order: 36 },
  { card: '5s', order: 37 }, { card: '5d', order: 38 }, { card: '5c', order: 39 }, { card: '5h', order: 40 },
  { card: '4s', order: 41 }, { card: '4d', order: 42 }, { card: '4c', order: 43 }, { card: '4h', order: 44 },
  { card: '3s', order: 45 }, { card: '3d', order: 46 }, { card: '3c', order: 47 }, { card: '3h', order: 48 },
  { card: '2s', order: 49 }, { card: '2d', order: 50 }, { card: '2c', order: 51 }, { card: '2h', order: 52 },
]

export const CARDS_ORDERED_BY_RANK = [
  { card: 'As', order: 1 }, { card: 'Ad', order: 1 }, { card: 'Ac', order: 1 }, { card: 'Ah', order: 1 },
  { card: 'Ks', order: 2 }, { card: 'Kd', order: 2 }, { card: 'Kc', order: 2 }, { card: 'Kh', order: 2 },
  { card: 'Qs', order: 3 }, { card: 'Qd', order: 3 }, { card: 'Qc', order: 3 }, { card: 'Qh', order: 3 },
  { card: 'Js', order: 4 }, { card: 'Jd', order: 4 }, { card: 'Jc', order: 4 }, { card: 'Jh', order: 4 },
  { card: 'Ts', order: 5 }, { card: 'Td', order: 5 }, { card: 'Tc', order: 5 }, { card: 'Th', order: 5 },
  { card: '9s', order: 6 }, { card: '9d', order: 6 }, { card: '9c', order: 6 }, { card: '9h', order: 6 },
  { card: '8s', order: 7 }, { card: '8d', order: 7 }, { card: '8c', order: 7 }, { card: '8h', order: 7 },
  { card: '7s', order: 8 }, { card: '7d', order: 8 }, { card: '7c', order: 8 }, { card: '7h', order: 8 },
  { card: '6s', order: 9 }, { card: '6d', order: 9 }, { card: '6c', order: 9 }, { card: '6h', order: 9 },
  { card: '5s', order: 10 }, { card: '5d', order: 10 }, { card: '5c', order: 10 }, { card: '5h', order: 10 },
  { card: '4s', order: 11 }, { card: '4d', order: 11 }, { card: '4c', order: 11 }, { card: '4h', order: 11 },
  { card: '3s', order: 12 }, { card: '3d', order: 12 }, { card: '3c', order: 12 }, { card: '3h', order: 12 },
  { card: '2s', order: 13 }, { card: '2d', order: 13 }, { card: '2c', order: 13 }, { card: '2h', order: 13 },
]

export const SUIT_PATERN_LIST = [
  "cdhs", "cdsh", "chds", "chsd", "csdh", "cshd", "dchs", "dcsh", "dhcs", "dhsc", "dsch", "dshc",
  "hcds", "hcsd","hdcs", "hdsc", "hscd", "hsdc", "scdh", "schd", "sdch", "sdhc", "shcd", "shdc"
]

export const CASH_GAME_PLAYER_POSITIONS = [
  'b', 'sb', 'bb', 'b1', 'b2', 'b3'
]

export const HU_GAME_PLAYER_POSITIONS = [ 'b', 'bb' ]

export const MTT_GAME_PLAYER_POSITIONS = [
  'b', 'sb', 'bb', 'b1', 'b2', 'b3', 'b4', 'b5'
]

export const SUITS_FOR_FILTER = {
  excluded: {first: "", second: ""},
  included: {first: "", second: ""}
}

export const INITIAL_POSITION_NODES = {
  'b': 'B_strategy',
  'sb': 'SB_strategy',
  'bb': 'BB_strategy',
  'b1': 'B+1_strategy',
  'b2': 'B+2_strategy',
  'b3': 'B+3_strategy',
  'b4': 'B+4_strategy',
  'b5': 'B+5_strategy',
}

export const PREFLOP_POSITIONS_ORDER = {
  cash: ['b3', 'b2', 'b1', 'b', 'sb', 'bb'],
  mtt: ['b5', 'b4', 'b3', 'b2', 'b1', 'b', 'sb', 'bb'],
  hu: ['b', 'bb']
}

export const SKIPPED_ACTIONS = ['fold', 'allin'];

export const POSITION_NODE_PARAMS = {
  'b': 'B',
  'sb': 'SB',
  'bb': 'BB',
  'b1': 'B+1',
  'b2': 'B+2',
  'b3': 'B+3',
  'b4': 'B+4',
  'b5': 'B+5',
}

export const CASH_GAME_INITIAL_TOTAL_POT = 1.5;
export const MTT_GAME_INITIAL_TOTAL_POT = 2.5;
export const BIG_BLIND_BET = 1;

export const DEFAULT_BLINDS = {
  small_blind: 50,
  big_blind: 100
}

export const SPECIAL_SIMS = [
  '50-limp-BBvSB',
  '40-srp-BBvB+4',
  '20-srp-BBvSB',
  '20-limp-BBvSB',
  '20-srp-BBvB+4',
  '20-srp-SBvB+4',
  '20-srp-BvB+4',
  '15-srp-BBvB',
  '15-srp-BBvB+2',
  '15-srp-BvB+2',
  '15-limp-BBvB',
  '15-limp-BBvB+2'
]

export const COLOR_BY_ROLE = {
  'odin_user': {
    'primaryColor': '#3D64F8',
    'darkerPrimaryColor': '#001F95',
    'positionCircleBorderColor': '#4FAEFF',
    'deckDarkerColor': '#001F95',
    'deckColor': '#2147D8',
    'positionCircleShadowColor': '#4FAEFF',
    'selectedBorderColor': '#3D64F8',
    'selectedBackgroundColor': '#3D64F8',
    'textColor': '#4FAEFF',
    'headerBackgroundColor': '#1E1E31',
    'pageBackgroundColor': '#2A2A44',
    'pageBackgroundDeepColor': '#1E1E31',
    'pageBackgroundLightColor': '#31314D',
    'buttonBackgroundColor': '#3E3E5D',
    'lineDividerColor': '#4C4C6C',
    'matrixSquareBackgroundColor': '#616181',
    'positionCircleBorderColorSelected': '#3D93F8',
    'buttonSubscribeDarkColor': '#47475F',
    'buttonSubscribeLightColor': '#47475F',
    'buttonSubscribeShadow': 'rgba(0, 0, 0, 0.282353)',
    'backgroundSubscriptionOptionDark': '#3D93F8',
    'backgroundSubscriptionOptionLight': '#3D64F8',
    'specialPriceColor': '#ffffff',
    'buttonSpecialSubscribeDarkColor': '#3D93F8',
    'buttonSpecialSubscribeLightColor': '#3D64F8',
    'titleSubscription': '#ffffff',
  },
  'pokercode_user': {
    'primaryColor': '#3D64F8',
    'darkerPrimaryColor': '#D54935',
    'positionCircleBorderColor': '#FE5000',
    'deckDarkerColor': '#001F95',
    'deckColor': '#2147D8',
    'positionCircleShadowColor': '#e6cd97',
    'selectedBorderColor': '#E16E4B',
    'selectedBackgroundColor': '#E16E4B',
    'textColor': '#4FAEFF',
    'headerBackgroundColor': '#1E1E31',
    'pageBackgroundColor': '#2A2A44',
    'pageBackgroundDeepColor': '#1E1E31',
    'pageBackgroundLightColor': '#31314D',
    'buttonBackgroundColor': '#3E3E5D',
    'lineDividerColor': '#4C4C6C',
    'matrixSquareBackgroundColor': '#616181',
    'positionCircleBorderColorSelected': '#F2653F',
    'buttonSubscribeDarkColor': '#E16E4B',
    'buttonSubscribeLightColor': '#E16E4B',
    'buttonSubscribeShadow': 'rgb(61 100 248 / 20%)',
    'backgroundSubscriptionOptionDark': '#ffffff',
    'backgroundSubscriptionOptionLight': '#ffffff',
    'specialPriceColor': '#000000',
    'buttonSpecialSubscribeDarkColor': '#E16E4B',
    'buttonSpecialSubscribeLightColor': '#E16E4B',
    'titleSubscription': '#E16E4B',

  },
  'black_design_user': {
    'primaryColor': '#E16E4B',
    'darkerPrimaryColor': '#D54935',
    'positionCircleBorderColor': '#FE5000',
    'deckDarkerColor': '#D54935',
    'deckColor': '#D47358',
    'positionCircleShadowColor': '#E6CD97',
    'selectedBorderColor': '#E16E4B',
    'selectedBackgroundColor': '#E16E4B',
    'textColor': '#4FAEFF',
    'headerBackgroundColor': '#1F1F1F',
    'pageBackgroundColor': '#1F1F1F',
    'pageBackgroundDeepColor': '#101010',
    'pageBackgroundLightColor': '#252525',
    'buttonBackgroundColor': '#404040',
    'lineDividerColor': '#404040',
    'matrixSquareBackgroundColor': '#505050',
    'positionCircleBorderColorSelected': '#3D93F8',
    'buttonSubscribeDarkColor': '#47475F',
    'buttonSubscribeLightColor': '#47475F',
    'buttonSubscribeShadow': 'rgba(0, 0, 0, 0.282353)',
    'backgroundSubscriptionOptionDark': '#3D93F8',
    'backgroundSubscriptionOptionLight': '#3D64F8',
    'specialPriceColor': '#ffffff',
    'buttonSpecialSubscribeDarkColor': '#3D93F8',
    'buttonSpecialSubscribeLightColor': '#3D64F8',
    'titleSubscription': '#ffffff',
  },
  "admin": {
    'primaryColor': '#3D64F8',
    'darkerPrimaryColor': '#001F95',
    'positionCircleBorderColor': '#4FAEFF',
    'deckDarkerColor': '#001F95',
    'deckColor': '#2147D8',
    'positionCircleShadowColor': '#4FAEFF',
    'selectedBorderColor': '#3D64F8',
    'selectedBackgroundColor': '#3D64F8',
    'textColor': '#4FAEFF',
    'headerBackgroundColor': '#1E1E31',
    'pageBackgroundColor': '#2A2A44',
    'pageBackgroundDeepColor': '#1E1E31',
    'pageBackgroundLightColor': '#31314D',
    'buttonBackgroundColor': '#3E3E5D',
    'lineDividerColor': '#4C4C6C',
    'matrixSquareBackgroundColor': '#616181',
    'positionCircleBorderColorSelected': '#3D93F8',
    'buttonSubscribeDarkColor': '#47475F',
    'buttonSubscribeLightColor': '#47475F',
    'buttonSubscribeShadow': 'rgba(0, 0, 0, 0.282353)',
    'backgroundSubscriptionOptionDark': '#3D93F8',
    'backgroundSubscriptionOptionLight': '#3D64F8',
    'specialPriceColor': '#ffffff',
    'buttonSpecialSubscribeDarkColor': '#3D93F8',
    'buttonSpecialSubscribeLightColor': '#3D64F8',
    'titleSubscription': '#ffffff',
  }
}

export const IP_OOP_POSITIONS_ORDER = ['sb', 'bb', 'b5', 'b4', 'b3', 'b2', 'b1', 'b'];

export const SUITED_CARD_NUMBER = 13;

export const DOWNCASE_POSITIONS_MAPPING = {
  'bb': 'bb',
  'sb': 'sb',
  'b': 'btn',
  'b1': 'co',
  'b2': 'hj',
  'b3': 'lj',
  'b4': 'utg+1',
  'b5': 'utg'
}

export const UPPERCASE_POSITIONS_MAPPING = {
  'BB': 'BB',
  'SB': 'SB',
  'B': 'BTN',
  'B+1': 'CO',
  'B+2': 'HJ',
  'B+3': 'LJ',
  'B+4': 'UTG+1',
  'B+5': 'UTG'
}

export const EV_EQ_PRECISION = 2;
export const RANGES_PRECISION = 2
export const INITIAL_TREE_INFO = {
  oop: { flop: {}, turn: {}, river: {}},
  ip: { flop: {}, turn: {}, river: {}},
  fetching: false
}
export const DISPLAY_IN_BB = true
export const NUMBER_OF_COLOR_SCHEME_LEVELS = 12

export const CLEAR_ICON_TEXT_LIST = ['', '<p></p>']
export const EMAILS_ABLE_TO_VIEW_BLACK_DESIGN = [
  'fedor@pokercode.com',
  'holzfedor@googlemail.com',
  'kentest@gmail.com',
];
export const DO_NOT_APPLY_DELAY_TIME_EMAILS = [
  'ryananthonyotto@gmail.com',
  'roryyoungpoker@gmail.com',
  'ken@gmail.com',
  'heimdallrpoker@hotmail.com',
  'kahleburns@gmail.com',
  'techniczne83@gmail.com'
]

export const FREE_TRIAL_USER_EMAILS = [
  'odinfreetrialuser@yopmail.com',
];

export const MAXIMUM_NUMBER_OF_PREFLOP_ACTIONS = 4;
export const PREFLOP_ANTES = {
  cash: 0,
  mtt: 1,
}

export const PREFLOP_ACTION_DECIMAL = 2
export const PREFLOP_NUM_COMBOS_PRECISION = 2
export const PREFLOP_PERCENT_COMBOS_PRECISION = 2
export const PREFLOP_POT_STACK_PRECISION = 2
export const DECISION_PERCENT_COMBOS_PRECISION = 1
export const DECISION_NUM_COMBOS_PRECISION = 1
export const COMPARE_EV_PRECISION = 2

export const PAIRED_BOARDWAY_1 = ['JJT', 'QQT', 'KKT', 'KKQ', 'AAT', 'AAJ', 'AAQ', 'AAK']
export const PAIRED_BOARDWAY_2 = ['JTT', 'QTT', 'KTT', 'ATT', 'AJJ', 'KQQ', 'AQQ', 'AKK']
export const BROADWAY_CARD = ["10", "J", "Q", "K", "A"]

export const INITIAL_NOTE_POPUP_DATA = {
  streetView: {
    general: {
      note: '',
      default: []
    },
    flop: {
      note: '',
      oop: [],
      ip: []
    },
    turn: {
      note: '',
      default: [],
      oop: [],
      ip: []
    },
    river: {
      note: '',
      default: [],
      oop: [],
      ip: []
    }
  },
  notepadView: {
    note: ''
  }
}

export const SELECT_CARD_POPUP = {
  left_position_of_left_pop_up: 50,
  left_position_of_right_pop_up: 270,
  height: 370
}

export const NOTES_POPUP_ROUND = ['general', 'flop', 'turn', 'river']

export const INIT_PREFLOP_FIELDS = {
  site: DEFAULT_SITE,
  stake: DEFAULT_STAKE_OPTIONS[DEFAULT_PLAYERS][DEFAULT_SITE][0].value,
  open_size: DEFAULT_OPEN_SIZE,
}

export const INIT_STRATEGY_SELECTION = {
  street: DEFAULT_STREET_OPTIONS,
  game_type: DEFAULT_GAME_TYPE,
  players: DEFAULT_PLAYERS,
  stack: DEFAULT_STACK_SIZE[DEFAULT_GAME_TYPE],
  positions: DEFAULT_POSITIONS[DEFAULT_GAME_TYPE],
  sim_type: TYPE_OPTIONS,
  site: DEFAULT_SITE,
  stake: DEFAULT_STAKE_OPTIONS[DEFAULT_PLAYERS][DEFAULT_SITE][0].value,
  open_size: DEFAULT_OPEN_SIZE,
  flops: DEFAULT_FLOP_CARDS,
}

export const INIT_PTB_STRATEGY_SELECTION = {
  ...INIT_STRATEGY_SELECTION,
  hero_seat: ['ip'],
  flop_type: 'random',
  selectTypes: ['srp', '3bet', '4bet'],
  suit: [],
  paired: [],
  card: [],
  connectedness: [],
}

export const HAND_CATEGORY_MAP = {
  madeHand: {
    '0':	'nothing',
    '1':	'king_high',
    '2':	'ace_high',
    '3':	'low_pair',
    '4':	'third_pair',
    '5':	'second_pair',
    '6':	'underpair',
    '7':	'top_pair',
    '8':	'top_pair',
    '9':	'overpair',
    '10':	'two_pair',
    '11':	'trips',
    '12':	'set',
    '13':	'straight',
    '14':	'flush',
    '15':	'fullhouse',
    '16':	'fullhouse',
    '17':	'quads',
    '18':	'straight_flush',
  },
  drawHand: {
    '-1': 'not_exists',
    '0':	'no_draw',
    '1':	'four_out_straight_draw',
    '2':	'eight_out_straight_draw',
    '3':	'flush_draw',
    '4':	'combo_draw',
  }
}

export const INITIAL_HAND_CATEGORY_DATA = {
  nothing: 0,
  king_high: 0,
  ace_high: 0,
  low_pair: 0,
  third_pair: 0,
  second_pair: 0,
  underpair: 0,
  top_pair: 0,
  overpair: 0,
  two_pair: 0,
  trips: 0,
  set: 0,
  straight: 0,
  flush: 0,
  fullhouse: 0,
  quads: 0,
  straight_flush: 0,
  no_draw: 0,
  four_out_straight_draw: 0,
  eight_out_straight_draw: 0,
  flush_draw: 0,
  combo_draw: 0
}

export const INITIAL_HAND_CATEGORY_MATRIX = {
  board: '',
  made_hands: '',
  draw_hands: ''
}

export const WEIGHTED_STRATEGY_DEFAULT_STATUS = true

export const HUNL_CASH_GAME_STRATEGY = {
  street: "postflop",
  game_type: "cash",
  players: "hu",
  stack: "100",
  positions: ["bb", "b"],
  sim_type: "3bet",
  site: "",
  stake: "",
  open_size: "",
  flops: ["Js", "8d", "7d"],
  specs: "hu"
}

export const SIX_MAX_CASH_GAME_STRATEGY = {
  flops: ["Ad", "Kd", "5s"],
  game_type: "cash",
  open_size: "",
  players: "6max",
  positions: ["sb", "b"],
  sim_type: "3bet",
  site: "",
  specs: "6max",
  stack: "100",
  stake: "",
  street: "postflop"
}

export const MTT_GAME_STRATEGY = {
  flops: ["6s", "4d", "3s"],
  game_type: "mtt",
  open_size: "",
  players: "6max",
  positions: ["b", "bb"],
  sim_type: "srp",
  site: "",
  specs: "",
  stack: "25",
  stake: "",
  street: "postflop"
}

export const DELETE_SIM_TYPE = ['select-item','all']

export const PADS_ACCESS_EMAILS_LIST = [
  'jackharris1@hotmail.co.uk',
  'itwasaghost@hotmail.com',
  'stozstoz@hotmail.com',
  'jcmb1000@gmail.com',
  'tomrhughes93@gmail.com',
  'ebaycappo@gmail.com',
  'yater22@gmail.com',
  'raphael.dutreuil@yahoo.fr',
  'lucashorbylon@gmail.com',
  'oscolaik@gmail.com',
  'joeyweissman88@gmail.com',
  'cargrc@gmail.com',
  'jamesaselliott@gmail.com',
  'gbachiyski@gmail.com',
  'poker330330@gmail.com',
  'josham1995@gmail.com',
  'patsharpe1155@gmail.com',
  'thomastano@hotmail.com',
  'anhbuicp@gmail.com'
]

export const URL_TO_AFFILIATE_CODE_MAPPING = {
  '/': 'prometheus',
  '/gg': 'ggplatinum',
  '/pokercode': 'pokercode',
  '/ginge': 'ginge',
  '/elliot': 'elliot',
  '/bts': 'bts',
  '/streamteam': 'streamteam',
  '/cavalitopoker': 'cavalitopoker',
  '/pc': 'pc',
  '/kmart': 'kmart',
  '/pads': 'pads',
  '/pav': 'pav',
  '/jnandez': 'jnandez',
  '/reglife': 'reglife',
  '/obex': 'obex',
  '/bbz': 'bbz',
  '/sixpoker666': 'sixpoker666',
  '/pokerdetox': 'pokerdetox',
  '/lex': 'lex',
  '/jeff': 'jeff',
  '/apestyles': 'apestyles',
  '/jargo': 'jargo',
  '/rodrigo': 'rodrigo',
  '/gtoexploit': 'gtoexploit',
  '/luckyfish89': 'luckyfish89',
  '/spraggy': 'spraggy',
  '/wolf': 'wolf',
  '/easywithaces': 'easywithaces',
  '/james': 'james',
  '/robinpoker': 'robinpoker',
  '/conor': 'conor',
  '/solcity': 'solcity'
}

export const TOTAL_FLOP_WEIGHT = 22100

export const FLOP_WEIGHTS = {'2s2d2c': 4,'3s3d3c': 4,'4s4d4c': 4,'5s5d5c': 4,'6s6d6c': 4,'7s7d7c': 4,'8s8d8c': 4,'9s9d9c': 4,'TsTdTc': 4,'JsJdJc': 4,'QsQdQc': 4,'KsKdKc': 4,'AsAdAc': 4,
  '3s2s2d': 12,'3s2d2c': 12,'4s2s2d': 12,'4s2d2c': 12,'5s2s2d': 12,'5s2d2c': 12,'6s2s2d': 12,'6s2d2c': 12,'7s2s2d': 12,'7s2d2c': 12,'8s2s2d': 12,'8s2d2c': 12,'9s2s2d': 12,'9s2d2c': 12,'Ts2s2d': 12,'Ts2d2c': 12,'Js2s2d': 12,'Js2d2c': 12,
  'Qs2s2d': 12,'Qs2d2c': 12,'Ks2s2d': 12,'Ks2d2c': 12,'As2s2d': 12,'As2d2c': 12,'3s3d2s': 12,'3s3d2c': 12,'4s3s3d': 12,'4s3d3c': 12,'5s3s3d': 12,'5s3d3c': 12,'6s3s3d': 12,'6s3d3c': 12,'7s3s3d': 12,'7s3d3c': 12,'8s3s3d': 12,'8s3d3c': 12,'9s3s3d': 12,'9s3d3c': 12,
  'Ts3s3d': 12,'Ts3d3c': 12,'Js3s3d': 12,'Js3d3c': 12,'Qs3s3d': 12,'Qs3d3c': 12,'Ks3s3d': 12,'Ks3d3c': 12,'As3s3d': 12,'As3d3c': 12,'4s4d2s': 12,'4s4d2c': 12,'4s4d3s': 12,'4s4d3c': 12,'5s4s4d': 12,'5s4d4c': 12,'6s4s4d': 12,'6s4d4c': 12,'7s4s4d': 12,'7s4d4c': 12,'8s4s4d': 12,'8s4d4c': 12,'9s4s4d': 12,'9s4d4c': 12,
  'Ts4s4d': 12,'Ts4d4c': 12,'Js4s4d': 12,'Js4d4c': 12,'Qs4s4d': 12,'Qs4d4c': 12,'Ks4s4d': 12,'Ks4d4c': 12,'As4s4d': 12,'As4d4c': 12,'5s5d2s': 12,'5s5d2c': 12,'5s5d3s': 12,'5s5d3c': 12,'5s5d4s': 12,'5s5d4c': 12,'6s5s5d': 12,'6s5d5c': 12,'7s5s5d': 12,'7s5d5c': 12,'8s5s5d': 12,'8s5d5c': 12,'9s5s5d': 12,'9s5d5c': 12,'Ts5s5d': 12,'Ts5d5c': 12,
  'Js5s5d': 12,'Js5d5c': 12,'Qs5s5d': 12,'Qs5d5c': 12,'Ks5s5d': 12,'Ks5d5c': 12,'As5s5d': 12,'As5d5c': 12,'6s6d2s': 12,'6s6d2c': 12,'6s6d3s': 12,'6s6d3c': 12,'6s6d4s': 12,'6s6d4c': 12,'6s6d5s': 12,'6s6d5c': 12,'7s6s6d': 12,'7s6d6c': 12,'8s6s6d': 12,'8s6d6c': 12,'9s6s6d': 12,'9s6d6c': 12,
  'Ts6s6d': 12,'Ts6d6c': 12,'Js6s6d': 12,'Js6d6c': 12,'Qs6s6d': 12,'Qs6d6c': 12,'Ks6s6d': 12,'Ks6d6c': 12,'As6s6d': 12,'As6d6c': 12,'7s7d2s': 12,'7s7d2c': 12,'7s7d3s': 12,'7s7d3c': 12,'7s7d4s': 12,'7s7d4c': 12,'7s7d5s': 12,'7s7d5c': 12,'7s7d6s': 12,'7s7d6c': 12,'8s7s7d': 12,'8s7d7c': 12,'9s7s7d': 12,'9s7d7c': 12,'Ts7s7d': 12,'Ts7d7c': 12,'Js7s7d': 12,'Js7d7c': 12,'Qs7s7d': 12,'Qs7d7c': 12,'Ks7s7d': 12,'Ks7d7c': 12,'As7s7d': 12,'As7d7c': 12,'8s8d2s': 12,'8s8d2c': 12,'8s8d3s': 12,'8s8d3c': 12,'8s8d4s': 12,'8s8d4c': 12,'8s8d5s': 12,'8s8d5c': 12,
  '8s8d6s': 12,'8s8d6c': 12,'8s8d7s': 12,'8s8d7c': 12,'9s8s8d': 12,'9s8d8c': 12,'Ts8s8d': 12,'Ts8d8c': 12,'Js8s8d': 12,'Js8d8c': 12,'Qs8s8d': 12,'Qs8d8c': 12,'Ks8s8d': 12,'Ks8d8c': 12,'As8s8d': 12,'As8d8c': 12,'9s9d2s': 12,'9s9d2c': 12,'9s9d3s': 12,'9s9d3c': 12,'9s9d4s': 12,'9s9d4c': 12,
  '9s9d5s': 12,'9s9d5c': 12,'9s9d6s': 12,'9s9d6c': 12,'9s9d7s': 12,'9s9d7c': 12,'9s9d8s': 12,'9s9d8c': 12,'Ts9s9d': 12,'Ts9d9c': 12,'Js9s9d': 12,'Js9d9c': 12,'Qs9s9d': 12,'Qs9d9c': 12,'Ks9s9d': 12,'Ks9d9c': 12,'As9s9d': 12,'As9d9c': 12,'TsTd2s': 12,'TsTd2c': 12,'TsTd3s': 12,'TsTd3c': 12,
  'TsTd4s': 12,'TsTd4c': 12,'TsTd5s': 12,'TsTd5c': 12,'TsTd6s': 12,'TsTd6c': 12,'TsTd7s': 12,'TsTd7c': 12,'TsTd8s': 12,'TsTd8c': 12,'TsTd9s': 12,'TsTd9c': 12,'JsTsTd': 12,'JcTsTd': 12,'QsTsTd': 12,'QcTsTd': 12,'KsTsTd': 12,'KcTsTd': 12,'AsTsTd': 12,'AcTsTd': 12,'JsJd2s': 12,
  'JsJd2c': 12,'JsJd3s': 12,'JsJd3c': 12,'JsJd4s': 12,'JsJd4c': 12,'JsJd5s': 12,'JsJd5c': 12,'JsJd6s': 12,'JsJd6c': 12,'JsJd7s': 12,'JsJd7c': 12,'JsJd8s': 12,'JsJd8c': 12,'JsJd9s': 12,'JsJd9c': 12,'JsJdTs': 12,'JdJcTs': 12,'QsJsJd': 12,'QsJdJc': 12,
  'KsJsJd': 12,'KsJdJc': 12,'AsJsJd': 12,'AcJsJd': 12,'QsQd2s': 12,'QsQd2c': 12,'QsQd3s': 12,'QsQd3c': 12,'QsQd4s': 12,'QsQd4c': 12,'QsQd5s': 12,'QsQd5c': 12,'QsQd6s': 12,'QsQd6c': 12,'QsQd7s': 12,'QsQd7c': 12,
  'QsQd8s': 12,'QsQd8c': 12,'QsQd9s': 12,'QsQd9c': 12,'QsQdTs': 12,'QdQcTs': 12,'QsQdJs': 12,'QsQdJc': 12,'KsQsQd': 12,'KcQsQd': 12,'AsQsQd': 12,'AcQsQd': 12,'KsKd2s': 12,'KsKd2c': 12,'KsKd3s': 12,'KsKd3c': 12,'KsKd4s': 12,'KsKd4c': 12,'KsKd5s': 12,
  'KsKd5c': 12,'KsKd6s': 12,'KsKd6c': 12,'KsKd7s': 12,'KsKd7c': 12,'KsKd8s': 12,'KsKd8c': 12,'KsKd9s': 12,'KsKd9c': 12,'KsKdTs': 12,'KdKcTs': 12,'KsKdJs': 12,'KsKdJc': 12,'KsKdQs': 12,'KdKcQs': 12,'AsKsKd': 12,'AcKsKd': 12,
  'AsAd2s': 12,'AsAd2c': 12,'AsAd3s': 12,'AsAd3c': 12,'AsAd4s': 12,'AsAd4c': 12,'AsAd5s': 12,'AsAd5c': 12,'AsAd6s': 12,'AsAd6c': 12,'AsAd7s': 12,'AsAd7c': 12,'AsAd8s': 12,'AsAd8c': 12,'AsAd9s': 12,'AsAd9c': 12,'AsAdTs': 12,'AdAcTs': 12,'AsAdJs': 12,'AdAcJs': 12,'AsAdQs': 12,
  'AdAcQs': 12,'AsAdKs': 12,'AdAcKs': 12,'4s3d2c': 24,'4s3d2d': 12,'4s3d2s': 12,'4s3s2d': 12,'4s3s2s': 4,'5s3d2c': 24,'5s3d2d': 12,'5s3d2s': 12,'5s3s2d': 12,'5s3s2s': 4,'6s3d2c': 24,'6s3d2d': 12,'6s3d2s': 12,'6s3s2d': 12,'6s3s2s': 4,'7s3d2c': 24,'7s3d2d': 12,
  '7s3d2s': 12,'7s3s2d': 12,'7s3s2s': 4,'8s3d2c': 24,'8s3d2d': 12,'8s3d2s': 12,'8s3s2d': 12,'8s3s2s': 4,'9s3d2c': 24,'9s3d2d': 12,'9s3d2s': 12,'9s3s2d': 12,'9s3s2s': 4,'Ts3d2c': 24,'Ts3d2d': 12,'Ts3d2s': 12,'Ts3s2d': 12,'Ts3s2s': 4,'Js3d2c': 24,'Js3d2d': 12,'Js3d2s': 12,'Js3s2d': 12,'Js3s2s': 4,'Qs3d2c': 24,'Qs3d2d': 12,'Qs3d2s': 12,'Qs3s2d': 12,'Qs3s2s': 4,'Ks3d2c': 24,'Ks3d2d': 12,'Ks3d2s': 12,'Ks3s2d': 12,'Ks3s2s': 4,'As3d2c': 24,'As3d2d': 12,'As3d2s': 12,'As3s2d': 12,'As3s2s': 4,'5s4d2c': 24,'5s4d2d': 12,'5s4d2s': 12,'5s4s2d': 12,'5s4s2s': 4,'6s4d2c': 24,'6s4d2d': 12,
  '6s4d2s': 12,'6s4s2d': 12,'6s4s2s': 4,'7s4d2c': 24,'7s4d2d': 12,'7s4d2s': 12,'7s4s2d': 12,'7s4s2s': 4,'8s4d2c': 24,'8s4d2d': 12,'8s4d2s': 12,'8s4s2d': 12,'8s4s2s': 4,'9s4d2c': 24,'9s4d2d': 12,'9s4d2s': 12,'9s4s2d': 12,'9s4s2s': 4,'Ts4d2c': 24,'Ts4d2d': 12,'Ts4d2s': 12,'Ts4s2d': 12,'Ts4s2s': 4,'Js4d2c': 24,'Js4d2d': 12,'Js4d2s': 12,'Js4s2d': 12,'Js4s2s': 4,'Qs4d2c': 24,'Qs4d2d': 12,'Qs4d2s': 12,'Qs4s2d': 12,'Qs4s2s': 4,'Ks4d2c': 24,'Ks4d2d': 12,'Ks4d2s': 12,'Ks4s2d': 12,'Ks4s2s': 4,'As4d2c': 24,'As4d2d': 12,'As4d2s': 12,'As4s2d': 12,'As4s2s': 4,'6s5d2c': 24,
  '6s5d2d': 12,'6s5d2s': 12,'6s5s2d': 12,'6s5s2s': 4,'7s5d2c': 24,'7s5d2d': 12,'7s5d2s': 12,'7s5s2d': 12,'7s5s2s': 4,'8s5d2c': 24,'8s5d2d': 12,'8s5d2s': 12,'8s5s2d': 12,'8s5s2s': 4,'9s5d2c': 24,'9s5d2d': 12,'9s5d2s': 12,'9s5s2d': 12,'9s5s2s': 4,'Ts5d2c': 24,'Ts5d2d': 12,'Ts5d2s': 12,'Ts5s2d': 12,'Ts5s2s': 4,'Js5d2c': 24,'Js5d2d': 12,'Js5d2s': 12,
  'Js5s2d': 12,'Js5s2s': 4,'Qs5d2c': 24,'Qs5d2d': 12,'Qs5d2s': 12,'Qs5s2d': 12,'Qs5s2s': 4,'Ks5d2c': 24,'Ks5d2d': 12,'Ks5d2s': 12,'Ks5s2d': 12,'Ks5s2s': 4,'As5d2c': 24,'As5d2d': 12,'As5d2s': 12,'As5s2d': 12,'As5s2s': 4,'7s6d2c': 24,'7s6d2d': 12,'7s6d2s': 12,'7s6s2d': 12,'7s6s2s': 4,'8s6d2c': 24,'8s6d2d': 12,'8s6d2s': 12,'8s6s2d': 12,'8s6s2s': 4,'9s6d2c': 24,'9s6d2d': 12,'9s6d2s': 12,'9s6s2d': 12,'9s6s2s': 4,
  'Ts6d2c': 24,'Ts6d2d': 12,'Ts6d2s': 12,'Ts6s2d': 12,'Ts6s2s': 4,'Js6d2c': 24,'Js6d2d': 12,'Js6d2s': 12,'Js6s2d': 12,'Js6s2s': 4,'Qs6d2c': 24,'Qs6d2d': 12,'Qs6d2s': 12,'Qs6s2d': 12,'Qs6s2s': 4,'Ks6d2c': 24,'Ks6d2d': 12,'Ks6d2s': 12,'Ks6s2d': 12,'Ks6s2s': 4,'As6d2c': 24,'As6d2d': 12,'As6d2s': 12,'As6s2d': 12,'As6s2s': 4,'8s7d2c': 24,'8s7d2d': 12,'8s7d2s': 12,'8s7s2d': 12,
  '8s7s2s': 4,'9s7d2c': 24,'9s7d2d': 12,'9s7d2s': 12,'9s7s2d': 12,'9s7s2s': 4,'Ts7d2c': 24,'Ts7d2d': 12,'Ts7d2s': 12,'Ts7s2d': 12,'Ts7s2s': 4,'Js7d2c': 24,'Js7d2d': 12,'Js7d2s': 12,'Js7s2d': 12,'Js7s2s': 4,'Qs7d2c': 24,'Qs7d2d': 12,'Qs7d2s': 12,'Qs7s2d': 12,'Qs7s2s': 4,'Ks7d2c': 24,
  'Ks7d2d': 12,'Ks7d2s': 12,'Ks7s2d': 12,'Ks7s2s': 4,'As7d2c': 24,'As7d2d': 12,'As7d2s': 12,'As7s2d': 12,'As7s2s': 4,'9s8d2c': 24,'9s8d2d': 12,'9s8d2s': 12,'9s8s2d': 12,'9s8s2s': 4,'Ts8d2c': 24,'Ts8d2d': 12,'Ts8d2s': 12,'Ts8s2d': 12,'Ts8s2s': 4,'Js8d2c': 24,'Js8d2d': 12,'Js8d2s': 12,'Js8s2d': 12,'Js8s2s': 4,'Qs8d2c': 24,'Qs8d2d': 12,'Qs8d2s': 12,'Qs8s2d': 12,
  'Qs8s2s': 4,'Ks8d2c': 24,'Ks8d2d': 12,'Ks8d2s': 12,'Ks8s2d': 12,'Ks8s2s': 4,'As8d2c': 24,'As8d2d': 12,'As8d2s': 12,'As8s2d': 12,'As8s2s': 4,'Ts9d2c': 24,'Ts9d2d': 12,'Ts9d2s': 12,'Ts9s2d': 12,'Ts9s2s': 4,'Js9d2c': 24,'Js9d2d': 12,'Js9d2s': 12,'Js9s2d': 12,'Js9s2s': 4,'Qs9d2c': 24,'Qs9d2d': 12,'Qs9d2s': 12,
  'Qs9s2d': 12,'Qs9s2s': 4,'Ks9d2c': 24,'Ks9d2d': 12,'Ks9d2s': 12,'Ks9s2d': 12,'Ks9s2s': 4,'As9d2c': 24,'As9d2d': 12,'As9d2s': 12,'As9s2d': 12,'As9s2s': 4,'JsTd2c': 24,'JsTd2d': 12,'JsTd2s': 12,'JsTs2d': 12,'JsTs2s': 4,'QsTd2c': 24,'QsTd2d': 12,'QsTd2s': 12,'QsTs2d': 12,'QsTs2s': 4,
  'KsTd2c': 24,'KsTd2d': 12,'KsTd2s': 12,'KsTs2d': 12,'KsTs2s': 4,'AsTd2c': 24,'AsTd2d': 12,'AsTd2s': 12,'AsTs2d': 12,'AsTs2s': 4,'QsJd2c': 24,'QsJd2d': 12,'QsJd2s': 12,'QsJs2d': 12,'QsJs2s': 4,'KsJd2c': 24,'KsJd2d': 12,'KsJd2s': 12,'KsJs2d': 12,'KsJs2s': 4,'AsJd2c': 24,'AsJd2d': 12,'AsJd2s': 12,
  'AsJs2d': 12,'AsJs2s': 4,'KsQd2c': 24,'KsQd2d': 12,'KsQd2s': 12,'KsQs2d': 12,'KsQs2s': 4,'AsQd2c': 24,'AsQd2d': 12,'AsQd2s': 12,'AsQs2d': 12,'AsQs2s': 4,'AsKd2c': 24,'AsKd2d': 12,'AsKd2s': 12,'AsKs2d': 12,'AsKs2s': 4,'5s4d3c': 24,'5s4d3d': 12,'5s4d3s': 12,'5s4s3d': 12,'5s4s3s': 4,'6s4d3c': 24,
  '6s4d3d': 12,'6s4d3s': 12,'6s4s3d': 12,'6s4s3s': 4,'7s4d3c': 24,'7s4d3d': 12,'7s4d3s': 12,'7s4s3d': 12,'7s4s3s': 4,'8s4d3c': 24,'8s4d3d': 12,'8s4d3s': 12,'8s4s3d': 12,'8s4s3s': 4,'9s4d3c': 24,'9s4d3d': 12,'9s4d3s': 12,'9s4s3d': 12,'9s4s3s': 4,'Ts4d3c': 24,'Ts4d3d': 12,'Ts4d3s': 12,
  'Ts4s3d': 12,'Ts4s3s': 4,'Js4d3c': 24,'Js4d3d': 12,'Js4d3s': 12,'Js4s3d': 12,'Js4s3s': 4,'Qs4d3c': 24,'Qs4d3d': 12,'Qs4d3s': 12,'Qs4s3d': 12,'Qs4s3s': 4,'Ks4d3c': 24,'Ks4d3d': 12,'Ks4d3s': 12,'Ks4s3d': 12,'Ks4s3s': 4,'As4d3c': 24,'As4d3d': 12,'As4d3s': 12,'As4s3d': 12,
  'As4s3s': 4,'6s5d3c': 24,'6s5d3d': 12,'6s5d3s': 12,'6s5s3d': 12,'6s5s3s': 4,'7s5d3c': 24,'7s5d3d': 12,'7s5d3s': 12,'7s5s3d': 12,'7s5s3s': 4,'8s5d3c': 24,'8s5d3d': 12,'8s5d3s': 12,'8s5s3d': 12,'8s5s3s': 4,'9s5d3c': 24,'9s5d3d': 12,
  '9s5d3s': 12,'9s5s3d': 12,'9s5s3s': 4,'Ts5d3c': 24,'Ts5d3d': 12,'Ts5d3s': 12,'Ts5s3d': 12,'Ts5s3s': 4,'Js5d3c': 24,'Js5d3d': 12,'Js5d3s': 12,'Js5s3d': 12,'Js5s3s': 4,'Qs5d3c': 24,'Qs5d3d': 12,'Qs5d3s': 12,'Qs5s3d': 12,
  'Qs5s3s': 4,'Ks5d3c': 24,'Ks5d3d': 12,'Ks5d3s': 12,'Ks5s3d': 12,'Ks5s3s': 4,'As5d3c': 24,'As5d3d': 12,'As5d3s': 12,'As5s3d': 12,'As5s3s': 4,'7s6d3c': 24,'7s6d3d': 12,'7s6d3s': 12,'7s6s3d': 12,'7s6s3s': 4,'8s6d3c': 24,'8s6d3d': 12,'8s6d3s': 12,'8s6s3d': 12,'8s6s3s': 4,
  '9s6d3c': 24,'9s6d3d': 12,'9s6d3s': 12,'9s6s3d': 12,'9s6s3s': 4,'Ts6d3c': 24,'Ts6d3d': 12,'Ts6d3s': 12,'Ts6s3d': 12,'Ts6s3s': 4,'Js6d3c': 24,'Js6d3d': 12,'Js6d3s': 12,'Js6s3d': 12,'Js6s3s': 4,'Qs6d3c': 24,'Qs6d3d': 12,'Qs6d3s': 12,'Qs6s3d': 12,'Qs6s3s': 4,'Ks6d3c': 24,
  'Ks6d3d': 12,'Ks6d3s': 12,'Ks6s3d': 12,'Ks6s3s': 4,'As6d3c': 24,'As6d3d': 12,'As6d3s': 12,'As6s3d': 12,'As6s3s': 4,'8s7d3c': 24,'8s7d3d': 12,'8s7d3s': 12,'8s7s3d': 12,'8s7s3s': 4,'9s7d3c': 24,'9s7d3d': 12,'9s7d3s': 12,'9s7s3d': 12,'9s7s3s': 4,'Ts7d3c': 24,
  'Ts7d3d': 12,'Ts7d3s': 12,'Ts7s3d': 12,'Ts7s3s': 4,'Js7d3c': 24,'Js7d3d': 12,'Js7d3s': 12,'Js7s3d': 12,'Js7s3s': 4,'Qs7d3c': 24,'Qs7d3d': 12,'Qs7d3s': 12,'Qs7s3d': 12,'Qs7s3s': 4,'Ks7d3c': 24,'Ks7d3d': 12,'Ks7d3s': 12,'Ks7s3d': 12,'Ks7s3s': 4,'As7d3c': 24,'As7d3d': 12,'As7d3s': 12,'As7s3d': 12,'As7s3s': 4,'9s8d3c': 24,'9s8d3d': 12,'9s8d3s': 12,
  '9s8s3d': 12,'9s8s3s': 4,'Ts8d3c': 24,'Ts8d3d': 12,'Ts8d3s': 12,'Ts8s3d': 12,'Ts8s3s': 4,'Js8d3c': 24,'Js8d3d': 12,'Js8d3s': 12,'Js8s3d': 12,'Js8s3s': 4,'Qs8d3c': 24,'Qs8d3d': 12,'Qs8d3s': 12,'Qs8s3d': 12,'Qs8s3s': 4,'Ks8d3c': 24,'Ks8d3d': 12,'Ks8d3s': 12,'Ks8s3d': 12,'Ks8s3s': 4,'As8d3c': 24,'As8d3d': 12,'As8d3s': 12,'As8s3d': 12,'As8s3s': 4,
  'Ts9d3c': 24,'Ts9d3d': 12,'Ts9d3s': 12,'Ts9s3d': 12,'Ts9s3s': 4,'Js9d3c': 24,'Js9d3d': 12,'Js9d3s': 12,'Js9s3d': 12,'Js9s3s': 4,'Qs9d3c': 24,'Qs9d3d': 12,'Qs9d3s': 12,'Qs9s3d': 12,'Qs9s3s': 4,'Ks9d3c': 24,'Ks9d3d': 12,'Ks9d3s': 12,'Ks9s3d': 12,'Ks9s3s': 4,'As9d3c': 24,'As9d3d': 12,'As9d3s': 12,'As9s3d': 12,'As9s3s': 4,'JsTd3c': 24,'JsTd3d': 12,'JsTd3s': 12,'JsTs3d': 12,'JsTs3s': 4,'QsTd3c': 24,
  'QsTd3d': 12,'QsTd3s': 12,'QsTs3d': 12,'QsTs3s': 4,'KsTd3c': 24,'KsTd3d': 12,'KsTd3s': 12,'KsTs3d': 12,'KsTs3s': 4,'AsTd3c': 24,'AsTd3d': 12,'AsTd3s': 12,'AsTs3d': 12,'AsTs3s': 4,'QsJd3c': 24,'QsJd3d': 12,'QsJd3s': 12,'QsJs3d': 12,'QsJs3s': 4,'KsJd3c': 24,'KsJd3d': 12,'KsJd3s': 12,'KsJs3d': 12,'KsJs3s': 4,'AsJd3c': 24,'AsJd3d': 12,'AsJd3s': 12,'AsJs3d': 12,'AsJs3s': 4,'KsQd3c': 24,
  'KsQd3d': 12,'KsQd3s': 12,'KsQs3d': 12,'KsQs3s': 4,'AsQd3c': 24,'AsQd3d': 12,'AsQd3s': 12,'AsQs3d': 12,'AsQs3s': 4,'AsKd3c': 24,'AsKd3d': 12,'AsKd3s': 12,'AsKs3d': 12,'AsKs3s': 4,'6s5d4c': 24,'6s5d4d': 12,'6s5d4s': 12,'6s5s4d': 12,'6s5s4s': 4,'7s5d4c': 24,'7s5d4d': 12,'7s5d4s': 12,'7s5s4d': 12,'7s5s4s': 4,'8s5d4c': 24,'8s5d4d': 12,
  '8s5d4s': 12,'8s5s4d': 12,'8s5s4s': 4,'9s5d4c': 24,'9s5d4d': 12,'9s5d4s': 12,'9s5s4d': 12,'9s5s4s': 4,'Ts5d4c': 24,'Ts5d4d': 12,'Ts5d4s': 12,'Ts5s4d': 12,'Ts5s4s': 4,'Js5d4c': 24,'Js5d4d': 12,'Js5d4s': 12,'Js5s4d': 12,'Js5s4s': 4,'Qs5d4c': 24,'Qs5d4d': 12,'Qs5d4s': 12,'Qs5s4d': 12,'Qs5s4s': 4,'Ks5d4c': 24,'Ks5d4d': 12,'Ks5d4s': 12,'Ks5s4d': 12,
  'Ks5s4s': 4,'As5d4c': 24,'As5d4d': 12,'As5d4s': 12,'As5s4d': 12,'As5s4s': 4,'7s6d4c': 24,'7s6d4d': 12,'7s6d4s': 12,'7s6s4d': 12,'7s6s4s': 4,'8s6d4c': 24,'8s6d4d': 12,'8s6d4s': 12,'8s6s4d': 12,'8s6s4s': 4,'9s6d4c': 24,'9s6d4d': 12,'9s6d4s': 12,'9s6s4d': 12,'9s6s4s': 4,'Ts6d4c': 24,'Ts6d4d': 12,'Ts6d4s': 12,'Ts6s4d': 12,'Ts6s4s': 4,'Js6d4c': 24,
  'Js6d4d': 12,'Js6d4s': 12,'Js6s4d': 12,'Js6s4s': 4,'Qs6d4c': 24,'Qs6d4d': 12,'Qs6d4s': 12,'Qs6s4d': 12,'Qs6s4s': 4,'Ks6d4c': 24,'Ks6d4d': 12,'Ks6d4s': 12,'Ks6s4d': 12,'Ks6s4s': 4,'As6d4c': 24,'As6d4d': 12,'As6d4s': 12,'As6s4d': 12,'As6s4s': 4,'8s7d4c': 24,'8s7d4d': 12,'8s7d4s': 12,'8s7s4d': 12,'8s7s4s': 4,'9s7d4c': 24,'9s7d4d': 12,
  '9s7d4s': 12,'9s7s4d': 12,'9s7s4s': 4,'Ts7d4c': 24,'Ts7d4d': 12,'Ts7d4s': 12,'Ts7s4d': 12,'Ts7s4s': 4,'Js7d4c': 24,'Js7d4d': 12,'Js7d4s': 12,'Js7s4d': 12,'Js7s4s': 4,'Qs7d4c': 24,'Qs7d4d': 12,'Qs7d4s': 12,'Qs7s4d': 12,'Qs7s4s': 4,'Ks7d4c': 24,'Ks7d4d': 12,'Ks7d4s': 12,'Ks7s4d': 12,'Ks7s4s': 4,
  'As7d4c': 24,'As7d4d': 12,'As7d4s': 12,'As7s4d': 12,'As7s4s': 4,'9s8d4c': 24,'9s8d4d': 12,'9s8d4s': 12,'9s8s4d': 12,'9s8s4s': 4,'Ts8d4c': 24,'Ts8d4d': 12,'Ts8d4s': 12,'Ts8s4d': 12,'Ts8s4s': 4,'Js8d4c': 24,'Js8d4d': 12,'Js8d4s': 12,'Js8s4d': 12,'Js8s4s': 4,'Qs8d4c': 24,'Qs8d4d': 12,'Qs8d4s': 12,'Qs8s4d': 12,
  'Qs8s4s': 4,'Ks8d4c': 24,'Ks8d4d': 12,'Ks8d4s': 12,'Ks8s4d': 12,'Ks8s4s': 4,'As8d4c': 24,'As8d4d': 12,'As8d4s': 12,'As8s4d': 12,'As8s4s': 4,'Ts9d4c': 24,'Ts9d4d': 12,'Ts9d4s': 12,'Ts9s4d': 12,'Ts9s4s': 4,'Js9d4c': 24,'Js9d4d': 12,'Js9d4s': 12,'Js9s4d': 12,'Js9s4s': 4,'Qs9d4c': 24,'Qs9d4d': 12,
  'Qs9d4s': 12,'Qs9s4d': 12,'Qs9s4s': 4,'Ks9d4c': 24,'Ks9d4d': 12,'Ks9d4s': 12,'Ks9s4d': 12,'Ks9s4s': 4,'As9d4c': 24,'As9d4d': 12,'As9d4s': 12,'As9s4d': 12,'As9s4s': 4,'JsTd4c': 24,'JsTd4d': 12,'JsTd4s': 12,'JsTs4d': 12,'JsTs4s': 4,'QsTd4c': 24,'QsTd4d': 12,'QsTd4s': 12,'QsTs4d': 12,'QsTs4s': 4,'KsTd4c': 24,'KsTd4d': 12,'KsTd4s': 12,'KsTs4d': 12,'KsTs4s': 4,
  'AsTd4c': 24,'AsTd4d': 12,'AsTd4s': 12,'AsTs4d': 12,'AsTs4s': 4,'QsJd4c': 24,'QsJd4d': 12,'QsJd4s': 12,'QsJs4d': 12,'QsJs4s': 4,'KsJd4c': 24,'KsJd4d': 12,'KsJd4s': 12,'KsJs4d': 12,'KsJs4s': 4,'AsJd4c': 24,'AsJd4d': 12,'AsJd4s': 12,'AsJs4d': 12,'AsJs4s': 4,'KsQd4c': 24,'KsQd4d': 12,
  'KsQd4s': 12,'KsQs4d': 12,'KsQs4s': 4,'AsQd4c': 24,'AsQd4d': 12,'AsQd4s': 12,'AsQs4d': 12,'AsQs4s': 4,'AsKd4c': 24,'AsKd4d': 12,'AsKd4s': 12,'AsKs4d': 12,'AsKs4s': 4,'7s6d5c': 24,'7s6d5d': 12,'7s6d5s': 12,'7s6s5d': 12,'7s6s5s': 4,'8s6d5c': 24,'8s6d5d': 12,'8s6d5s': 12,
  '8s6s5d': 12,'8s6s5s': 4,'9s6d5c': 24,'9s6d5d': 12,'9s6d5s': 12,'9s6s5d': 12,'9s6s5s': 4,'Ts6d5c': 24,'Ts6d5d': 12,'Ts6d5s': 12,'Ts6s5d': 12,'Ts6s5s': 4,'Js6d5c': 24,'Js6d5d': 12,'Js6d5s': 12,'Js6s5d': 12,'Js6s5s': 4,'Qs6d5c': 24,'Qs6d5d': 12,'Qs6d5s': 12,
  'Qs6s5d': 12,'Qs6s5s': 4,'Ks6d5c': 24,'Ks6d5d': 12,'Ks6d5s': 12,'Ks6s5d': 12,'Ks6s5s': 4,'As6d5c': 24,'As6d5d': 12,'As6d5s': 12,'As6s5d': 12,'As6s5s': 4,'8s7d5c': 24,'8s7d5d': 12,'8s7d5s': 12,'8s7s5d': 12,'8s7s5s': 4,'9s7d5c': 24,'9s7d5d': 12,'9s7d5s': 12,'9s7s5d': 12,'9s7s5s': 4,'Ts7d5c': 24,'Ts7d5d': 12,
  'Ts7d5s': 12,'Ts7s5d': 12,'Ts7s5s': 4,'Js7d5c': 24,'Js7d5d': 12,'Js7d5s': 12,'Js7s5d': 12,'Js7s5s': 4,'Qs7d5c': 24,'Qs7d5d': 12,'Qs7d5s': 12,'Qs7s5d': 12,'Qs7s5s': 4,'Ks7d5c': 24,'Ks7d5d': 12,'Ks7d5s': 12,'Ks7s5d': 12,'Ks7s5s': 4,'As7d5c': 24,'As7d5d': 12,'As7d5s': 12,'As7s5d': 12,
  'As7s5s': 4,'9s8d5c': 24,'9s8d5d': 12,'9s8d5s': 12,'9s8s5d': 12,'9s8s5s': 4,'Ts8d5c': 24,'Ts8d5d': 12,'Ts8d5s': 12,'Ts8s5d': 12,'Ts8s5s': 4,'Js8d5c': 24,'Js8d5d': 12,'Js8d5s': 12,'Js8s5d': 12,'Js8s5s': 4,'Qs8d5c': 24,'Qs8d5d': 12,'Qs8d5s': 12,'Qs8s5d': 12,'Qs8s5s': 4,'Ks8d5c': 24,'Ks8d5d': 12,'Ks8d5s': 12,'Ks8s5d': 12,'Ks8s5s': 4,'As8d5c': 24,'As8d5d': 12,
  'As8d5s': 12,'As8s5d': 12,'As8s5s': 4,'Ts9d5c': 24,'Ts9d5d': 12,'Ts9d5s': 12,'Ts9s5d': 12,'Ts9s5s': 4,'Js9d5c': 24,'Js9d5d': 12,'Js9d5s': 12,'Js9s5d': 12,'Js9s5s': 4,'Qs9d5c': 24,'Qs9d5d': 12,'Qs9d5s': 12,'Qs9s5d': 12,'Qs9s5s': 4,'Ks9d5c': 24,'Ks9d5d': 12,
  'Ks9d5s': 12,'Ks9s5d': 12,'Ks9s5s': 4,'As9d5c': 24,'As9d5d': 12,'As9d5s': 12,'As9s5d': 12,'As9s5s': 4,'JsTd5c': 24,'JsTd5d': 12,'JsTd5s': 12,'JsTs5d': 12,'JsTs5s': 4,'QsTd5c': 24,'QsTd5d': 12,'QsTd5s': 12,'QsTs5d': 12,'QsTs5s': 4,'KsTd5c': 24,'KsTd5d': 12,'KsTd5s': 12,'KsTs5d': 12,
  'KsTs5s': 4,'AsTd5c': 24,'AsTd5d': 12,'AsTd5s': 12,'AsTs5d': 12,'AsTs5s': 4,'QsJd5c': 24,'QsJd5d': 12,'QsJd5s': 12,'QsJs5d': 12,'QsJs5s': 4,'KsJd5c': 24,'KsJd5d': 12,'KsJd5s': 12,'KsJs5d': 12,'KsJs5s': 4,'AsJd5c': 24,'AsJd5d': 12,'AsJd5s': 12,'AsJs5d': 12,'AsJs5s': 4,'KsQd5c': 24,'KsQd5d': 12,
  'KsQd5s': 12,'KsQs5d': 12,'KsQs5s': 4,'AsQd5c': 24,'AsQd5d': 12,'AsQd5s': 12,'AsQs5d': 12,'AsQs5s': 4,'AsKd5c': 24,'AsKd5d': 12,'AsKd5s': 12,'AsKs5d': 12,'AsKs5s': 4,'8s7d6c': 24,'8s7d6d': 12,'8s7d6s': 12,'8s7s6d': 12,'8s7s6s': 4,'9s7d6c': 24,'9s7d6d': 12,'9s7d6s': 12,'9s7s6d': 12,'9s7s6s': 4,
  'Ts7d6c': 24,'Ts7d6d': 12,'Ts7d6s': 12,'Ts7s6d': 12,'Ts7s6s': 4,'Js7d6c': 24,'Js7d6d': 12,'Js7d6s': 12,'Js7s6d': 12,'Js7s6s': 4,'Qs7d6c': 24,'Qs7d6d': 12,'Qs7d6s': 12,'Qs7s6d': 12,'Qs7s6s': 4,'Ks7d6c': 24,'Ks7d6d': 12,'Ks7d6s': 12,'Ks7s6d': 12,'Ks7s6s': 4,
  'As7d6c': 24,'As7d6d': 12,'As7d6s': 12,'As7s6d': 12,'As7s6s': 4,'9s8d6c': 24,'9s8d6d': 12,'9s8d6s': 12,'9s8s6d': 12,'9s8s6s': 4,'Ts8d6c': 24,'Ts8d6d': 12,'Ts8d6s': 12,'Ts8s6d': 12,'Ts8s6s': 4,'Js8d6c': 24,'Js8d6d': 12,'Js8d6s': 12,'Js8s6d': 12,'Js8s6s': 4,'Qs8d6c': 24,'Qs8d6d': 12,'Qs8d6s': 12,'Qs8s6d': 12,'Qs8s6s': 4,
  'Ks8d6c': 24,'Ks8d6d': 12,'Ks8d6s': 12,'Ks8s6d': 12,'Ks8s6s': 4,'As8d6c': 24,'As8d6d': 12,'As8d6s': 12,'As8s6d': 12,'As8s6s': 4,'Ts9d6c': 24,'Ts9d6d': 12,'Ts9d6s': 12,'Ts9s6d': 12,'Ts9s6s': 4,'Js9d6c': 24,'Js9d6d': 12,'Js9d6s': 12,'Js9s6d': 12,'Js9s6s': 4,'Qs9d6c': 24,'Qs9d6d': 12,'Qs9d6s': 12,'Qs9s6d': 12,'Qs9s6s': 4,
  'Ks9d6c': 24,'Ks9d6d': 12,'Ks9d6s': 12,'Ks9s6d': 12,'Ks9s6s': 4,'As9d6c': 24,'As9d6d': 12,'As9d6s': 12,'As9s6d': 12,'As9s6s': 4,'JsTd6c': 24,'JsTd6d': 12,'JsTd6s': 12,'JsTs6d': 12,'JsTs6s': 4,'QsTd6c': 24,'QsTd6d': 12,'QsTd6s': 12,'QsTs6d': 12,'QsTs6s': 4,'KsTd6c': 24,'KsTd6d': 12,'KsTd6s': 12,
  'KsTs6d': 12,'KsTs6s': 4,'AsTd6c': 24,'AsTd6d': 12,'AsTd6s': 12,'AsTs6d': 12,'AsTs6s': 4,'QsJd6c': 24,'QsJd6d': 12,'QsJd6s': 12,'QsJs6d': 12,'QsJs6s': 4,'KsJd6c': 24,'KsJd6d': 12,'KsJd6s': 12,'KsJs6d': 12,'KsJs6s': 4,'AsJd6c': 24,'AsJd6d': 12,'AsJd6s': 12,'AsJs6d': 12,'AsJs6s': 4,'KsQd6c': 24,'KsQd6d': 12,
  'KsQd6s': 12,'KsQs6d': 12,'KsQs6s': 4,'AsQd6c': 24,'AsQd6d': 12,'AsQd6s': 12,'AsQs6d': 12,'AsQs6s': 4,'AsKd6c': 24,'AsKd6d': 12,'AsKd6s': 12,'AsKs6d': 12,'AsKs6s': 4,'9s8d7c': 24,'9s8d7d': 12,'9s8d7s': 12,'9s8s7d': 12,'9s8s7s': 4,'Ts8d7c': 24,'Ts8d7d': 12,'Ts8d7s': 12,'Ts8s7d': 12,'Ts8s7s': 4,'Js8d7c': 24,'Js8d7d': 12,
  'Js8d7s': 12,'Js8s7d': 12,'Js8s7s': 4,'Qs8d7c': 24,'Qs8d7d': 12,'Qs8d7s': 12,'Qs8s7d': 12,'Qs8s7s': 4,'Ks8d7c': 24,'Ks8d7d': 12,'Ks8d7s': 12,'Ks8s7d': 12,'Ks8s7s': 4,'As8d7c': 24,'As8d7d': 12,'As8d7s': 12,'As8s7d': 12,'As8s7s': 4,'Ts9d7c': 24,'Ts9d7d': 12,'Ts9d7s': 12,
  'Ts9s7d': 12,'Ts9s7s': 4,'Js9d7c': 24,'Js9d7d': 12,'Js9d7s': 12,'Js9s7d': 12,'Js9s7s': 4,'Qs9d7c': 24,'Qs9d7d': 12,'Qs9d7s': 12,'Qs9s7d': 12,'Qs9s7s': 4,'Ks9d7c': 24,'Ks9d7d': 12,'Ks9d7s': 12,'Ks9s7d': 12,'Ks9s7s': 4,'As9d7c': 24,'As9d7d': 12,'As9d7s': 12,'As9s7d': 12,'As9s7s': 4,'JsTd7c': 24,
  'JsTd7d': 12,'JsTd7s': 12,'JsTs7d': 12,'JsTs7s': 4,'QsTd7c': 24,'QsTd7d': 12,'QsTd7s': 12,'QsTs7d': 12,'QsTs7s': 4,'KsTd7c': 24,'KsTd7d': 12,'KsTd7s': 12,'KsTs7d': 12,'KsTs7s': 4,'AsTd7c': 24,'AsTd7d': 12,'AsTd7s': 12,'AsTs7d': 12,'AsTs7s': 4,'QsJd7c': 24,'QsJd7d': 12,'QsJd7s': 12,'QsJs7d': 12,'QsJs7s': 4,
  'KsJd7c': 24,'KsJd7d': 12,'KsJd7s': 12,'KsJs7d': 12,'KsJs7s': 4,'AsJd7c': 24,'AsJd7d': 12,'AsJd7s': 12,'AsJs7d': 12,'AsJs7s': 4,'KsQd7c': 24,'KsQd7d': 12,'KsQd7s': 12,'KsQs7d': 12,'KsQs7s': 4,'AsQd7c': 24,'AsQd7d': 12,'AsQd7s': 12,'AsQs7d': 12,'AsQs7s': 4,'AsKd7c': 24,'AsKd7d': 12,'AsKd7s': 12,'AsKs7d': 12,'AsKs7s': 4,
  'Ts9d8c': 24,'Ts9d8d': 12,'Ts9d8s': 12,'Ts9s8d': 12,'Ts9s8s': 4,'Js9d8c': 24,'Js9d8d': 12,'Js9d8s': 12,'Js9s8d': 12,'Js9s8s': 4,'Qs9d8c': 24,'Qs9d8d': 12,'Qs9d8s': 12,'Qs9s8d': 12,'Qs9s8s': 4,'Ks9d8c': 24,'Ks9d8d': 12,'Ks9d8s': 12,'Ks9s8d': 12,'Ks9s8s': 4,'As9d8c': 24,
  'As9d8d': 12,'As9d8s': 12,'As9s8d': 12,'As9s8s': 4,'JsTd8c': 24,'JsTd8d': 12,'JsTd8s': 12,'JsTs8d': 12,'JsTs8s': 4,'QsTd8c': 24,'QsTd8d': 12,'QsTd8s': 12,'QsTs8d': 12,'QsTs8s': 4,'KsTd8c': 24,'KsTd8d': 12,'KsTd8s': 12,'KsTs8d': 12,'KsTs8s': 4,'AsTd8c': 24,'AsTd8d': 12,'AsTd8s': 12,'AsTs8d': 12,'AsTs8s': 4,'QsJd8c': 24,
  'QsJd8d': 12,'QsJd8s': 12,'QsJs8d': 12,'QsJs8s': 4,'KsJd8c': 24,'KsJd8d': 12,'KsJd8s': 12,'KsJs8d': 12,'KsJs8s': 4,'AsJd8c': 24,'AsJd8d': 12,'AsJd8s': 12,'AsJs8d': 12,'AsJs8s': 4,'KsQd8c': 24,'KsQd8d': 12,'KsQd8s': 12,'KsQs8d': 12,'KsQs8s': 4,'AsQd8c': 24,'AsQd8d': 12,
  'AsQd8s': 12,'AsQs8d': 12,'AsQs8s': 4,'AsKd8c': 24,'AsKd8d': 12,'AsKd8s': 12,'AsKs8d': 12,'AsKs8s': 4,'JsTd9c': 24,'JsTd9d': 12,'JsTd9s': 12,'JsTs9d': 12,'JsTs9s': 4,'QsTd9c': 24,'QsTd9d': 12,'QsTd9s': 12,'QsTs9d': 12,'QsTs9s': 4,'KsTd9c': 24,'KsTd9d': 12,
  'KsTd9s': 12,'KsTs9d': 12,'KsTs9s': 4,'AsTd9c': 24,'AsTd9d': 12,'AsTd9s': 12,'AsTs9d': 12,'AsTs9s': 4,'QsJd9c': 24,'QsJd9d': 12,'QsJd9s': 12,'QsJs9d': 12,'QsJs9s': 4,'KsJd9c': 24,'KsJd9d': 12,'KsJd9s': 12,'KsJs9d': 12,'KsJs9s': 4,'AsJd9c': 24,'AsJd9d': 12,
  'AsJd9s': 12,'AsJs9d': 12,'AsJs9s': 4,'KsQd9c': 24,'KsQd9d': 12,'KsQd9s': 12,'KsQs9d': 12,'KsQs9s': 4,'AsQd9c': 24,'AsQd9d': 12,'AsQd9s': 12,'AsQs9d': 12,'AsQs9s': 4,'AsKd9c': 24,'AsKd9d': 12,'AsKd9s': 12,'AsKs9d': 12,'AsKs9s': 4,
  'QsJdTc': 24,'QsJdTd': 12,'QsJdTs': 12,'QsJsTd': 12,'QsJsTs': 4,'KsJdTc': 24,'KsJdTd': 12,'KsJdTs': 12,'KsJsTd': 12,'KsJsTs': 4,'AsJdTc': 24,'AsJdTd': 12,'AsJdTs': 12,'AsJsTd': 12,'AsJsTs': 4,'KsQdTc': 24,'KsQdTd': 12,'KsQdTs': 12,'KsQsTd': 12,'KsQsTs': 4,'AsQdTc': 24,
  'AsQdTd': 12,'AsQdTs': 12,'AsQsTd': 12,'AsQsTs': 4,'AsKdTc': 24,'AsKdTd': 12,'AsKdTs': 12,'AsKsTd': 12,'AsKsTs': 4,'KsQdJc': 24,'KsQdJd': 12,'KsQdJs': 12,'KsQsJd': 12,'KsQsJs': 4,'AsQdJc': 24,'AsQdJd': 12,'AsQdJs': 12,'AsQsJd': 12,'AsQsJs': 4,'AsKdJc': 24,
  'AsKdJd': 12,'AsKdJs': 12,'AsKsJd': 12,'AsKsJs': 4,'AsKdQc': 24,'AsKdQd': 12,'AsKdQs': 12,'AsKsQd': 12,'AsKsQs': 4
}

export const PTB_ACCESS_EMAILS = [
  "Kahleburns@gmail.com",
  "Coreykempson@hotmail.com",
  "Adrianattenborough@gmail.com",
  "odin_admin@yopmail.com",
  "nuskenpham@gmail.com",
  "tester_29@yopmail.com",
  "tester_37@yopmail.com",
  "tom@nustechnology.com"
]

export const CHANGE_SUBSCRIPTION_STATUS = {
  upGrade: 'Upgrade',
  downGrade: 'Downgrade',
  unknow: 'Unknow'
}

export const PTB_NODE_SCORE_DECIMAL = 0
export const PTB_HAND_SCORE_DECIMAL = 0
export const PTB_SESSION_SCORE_DECIMAL = 0
export const PTB_INIT_STATE = {
  positionsFold: [],
  preflopView: true,
  actived: false,
  nodeActived: {},
  holdCardsOop: '',
  holdCardsIp:'',
  simType: '',
  currentSeat: '',
  flops: [],
  strategy: {},
  strategyEV: {},
  flopsLoaded: [],
  strategyLoaded: {},
  strategyEVLoaded: {},
  decisionsTreeOrigin: [],
  decisionsTreeLoaded: [],
  gameResult: {},
  loadingNextHand: false,
  loadingNextDesision: false,
  actionOptions: [],
  preflopGame: {},
  clicked: false,
  autoPlay: true,
  skipPreflop: false,
  skipPreflopAction: false,
  autoClickLoading: false,
  random: false,
  displayNext: false,
  decisionCombos: [],
  decisionCombosLoaded: [],
  valid: false,
  recentScore: 'resting',
  score: 'resting',
  scoreEV: 0.0,
  popupEndSession: false,
  historySim: {},
  isInactive: false,
  endGame: false,
  loadTurn: false,
  loadRiver: false,
  autoNextDecision: true,
  instantPlay: false,
  nodeRandom: {}
}

export const FREE_A_WEEK_ACCESS_EMAILS = ["andrewholdroyd@gmail.com",
"noddbritt123@gmail.com",
"davidnovakovic@centrum.cz",
"jarod@harboursidelandscaping.com.au",
"yidooos@hotmail.com",
"samuel26genest@hotmail.com",
"vlaviusz@yahoo.com",
"dominik.lp640@bluewin.ch",
"anwar_major@hotmail.com",
"michaelpittet80@bluewin.ch",
"dislike-rip06@icloud.com",
"2022733279@qq.com",
"lishijie_0412@hotmail.com",
"tjwilliams84@hotmail.com",
"marcschulmeister@hotmail.de",
"nick.eagan@hotmail.com",
"george.kwok77@gmail.com",
"linkenw@outlook.com",
"blazejpolec@yahoo.com",
"charlyneumayr@web.de",
"provoma1@hotmail.com",
"bobbyjoemurphy@hotmail.co.uk",
"oscolaik@gmail.com",
"qkabww392@mozmail.com",
"gherardi.gabriel@outlook.com",
"miguelcarvalhooo@hotmail.com",
"davide.campa@hotmail.it",
"markus.wittmann@gmx.at",
"manuel.daschl@hotmail.com",
"ragim.etemi@me.com",
"kevin_berkhout@hotmail.com",
"tratamesuavemente123@hotmail.com",
"kristjan@allik.ee",
"jp_joaopinto@hotmail.com",
"redisland11@hotmail.com",
"ziom4000@gmail.com",
"felix_eikrem@hotmail.com",
"romancedawn85@hotmail.com",
"rodrigophiga@hotmail.com",
"steffen@flyteori.no",
"paulo.carbonera@hotmail.com",
"theo.bijaoui-charles@hotmail.fr",
"remonzilver@hotmail.com",
"lukeleggett31@hotmail.co.uk",
"dominicinchley@hotmail.co.uk",
"mink21original@hotmail.com",
"dontbluffmyj@gmail.com",
"mikiheikkila@outlook.com",
"jonas.gerhard@yahoo.de",
"chandede@yahoo.com.hk",
"towingyun2006@yahoo.com.hk",
"markdra56@yahoo.com.hk",
"contentedcaninesuk@gmail.com",
"martinpiik18@hotmail.com",
"diii00000123@yahoo.com.tw",
"lkpken@yahoo.com.hk",
"li2000621@yahoo.com.tw",
"rexrex932@yahoo.com.hk",
"eddieau@ymail.com",
"wa40505@icloud.com",
"mingxeri@hotmail.com",
"timir.mohammad@outlook.de",
"gluu@live.com.au",
"xarizax@abv.bg",
"jsergio321@hotmail.com",
"salva.r@hotmail.it",
"philip_laitila@hotmail.com",
"picardjohn@yahoo.com",
"deric_ball@hotmail.com",
"xg_lawyer@yahoo.com",
"tjoshannassy@yahoo.com",
"minhthai1809@yahoo.com",
"r_deparis@yahoo.com",
"peongwj97@outlook.my",
"cameron.dhaliwal@gmail.com",
"yx-style@foxmail.com",
"248247908@qq.com",
"rousel12@hotmail.com",
"sankesh_kumar@yahoo.com",
"alex.bain@yahoo.com",
"s.ismai@hotmail.com",
"christoph.jllig@web.de",
"roy_rodrigues995@hotmail.ca",
"drmariomentrop@hotmail.com",
"peixethi@yahoo.com.br",
"paulo.caponi@yahoo.com.br",
"vmio@yahoo.com.br",
"vitorstumpf@yahoo.com.br",
"vagner_spm@yahoo.com.br",
"nathan.doarte@hotmail.com",
"cassio_silva85@yahoo.com.br",
"caduvicente@yahoo.com",
"bpaulino14@yahoo.com",
"mavinicius25@gmail.com",
"marcoswiltb@yahoo.com",
"marcospiri@yahoo.com.br",
"marcoshorbach@yahoo.com.br",
"gersonsilvajr@yahoo.com.br",
"magakaze@yahoo.com",
"gabrielverissmo@yahoo.com.br",
"andresucogon@yahoo.com.mx",
"andre_miguelytoz@yahoo.com",
"robsonsouza10@hotmail.com",
"fabio_hatano@yahoo.com.br",
"adelirberardo@yahoo.com.br",
"eric.oliveiraepm@yahoo.com.br",
"a.wickerman@protonmail.com",
"edao_spfc@hotmail.com",
"elvis.r@uol.com.br",
"rafaelbelinidealmeida@hotmail.com",
"guilherme_27th@hotmail.com",
"lacicds@outlook.com.br",
"fabianosantos10414@hotmail.com",
"matano_caio@hotmail.com",
"victorxm1@hotmail.com",
"guto_abreu@hotmail.com",
"moa21130@hotmail.com",
"jagalskarftp@hotmail.com",
"nelisschuifwijk@hotmail.com",
"gameofequity@gmail.com",
"pharapiraca@hotmail.com",
"neggrogatto@yahoo.com.br",
"robsherwood78@hotmail.com",
"thomas.kesno@hotmail.fr",
"martinbouliane1992@hotmail.com",
"b_ballin_03@hotmail.com",
"chris-6611@hotmail.com",
"lieskovsky.erik@gmail.com",
"copperit@xtra.co.nz",
"pedropitchu@yahoo.com.br",
"dado2299@hotmail.com",
"matti136@hotmail.com",
"reinisikerts@gmail.com",
"marstrojak@hotmail.com",
"kirillmiros@gmail.com",
"matheus8367@hotmail.com",
"matheus_cavalli@hotmail.com",
"royal-vision@bluewin.ch",
"loicrandolet@outlook.fr",
"robin.hyyppa@live.se",
"dafoima@hotmail.com",
"pavel.matoska@seznam.cz",
"matulikvojta@email.cz",
"tiago.jesusluis@hotmail.com",
"maiconcmachado@hotmail.com",
"faisalshihabi@hotmail.com",
"rcfaria@live.com.pt",
"mitk0krasimirov@abv.bg",
"pshgul83@outlook.com",
"lucasbrumveiga@hotmail.com",
"afca_wesley_afca@hotmail.com",
"gabrielmendes82@hotmail.com",
"robsondpf@hotmail.com",
"gorkaoyaguitar@gmail.com",
"gui-98@hotmail.com",
"fugu.graphics@gmail.com",
"romainflament03@gmail.com",
"bruno_grazina@live.com.pt",
"mathonet.r@outlook.be",
"julieklein98@gmail.com",
"trymtj@hotmail.com",
"gabrielnet22@hotmail.com",
"fabianopedroso1@hotmail.com",
"aeae555@naver.com",
"marko.dogas@live.se",
"johannes.gassner@iplace.at",
"ferragemhamburgo@hotmail.com",
"romulocsar@hotmail.com",
"ns1001@hotmail.com",
"jonbux@hotmail.com",
"132.123@web.de",
"orange-juice18@hotmail.com",
"marllon.viana@hotmail.com",
"robbietucker@hotmail.com",
"peter.luscombe@outlook.com",
"marc.f@hotmail.co.uk",
"freddy4@hotmail.fr",
"mousehaohao@vip.qq.com",
"514428558@qq.com",
"yan91_9@hotmail.com",
"kojichiku@hotmail.com",
"traumspot@gmail.com",
"1916@gmx.de",
"casper212006@yandex.ru",
"ladymiu@yandex.ru",
"yann_champagne1@hotmail.com",
"si_mon_poirier@hotmail.com",
"letourneauphilippe@hotmail.com",
"blablamadeira@gmail.com",
"emilebp@live.com",
"simon1004@hotmail.com",
"samuelmicho@hotmail.com",
"kenzo_deceuster@hotmail.com",
"stein_borkvik@hotmail.com",
"pokerplayer@msgsafe.io",
"bflynn@claro.net.do",
"rick6r050@yahoo.co.jp",
"a.duveau@live.be",
"antarres_borko@abv.bg",
"laurent.ribous@hotmail.com",
"itsandiwilson@hotmail.com",
"grinder92@protonmail.com",
"sigikapeller@gmx.at",
"nyll04ps@gmail.com",
"bakkd@hotmail.com",
"vince604@hotmail.com",
"florian_dechanet@hotmail.co.uk",
"raphayakawa@yahoo.com",
"divemaxx@hotmail.com",
"victorhedmanrahm@hotmail.se",
"flori.thomas@freenet.de",
"joao_saldanha_38@hotmail.com",
"caiomouralo@hotmail.com",
"giorgio.stassen@hotmail.nl",
"nkuper97@gmail.com",
"coggy111@hotmail.co.uk",
"j.g.wgtn@hotmail.com",
"datbredahuzi@hotmail.co.uk",
"nicolas.champion501@orange.fr",
"ross.irvine@outlook.com",
"rick_andrey@hotmail.com",
"a_ortega@outlook.com",
"regcurr@live.ca",
"phwath@yahoo.com",
"dany_lakerskb@yahoo.com",
"daniel_kzc@yahoo.com",
"coreybradford95@yahoo.co.uk",
"terrypadams@yahoo.com",
"arspoklaz@yahoo.com",
"fannym95@yahoo.com",
"ady666999@yahoo.com",
"manugassmann@gmx.net",
"brianwindsor@shaw.ca",
"matejk992@gmail.com",
"whitewood@eastlink.ca",
"gustavohonorato@msn.com",
"osullivanboy@hotmail.com",
"robson.rfr@hotmail.com",
"yiannakou@cablenet.com.cy",
"dominikholzmueller@web.de",
"bader.sebastian@gmx.net",
"alexander_flohr@gmx.de",
"lemondedujeuvideo@outlook.fr",
"pinto.vhb@gmail.com",
"potti001988@hotmail.com",
"maxterrenas@yahoo.com",
"joerni-h@online.no",
"iwanrademakers@hotmail.com",
"pabllocabral@hotmail.com",
"hendrik.kwiatkowski@outlook.de",
"mark_brou@hotmail.com",
"davidepavone2014@libero.it",
"mmahlendorf@gmx.de",
"marco_st@gmx.at",
"dupreeza1410@gmail.com",
"panoonli@hotmail.com",
"robertlee1@outlook.com",
"lamprospro@hotmail.com",
"m-hoel@hotmail.no",
"razzer12@live.co.uk",
"bencpb@gmail.com",
"winsalabim@tutanota.com",
"p.villiard@hotmail.com",
"philsbrill@hotmail.com",
"maxsilz94@gmx.de",
"wayne0803@gmail.com",
"marcelkawa@gmx.de",
"manuel.vogel1@gmx.de",
"adi.toran@web.de",
"juhojuva@hotmail.com",
"italomarques11@hotmail.com.br",
"hatsor2@hotmail.com",
"danielbary@hotmail.com",
"arthur.ber@hotmail.com",
"furor351@hotmail.com",
"johnraste@hotmail.com",
"tatnio@gmx.de",
"terry.healy@hotmail.co.uk",
"pauladams815@msn.com",
"adamthornill@hotmail.co.uk",
"andreimorariu944@gmail.com",
"mollmarc@web.de",
"timreis@hotmail.com",
"jorgecabiedes99@hotmail.com",
"cooper345@hotmail.co.uk",
"tobias-krapp@web.de",
"achim456@gmx.de",
"juhnuli@hotmail.com",
"abbe_juventus10@hotmail.com",
"smclhun@naver.com",
"drewnaylor@hotmail.co.uk",
"keylings@zol.co.zw",
"lino.daute@web.de",
"jred404@outlook.com.au",
"lheise89@googlemail.com",
"ikompani@hotmail.es",
"fjuno@hotmail.com",
"bader2312@yahoo.com",
"cwpvanraamsdonk@hotmail.com",
"poker@olivermayr.at",
"marcelomaduro@hotmail.com",
"ramonpessoace@hotmail.com",
"alexanderpozo35@hotmail.com",
"973556035@qq.com",
"patrick_korb@hotmail.com",
"ianschmaltz@hotmail.com",
"tomcat777@gmx.net",
"peushup@hotmail.com",
"mevans@support-tech.com.au",
"delpalo3@hotmail.com",
"m.f.lima@bol.com.br",
"ymf@gmx.net",
"lucas.lima.cstr@outlook.com",
"andreluis_adv@hotmail.com",
"joaovictorldelima@hotmail.com",
"vitoronuki@yahoo.com.br",
"jho__lima@hotmail.com",
"mr.paulodino@hotmail.com",
"guipartala@hotmail.com",
"eu@ezequiel.xyz",
"davehudson_1@hotmail.com",
"lucas.cpedrosa@hotmail.com",
"crb011@hotmail.co.uk",
"rishi.lal@hotmail.com",
"danieln1997@hotmail.com",
"renandetros@hotmail.com",
"adamneighbour@hotmail.co.uk",
"rhabers@hotmail.com",
"mariosalbanopoulos@hotmail.com",
"ady@agriainvest.hu",
"shaneross@live.co.uk",
"steven.m.thomas@hotmail.com",
"michiknoebel@gmail.com",
"macdi83@hotmail.com",
"sbakerpoker@aol.co.uk",
"kyrleo1@hotmail.com",
"jamesbrown.89@hotmail.co.uk",
"davem86@hotmail.com",
"pacmankyiv@yahoo.com",
"oliverhl_tdic@yahoo.es",
"cosmins.mardare@yahoo.com",
"christoph.burdorf@yahoo.de",
"mrejior@yahoo.fr",
"burki-91@hotmail.com",
"bojan_bdd@yahoo.com",
"mdmurray@hotmail.co.uk",
"ghjk@rocketmail.com",
"geoin@yahoo.com",
"andrei.ilie24@yahoo.com",
"lockettlee8@aol.com",
"roman.skoda@yahoo.com",
"kbarov@yahoo.com",
"a632063877@icloud.com",
"toddirein@web.de",
"alirizvi992@gmail.com",
"paullovegrove2605@btinternet.com",
"joewrafter@gmail.com",
"pi-thu@hotmail.com",
"jrenato_d@hotmail.com",
"cleitonkania@hotmail.com",
"antonio.fn@hotmail.com",
"alisongelain@hotmail.com",
"ricardo.agnolo@hotmail.com",
"luferm11@hotmail.com",
"zzakkennna2@yahoo.co.jp",
"robshields23@hotmail.com",
"scholl.lhays@gmx.ch",
"manuel.thegreat@icloud.com",
"juanchi_calderon@hotmail.com",
"hernan.rodriguez93@hotmail.com",
"the_knyght@hotmail.com",
"greg57320@live.fr",
"sebastian0610@gmx.de",
"gregor.2000@hotmail.com",
"ljsheridan1@icloud.com",
"murilosantos.2018@hotmail.com",
"darlanroso@hotmail.com",
"jhonatam.ms_@hotmail.com",
"leemmolloy@gmail.com",
"spp_munhoz@hotmail.com",
"david.bernardi@hotmail.com",
"kylegoodwin@live.ca",
"onis_uk@hotmail.com",
"haslersamuel@hotmail.com",
"alexroger21oct@gmail.com",
"justinmcconkey@hotmail.com",
"davey.jones@live.ca",
"nego1001poker@gmail.com",
"elemag@gmx.co.uk",
"b.dijkman@chello.nl",
"corvonespoker@gmail.com",
"a_kamran91@hotmail.com",
"og1205@freemail.hu",
"shokyu@gmail.com",
"34vincent34@gmail.com",
"dota.styl3@yahoo.com",
"tiago.r.felicio@gmail.com",
"jkbets7@gmail.com",
"dj_stevey_spring@hotmail.co.uk",
"stevespring@hotmail.co.uk",
"lm25048720@hotmail.co.uk",
"infiniteperspectives@yahoo.co.uk",
"johanschultz@hotmail.dk",
"marcelofelipe99@hotmail.com",
"bubbleee12@hotmail.com",
"luke.boss@hotmail.com",
"scitzodtc@hotmail.com",
"pabloching22@hotmail.com",
"checkraiser89@outlook.com",
"philipp.broich@t-online.de",
"soeren_wick@yahoo.de",
"sebastian-2296@hotmail.com",
"espacepro1@hotmail.com",
"adelar.souza@outlook.com.br",
"kaijehnichen@yahoo.de",
"joao1rsneto@hotmail.com",
"ronan202020@hotmail.com",
"paul@zil.li",
"stockoliver@gmx.de",
"felix-ehlers@web.de",
"tec.rad@libero.it",
"igoraraujobh@hotmail.com",
"michael_de_lima01@hotmail.com",
"ronaldojunior99@hotmail.com",
"felipe_csf@hotmail.com",
"lesleythompson1978@hotmail.com",
"paulo.abreu18@outlook.com",
"renato_bfilho@hotmail.com",
"jellesmeulders@outlook.com",
"ronnyzetsch@gmx.de",
"kieran_fleming@hotmail.co.uk",
"lewis.t.higham@gmail.com",
"mickael.de-koning@hotmail.fr",
"benpwiddowson@hotmail.com",
"jan_schnellert7@gmx.de",
"benfono@hotmail.com",
"fragos7menetes@hotmail.com",
"mauricio.bcabral@hotmail.com",
"josepedro72@hotmail.com",
"juliano_marquess@hotmail.com",
"dayjonesuk@hotmail.com",
"elen_filip@hotmail.com",
"a2fapower@outlook.com",
"alineferraresi@hotmail.com",
"zakzy@protonmail.com",
"philippriedel1@yahoo.de",
"jonimi12345@yahoo.gr",
"oxkar@yahoo.com",
"ototbiliseli@yahoo.com",
"walker0502@yahoo.com",
"oriordanross2@yahoo.ie",
"constantin.adrian88@yahoo.com",
"ckovatchev@yahoo.fr",
"ciprianvoican@yahoo.com",
"nicolasguinot@aol.com",
"nicky_de_klerk@hotmail.nl",
"tratarisgiannis@yahoo.gr",
"nagynoorbert12@yahoo.com",
"toshapiter@yahoo.com",
"tomsroots@aol.com",
"ilia1995@yahoo.com",
"iamdodds@yahoo.co.uk",
"tgoodall10@yahoo.co.uk",
"michael_christiansen@yahoo.com",
"blurred_pablo@yahoo.co.uk",
"blakejohnston21@yahoo.co.uk",
"sveghinas@yahoo.com",
"stfn_fabian@yahoo.com",
"simonasablonskis@yahoo.com",
"assavass1957@yahoo.com",
"sheriefnegm@yahoo.com",
"gibboken@yahoo.co.uk",
"gary.parks@yahoo.co.uk",
"savvas_mitsikouridis@yahoo.com",
"lukaaso@yahoo.com",
"lsblecher@yahoo.de",
"rotting117@yahoo.gr",
"roby619robert@yahoo.com",
"robertadrianneagu@yahoo.com",
"rap_inside@yahoo.com",
"ra_buta@yahoo.com",
"chammy17@hotmail.com",
"s.danilo96@hotmail.it",
"carlos.benavides1313@outlook.com",
"elcontee@virgilio.it",
"da-sumi@yuai-hosp.jp",
"degraevejordy@hotmail.com",
"bobstarr@hotmail.de",
"paul12908@hotmail.co.uk",
"p.hackshaw@hotmail.co.uk",
"paul.rosner@gmx.at",
"dietmar_hu@gmx.at",
"oliviertaipina@free.fr",
"victor.gutkin@polymtl.ca",
"newf0revermore@gmail.com",
"neverfoldq5@web.de",
"toolehayden@gmail.com",
"simonhoffmann1991@web.de",
"ry4nh3nry@yahoo.co.nz",
"aaron_altun@hotmail.com",
"adamjohnson_1992@hotmail.co.uk",
"michal@stima.cz",
"mindcrushers@hotmail.com",
"norson2@gmail.com",
"ruca123123@hotmail.com",
"bukvic@gmx.ch",
"basdelaat89@hotmail.com",
"sebas2500@hotmail.com",
"ktoplay@hotmail.com",
"sondre_lie95@hotmail.com",
"denis.brandsteter@gmail.com",
"ruibouquet@hotmail.com",
"zidix@hotmail.com",
"lubbers_marco_@hotmail.com",
"quadvquadme@hotmail.com",
"zccmicky2@hotmail.com",
"catalinbadea13@yahoo.com",
"grinder992@yahoo.com",
"kitdu32@live.fr",
"achim456@web.de",
"felixvanbeuningen@web.de",
"dominikhinzmann@gmx.de",
"kealanhorgan999@hotmail.com",
"eduardoescada@hotmail.com",
"niklas.held1@gmx.de",
"kevframe@hotmail.com",
"rosaliepetit@yahoo.fr",
"jamescross96@hotmail.com",
"gdgrasser2@gmail.com",
"vagner_tt@hotmail.com",
"1009653037@qq.com",
"dawnfall26@hotmail.com",
"tbaggaley@hotmail.co.uk",
"sergios94@hotmail.com",
"ph.braeutigam@web.de",
"michaelnguyen.ca@outlook.com",
"loire-julien@live.fr",
"jeremy.chan@hotmail.com",
"jackn@outlook.com.au",
"airbeetroot@hotmail.co.nz",
"philipp.panchalingam@web.de",
"mwu3hd@pm.me",
"andresen_1980@hotmail.com",
"arhip_c59@yahoo.com",
"patten1983@yahoo.de",
"matej.mezgec@yahoo.com",
"giacomo.tagliaro@yahoo.co.uk",
"dansfarlea@yahoo.com",
"sandro-fh@hotmail.com",
"georgeroberts2008@gmail.com",
"jessebrianmckenzie@gmail.com"]
