import { positionsForQueryBuilder  } from 'services/game_play_service'
import { simTypeMapping, notify } from 'utils/utils'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify';
import { updateOdinLoadingShowingValue } from 'actions/odin-loading.actions'

export const loadTreeRequestPTB = (dispatch, dataStrategySelection) => {
  const { sim_type, positions } = dataStrategySelection
  const positionsForQuery = positionsForQueryBuilder(positions)
  const simType = simTypeMapping(sim_type)

  let treeInfo = JSON.parse(sessionStorage.getItem("treeInfo")) || {}
  treeInfo = { simName: renderSimName(dataStrategySelection) }
  return handleLoadTreeRequest(dataStrategySelection, simType, positionsForQuery, treeInfo).then(res => {
    dispatch(updateOdinLoadingShowingValue('done'))
    return res
  })
}

export const loadTreeRequest = (dataStrategySelection, sims, role='') => {
  const { sim_type, positions } = dataStrategySelection
  const positionsForQuery = positionsForQueryBuilder(positions)
  const simType = simTypeMapping(sim_type)

  let treeInfo = JSON.parse(sessionStorage.getItem("treeInfo")) || {}
  treeInfo = { simName: renderSimName(dataStrategySelection) }
  return handleLoadTreeRequest(dataStrategySelection, simType, positionsForQuery, treeInfo)
}

const renderSimName = (dataStrategySelection) => {
  const { stack_size, sim_type, positions, convertedFlopCards } = dataStrategySelection
  return `${stack_size}-${sim_type}-${positionsForQueryBuilder(positions)}-${convertedFlopCards.join('')}`
}

const handleLoadTreeRequest = async ( strategy_selection, simType, positionsForQuery, treeInfo ) => {
  const { stack_size, convertedFlopCards, game_type, specs } = strategy_selection
  const simParams = {
    positions: positionsForQuery,
    sim_type: simType,
    flop_cards: convertedFlopCards.join(''),
    stack_size,
    game_type,
    specs
  }

  try {
    const { data } = await axios.post('/load_tree', simParams);
    const { data: { status, channel_id } } = data;
    if (status === 1) {
      console.log('Successfully loaded tree');
      return storeLoadedTrees({ ...treeInfo, channelId: channel_id });
    } else {
      return status
    }
  } catch (err) {
    notify('Something went wrong on loading data. Please try agains.', toast.error);
    console.log('load tree failed with error: ', err);
  }
}

const storeLoadedTrees = (treeInfo) => {
  if (!isEmpty(treeInfo)) {
    sessionStorage.setItem("treeInfo", JSON.stringify(treeInfo))
    return 'success'
  }
  return 'failed'
}

export const freeTree = async (channelId) => {
  sessionStorage.setItem("treeInfo", null)
  try {
    const res = await axios.get(`/nodes/free_tree?channel_id=${channelId}`)
    const resData = res.data.data
    let textLog = 'Release tree failed'
    if(resData.status === 1) textLog = 'Release tree successfully!'
    console.log(textLog)
    return resData
  } catch (err) {
    console.log('Release tree failed with error: ', err)
  }
}

export const checkExistingChannels = async () => {
  try {
    const res = await axios.get(`/load_tree/existing_channels`);
    return res
  } catch (err) {
    console.log('Release tree failed with error: ', err);
  }
}
