import { handMatrixActions } from '../constants/index';

export const updateSuitsForFilter = (suits) => {
  return {
    type: handMatrixActions.UPDATE_SUITS_FOR_FILTER,
    payload: suits
  }
}

export const updateDecisionFilter = (decision) => {
  return {
    type: handMatrixActions.UPDATE_DECISION_FILTER,
    payload: decision
  }
}

export const changeMatrixView = (view) => {
  return {
    type: handMatrixActions.CHANGE_VIEW,
    payload: view
  }
}

export const changeClickedHandItem = (handItem) => {
  return {
    type: handMatrixActions.CHANGE_CLICKED_HAND_ITEM,
    payload: handItem
  }
}

export const refeshHandMatrix = () => {
  return {
    type: handMatrixActions.REFRESH_HAND_MATRIX
  }
}

export const updateComboWithIndex = (data) => {
  return {
    type: handMatrixActions.UPDATE_COMBO_WITH_INDEX,
    payload: data
  }
}
