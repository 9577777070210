import { odinLoadingActions } from '../constants/index';

const initialState = {
  fetching: '',
  type: '', //progress bar or overlay animate
  speed: 'normal', // loader speed
};

export function odinLoading(state = initialState, action) {
  switch (action.type) {
    case odinLoadingActions.UPDATE_ODIN_LOADING_VALUE:
      return {
        ...state,
        fetching: action.payload.fetchingValue,
        type: action.payload.animationType,
        speed: action.payload.animationSpeed,
      }
    default:
      return state;
  }
}
