import * as yup from 'yup';

export const buildSchema = (locale, translation) => {
  let t = translation

  let emailRules = locale === 'en' ?
    yup.string().trim().required(t('required_field')).email('Must be a valid email') :
    yup.string().trim()
       .required(t('validation.required'))
       .email(t('validation.wrong_format'))

  return yup.object({
    email: emailRules
  })
}
