import { gameActions } from '../constants/index';
import { batch } from 'react-redux'
import axios from 'axios';
import {
  gameDataGenerator,
  setBetLevelBaseOnValue,
  formatTurnStreetData,
  addMoreToGameData,
  positionsForQueryBuilder
} from '../services/game_play_service'
import { updateDecisionFilter, changeMatrixView } from './hand-matrix.actions'
import { addDecisionToTree, updateDecisionsTree } from './decision.actions'
import { isEmpty, pick, isUndefined, isNull } from 'lodash';
import { notify, formatToBigBlind, simTypeMapping, objectWithoutProperties } from 'utils/utils'
import { DISPLAY_IN_BB } from 'config/constants'
import { makeDecisionTurnRound } from 'services/decision_services'
import { updateOdinLoadingShowingValue } from 'actions/odin-loading.actions'
import { updateCardsConvertingTemplate } from 'actions/cards-converting.actions'
import { updateCurrentUserAttributesToReduxTokenAuth } from 'actions/user.actions'
import { convertFlopCards } from 'services/convert_cards_services'
import { loadTreeRequestPTB } from 'services/load_tree_request_service'

export const getLoadTreeInfo = (dataStrategySelection) => {
  return dispatch => {
    return loadTreeRequestPTB(dispatch, dataStrategySelection)
  }
}

export const recieveFlopData = (flopData) => {
  return {
    type: gameActions.RECIEVE_FLOP_DATA,
    payload: flopData
  }
}

export const updateProcessingClickNode = (processingClickNode) => {
  return {
    type: gameActions.UPDATE_PROCESSING_CLICK_NODE,
    payload: processingClickNode
  }
}

export const makeDecision = (dataParams, decision, game) => {
  return dispatch => {
    switch(dataParams.currentRound) {
      case 'turn':
      case 'river':
        return makeDecisionTurnRound(dispatch, dataParams, decision, game)
      default:
        return makeDecisionTurnRound(dispatch, dataParams, decision, game)
        // return makeDecisionFlopRound(dispatch, dataParams, decision)
    }
  }
}

export const initGameData = (strategySelection, dataParams, cardsConvertingTemplate, PTB=false) => {
  return dispatch => {
    // if (!PTB) dispatch(updateOdinLoadingShowingValue('show_odin_loading', 'fast'))
    const positions = positionsForQueryBuilder(strategySelection.positions)
    const reversePosition = positions.split('v').reverse().join('v')
    const simType = simTypeMapping(strategySelection.sim_type)
    let convertedFlopCards = strategySelection.convertedFlopCards || [];
    if(isEmpty(convertedFlopCards))
      convertedFlopCards = convertFlopCards(strategySelection.flop_cards, cardsConvertingTemplate)

    let simParams = {
      sim: `${strategySelection.stack_size}-${simType}-${positions}-${convertedFlopCards.join('')}`,
      sim_reverse: `${strategySelection.stack_size}-${simType}-${reversePosition}-${convertedFlopCards.join('')}`,
      game_type: strategySelection.game_type,
      specs: strategySelection.specs,
      stack_size: strategySelection.stack_size
    }

    let params = {
      nodes: dataParams.nodes,
      request_number: '',
      channel_id: JSON.parse(sessionStorage.getItem("treeInfo")).channelId,
      sim: `${strategySelection.stack_size}-${simType}-${positions}-${strategySelection.convertedFlopCards.join('')}`,
    }

    const requestOne = axios.post('/stacksizes/get_initial_stacksize', simParams)
    const requestTwo =  axios.post('/nodes/show_current_node_v2', params)

    axios.all([requestOne, requestTwo,]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]
      const initialStacksize = responseOne.data.stacksize.initial_stacksize
      const { data: currentFlop, status } = responseTwo.data

      if (['failed', 2].includes(status)) {
        dispatch(updateGameData({ ...strategySelection, shouldReloaded: true, currentNodeFailed: true }))
      } else {
        const formattedFlopData = formatTurnStreetData(currentFlop, dataParams);
        let flops = setBetLevelBaseOnValue({flops: formattedFlopData.flops, nodes: dataParams.nodes})

        dispatch(updateGameData({
          ...strategySelection,
          stackSize: initialStacksize,
          flops: flops,
          ev_ip: formattedFlopData.ev_ip,
          ev_ip2: formattedFlopData.ev_ip2,
          ev_oop: formattedFlopData.ev_oop,
          ev_oop2: formattedFlopData.ev_oop2,
          equity_ip: formattedFlopData.equity_ip,
          equity_oop: formattedFlopData.equity_oop,
          nodes: formattedFlopData.nodes,
          ranges_oop: formattedFlopData.ranges_oop,
          ranges_ip: formattedFlopData.ranges_ip,
          pot_ip: formattedFlopData.pot_ip,
          pot_oop: formattedFlopData.pot_oop,
          pot_main: formattedFlopData.pot_main,
          num_combos_ip: formattedFlopData.num_combos_ip,
          num_combos_oop: formattedFlopData.num_combos_oop,
          potMainDisplay: DISPLAY_IN_BB ? formatToBigBlind(formattedFlopData.pot_main) : formattedFlopData.pot_main
        }))
      }
    })).catch(errors => {
      // react on errors.
    }).finally(() => {
      if (!PTB) {
        dispatch(updateOdinLoadingShowingValue(''))
      }
    })
  }
}

export const updateGameData = (gameData) => {
  return {
    type: gameActions.UPDATE_GAME_DATA,
    payload: gameData
  }
}

export const refeshGameData = () => {
  return {
    type: gameActions.REFRESH_GAME_DATA
  }
}

export const updateTreeInfo = (treeInfo) => {
  return {
    type: gameActions.UPDATE_TREE_INFO,
    payload: treeInfo
  }
}

export const updateFetchTurnRiverSuccess = (value) => {
  return {
    type: gameActions.UPDATE_FETCH_TURN_RIVER_SUCCESS,
    payload: value
  }
}

export const updateStacksize = (payload) => {
  return {
    type: gameActions.FETCH_STACKSIZE,
    payload: payload
  }
}

export const fetchCurrentFlop = (dataParams) => {
  return dispatch => {
    axios.post('/nodes/show_current_node', dataParams).then(res => {
      let currentFlop = res.data.data
      let flops = setBetLevelBaseOnValue({flops: currentFlop.next_flops, nodes: dataParams.nodes})
      dispatch(updateGameData({
        flops: flops,
        ev_ip: currentFlop.ev_ip,
        ev_ip2: currentFlop.ev_ip2,
        ev_oop: currentFlop.ev_oop,
        ev_oop2: currentFlop.ev_oop2,
        equity_ip: currentFlop.equity_ip,
        equity_oop: currentFlop.equity_oop,
        nodes: currentFlop.nodes,
        ranges_oop: currentFlop.ranges_oop,
        ranges_ip: currentFlop.ranges_ip,
        pot_ip: currentFlop.pot_ip,
        pot_oop: currentFlop.pot_oop,
        pot_main: currentFlop.pot_main,
        num_combos_ip: currentFlop.num_combos_ip,
        num_combos_oop: currentFlop.num_combos_oop,
        potMainDisplay: DISPLAY_IN_BB ? formatToBigBlind(currentFlop.pot_main) : currentFlop.pot_main
      }))
    })
  }
}

export const fetchTurnRiverData = (dataParams, decision={}, cancelToken=null, cancelObj=null) => {
  return dispatch => {
    const strategySelection = JSON.parse(sessionStorage.getItem("strategy_selections"))
    if (isNull(strategySelection)) return
    const positions = positionsForQueryBuilder(strategySelection.positions)
    const simType = simTypeMapping(strategySelection.sim_type)
    const newCancelToken = !!cancelToken && !!cancelObj && new cancelToken(function executor(c) { cancelObj['cancel'] = c; })
    let params = {
      nodes: dataParams.nodes,
      request_number: '',
      channel_id: JSON.parse(sessionStorage.getItem("treeInfo")).channelId,
      sim: `${strategySelection.stack_size}-${simType}-${positions}-${strategySelection.convertedFlopCards.join('')}`,
    }
    turnRiverDataRequest(dispatch, params, dataParams, newCancelToken, decision).then(res => {
      const { fetching, playTheBot } = dataParams
      if (isUndefined(playTheBot)) {
        if(res < 4000 && fetching && fetching === 'show_odin_loading') {
          setTimeout(() => dispatch(updateOdinLoadingShowingValue('')), 4000 - res)
        } else {
          dispatch(updateOdinLoadingShowingValue(''))
        }
      }
    })
  }
}

export const fetchRunoutsExplorerData = (request_type, nodes, timeOut=null) => {
  return dispatch => {
    const strategy_selection = JSON.parse(sessionStorage.getItem("strategy_selections"))
    let positions = positionsForQueryBuilder(strategy_selection.positions)
    let simType = simTypeMapping(strategy_selection.sim_type)
    let params = {
      request_number: JSON.parse(sessionStorage.getItem("treeInfo")).requestNumber,
      channel_id: JSON.parse(sessionStorage.getItem("treeInfo")).channelId,
      nodes: nodes,
      request_type: request_type,
      sim: `${strategy_selection.stack_size}-${simType}-${positions}-${strategy_selection.convertedFlopCards.join('')}`
    }

    axios.post('/nodes/get_runouts_explorer_data', params).then(res => {
      let data = JSON.parse(res.data.data)
      if (timeOut) {
        clearTimeout(timeOut)
      }

      if(data.status === 1) {
        dispatch(updateRunoutsExplorerData(pick(data, request_type)))
      } else {
        let emptyData = {};
        emptyData[request_type] = {}
        dispatch(updateRunoutsExplorerData(emptyData));
      }
    }, err => {
      if (timeOut) {
        clearTimeout(timeOut)
      }
    });
  }
}

export const updateRunoutsExplorerData = (data) => {
  return {
    type: gameActions.UPDATE_RUNOUTS_EXPLORER_DATA,
    payload: data
  }
}

export const clearRunoutsExplorerData = () => {
  return {
    type: gameActions.CLEAR_RUNOUTS_EXPLORER_DATA
  }
}

export const updateTurnCard = (turnCard) => {
  return {
    type: gameActions.UPDATE_TURN_CARD,
    payload: turnCard
  }
}

export const updateRiverCard = (riverCard) => {
  return {
    type: gameActions.UPDATE_RIVER_CARD,
    payload: riverCard
  }
}

export const clearCards = () => {
  return {
    type: gameActions.CLEAR_CARDS
  }
}

export const updateComparedDecisions = (decisions) => {
  return {
    type: gameActions.UPDATE_COMPARED_DECISIONS,
    payload: decisions
  }
}

export const fetchDataForTurnRiverRound = (payload) => {
  return {
    type: gameActions.FETCH_DATA_FOR_TURN_RIVER_ROUND,
    payload: payload
  }
}

export const getMoreData = (params) => {
  return dispatch => {
    axios.post('/nodes/more_details_data', params).then(res => {
      let data = res.data.data
      dispatch(updateGameData(data));
    })
  }
}

const turnRiverDataRequest = async (dispatch, params, dataParams, newCancelToken, decision) => {
  try {
    const res = await axios.post('/nodes/get_turn_river_data', params, { cancelToken: newCancelToken });
    const resTime = new Date().getTime() - res.requestStartedAt
    if(resTime < 4000 && dataParams.fetching && dataParams.fetching === 'show_odin_loading') {
      setTimeout(() => {
        if (!['/game-play', '/main-page', '/my-sims'].includes(window.location.pathname)) return
        handleOnSuccessTurnRiverDataRequest(dispatch, res, dataParams, decision)
      }, 4000 - resTime)
    }
    else {
      if (!['/game-play', '/main-page', '/my-sims'].includes(window.location.pathname)) return
      handleOnSuccessTurnRiverDataRequest(dispatch, res, dataParams, decision)
    }
    return resTime
  } catch (err) {
    let gameData = { ...gameDataGenerator(dataParams.game.flops, dataParams.game), fetching: false, shouldReloaded: true }
    dispatch(updateGameData(gameData))
    return err
  }
}

const handleOnSuccessTurnRiverDataRequest = (dispatch, res, dataParams, decision ) => {
  const data = res.data.data
  let shouldShowNotifyEmptyData = false
  let gameData = {}
  if(data.status === 1) {
    const { pot_oop, pot_ip, stackSize } = dataParams
    const endGame = stackSize - pot_oop <= 0 && stackSize - pot_ip <= 0
    const formattedFlopData = formatTurnStreetData(data, objectWithoutProperties(dataParams, ['game']));
    let flops = []
    if (!endGame) {
     flops = setBetLevelBaseOnValue({flops: formattedFlopData.flops, nodes: dataParams.nodes})
    }
    const indexFlopHaveStrategy = flops.findIndex(flop => !!flop.strategy)

    if(indexFlopHaveStrategy === -1 && !endGame) {
      flops = []
      shouldShowNotifyEmptyData = true
    }
    gameData = { ...formattedFlopData, flops: flops, fetching: false, betCompleted: false}
    dispatch(addDecisionToTree(decision))
  } else {
    // should let user try again at here
    gameData = { ...gameDataGenerator(dataParams.game.flops, dataParams.game), fetching: false, shouldReloaded: true }
  }
  if(shouldShowNotifyEmptyData) {
    import('react-toastify').then(reactToastify => {
      notify("The solver uses this line 0%, no hands reach this senario!", reactToastify.toast.info, {time: 3000})
    });
  }
  batch(() => {
    dispatch(updateGameData(gameData))
    if (isUndefined(dataParams.playTheBot)) dispatch(updateDecisionFilter(''))
  })
}

export const dispatchCompareEV = (selectedOptions) => {
  return dispatch => {
    if(selectedOptions.length === 2) {
      batch(() => {
        dispatch(updateComparedDecisions(selectedOptions));
        dispatch(changeMatrixView('compare-ev'));
      })
    } else if(selectedOptions.length === 0) {
      batch(() => {
        dispatch(changeMatrixView(''));
        dispatch(updateComparedDecisions([]));
      })
    }
  }
}

export const handleClickOnViewOptions = (dataParams, gameData) => {
  return dispatch => {
    axios.post('/nodes/next_flops_details_data', dataParams).then(res => {
      let moreData = res.data.data
      let newGameData = addMoreToGameData(moreData, gameData)
      if(dataParams.view.split(",").length <= 1) {
        batch(() =>  {
          dispatch(updateGameData(newGameData))
          dispatch(changeMatrixView(dataParams.view))
          dispatch(updateDecisionFilter(''));
        })
      } else {
        batch(() =>  {
          dispatch(updateGameData(newGameData))
          dispatch(updateDecisionFilter(''));
        })
      }
    })
  }
}

export const handleOnOpeningRunoutsExplorer = (RUNOUTS_EXPLORER_DEFAULT_VIEW, nodes, timer) => {
  return dispatch => {
    batch(() => {
      dispatch(fetchDataForTurnRiverRound({ fetching: 'show_runouts_loading' }));
      dispatch(fetchRunoutsExplorerData(RUNOUTS_EXPLORER_DEFAULT_VIEW, nodes, timer));
    })
  }
}

export const handleOnOpeningNewSim = (cardsConvertingTemplate, dataStrategySelection, currentUser) => {
  return dispatch => {
    batch(() => {
      dispatch(updateCardsConvertingTemplate(cardsConvertingTemplate));
      dispatch(recieveFlopData(dataStrategySelection));
      dispatch(updateCurrentUserAttributesToReduxTokenAuth(currentUser));
    })
  }
}

export const reloadSimProcess = (value) => {
  return dispatch => {
    batch(() => {
      dispatch(updateFetchTurnRiverSuccess(value));
      dispatch(updateOdinLoadingShowingValue(value === 'done' ? '' : 'show_odin_loading'));
    })
  }
}

export const continueGame = (game, decisions) => {
  return dispatch => {
    batch(() => {
      dispatch(updateGameData(game));
      dispatch(updateDecisionsTree(decisions));
    })
  }
}
