import { handMatrixActions } from '../constants/index';

const initialState = {
  suitsForFilter: {
    included: {
      first: '',
      second: ''
    },
    excluded: {
      first: '',
      second: ''
    }
  },
  decisionFilter: '',
  view: '',
  handClickedItem: '',
  combosWithIndex: {}
};

export function handMatrix(state = initialState, action) {
  switch (action.type) {
    case handMatrixActions.UPDATE_SUITS_FOR_FILTER:
      return {
        ...state,
        suitsForFilter: action.payload
      }
    case handMatrixActions.UPDATE_DECISION_FILTER:
      return {
        ...state,
        decisionFilter: action.payload
      }
    case handMatrixActions.CHANGE_VIEW:
      return {
        ...state,
        view: action.payload
      }
    case handMatrixActions.CHANGE_CLICKED_HAND_ITEM:
      return {
        ...state,
        handClickedItem: action.payload
      }
    case handMatrixActions.REFRESH_HAND_MATRIX:
      return {
        ...initialState
      }
    case handMatrixActions.UPDATE_COMBO_WITH_INDEX:
      return {
        ...state,
        combosWithIndex: action.payload
      }
    default:
      return state;
  }
}
