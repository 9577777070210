import {
  CONVERTED_CARDS_ORDERED_BY_RANK_AND_SUIT,
  CARDS_ORDERED_BY_RANK,
  PAIRED_BOARDWAY_1,
  PAIRED_BOARDWAY_2,
  SUIT_PATERN_LIST
} from 'config/constants';
import { orderBy, uniq } from 'lodash'
import { COMBOS } from 'config/constants'

const orderCards = (cards, byRank=false) => {
  const arrCards = byRank ? CARDS_ORDERED_BY_RANK : CONVERTED_CARDS_ORDERED_BY_RANK_AND_SUIT
  cards = cards.map(card => card.replace('10', 'T'));
  let cardsWithOrder = arrCards.filter(card => cards.includes(card.card));
  cardsWithOrder = orderBy(cardsWithOrder, ['order'], ['asc'])

  return cardsWithOrder.map(card => card.card);
}

export const buildConvertSuitsTemplate = (realFlopCards) => {
  let cardsOrderedByRank = orderCards(realFlopCards, true)
  let ranksArr = cardsOrderedByRank.map(card => card[0])
  let suitsArr = cardsOrderedByRank.map(card => card[1])
  let template = {}
  if(isPairedBoardwayRainbow(ranksArr, suitsArr)) {
    template = buildConvertSuitsTemplateForPBR(ranksArr, suitsArr)
  } else {
    if(isTwotonePaired(ranksArr, suitsArr)) {
      if(hasFormatXXZ(ranksArr)) {
        cardsOrderedByRank = orderCardForXXZ(cardsOrderedByRank)
        suitsArr = cardsOrderedByRank.map(card => card[1])
      } else if (hasFormatXZZ(ranksArr)) {
        cardsOrderedByRank = orderCardForXZZ(cardsOrderedByRank)
        suitsArr = cardsOrderedByRank.map(card => card[1])
      }
    }
    const realKeys = uniq(uniq(suitsArr).concat(['c', 'd', 'h', 's']))
    realKeys.forEach((key, idx) => template[key] = ['s', 'd', 'c', 'h'][idx])
  }
  return template
}

export const convertFlopCards = (realFlopCards, template) => {
  let flopCards = []

  orderCards(realFlopCards, true).forEach((card) => {
    flopCards = flopCards.concat([`${card[0]}${template[card[1]]}`])
  })
  return orderCards(flopCards);
}

export const convertTurnRiverCard = (selectedCard, template) => {
  selectedCard = selectedCard.replace('10', 'T')
  return `${selectedCard[0]}${template[selectedCard[1]]}`
}

export const convertHandCards = (realFlopCards, handCards) => {
  const template = buildConvertSuitsTemplate(realFlopCards)
  const convertedCard = `${handCards[0]}${template[handCards[1]]}${handCards[2]}${template[handCards[3]]}`
  const reverseConvertedCard = `${handCards[2]}${template[handCards[3]]}${handCards[0]}${template[handCards[1]]}`
  return COMBOS.includes(convertedCard) ? convertedCard : reverseConvertedCard
}

const isPairedBoardwayRainbow = (ranksArr, suitsArr) => {
  return (PAIRED_BOARDWAY_1.includes(ranksArr.join('')) || PAIRED_BOARDWAY_2.includes(ranksArr.join(''))) && uniq(suitsArr).length === 3
}

const isTwotonePaired = (ranksArr, suitsArr) => {
  return uniq(ranksArr).length === 2 && uniq(suitsArr).length === 2
}

const hasFormatXXZ = (ranksArr) => {
  const first = ranksArr[0]    // first = X
  return ranksArr.filter(rank => rank === first).length === 2   // we found 2 X => it has format XXZ
}

const hasFormatXZZ = (ranksArr) => {
  const first = ranksArr[0]   // first = X
  return ranksArr.filter(rank => rank === first).length === 1   // we found 1 X => it has format XZZ
}

const buildConvertSuitsTemplateForPBR = (ranksArr, suitsArr) => {
  const realKeys = uniq(suitsArr.concat(['c', 'd', 'h', 's']))
  let template = {}
  if(PAIRED_BOARDWAY_1.includes(ranksArr.join(''))) {
    realKeys.forEach((key, idx) => template[key] = ['d', 'c', 's', 'h'][idx])
  } else if(PAIRED_BOARDWAY_2.includes(ranksArr.join(''))) {
    realKeys.forEach((key, idx) => template[key] = ['c', 's', 'd', 'h'][idx])
  }
  return template
}

const orderCardForXXZ = (cardsOrderedByRank) => {
  const lastSuit = cardsOrderedByRank.join('').charAt(5)
  const newCardsOrdered = []
  cardsOrderedByRank.forEach((card, idx) => {
    if(idx < cardsOrderedByRank.length - 1 ) {
      if(card[1] === lastSuit) {
        newCardsOrdered.push({card: card, order: 1})
      } else {
        newCardsOrdered.push({card: card, order: 2})
      }
    } else {
      newCardsOrdered.push({card: card, order: 3})
    }
  })
  return orderBy(newCardsOrdered, ['order'], ['asc']).map(item => item.card)
}

const orderCardForXZZ = (cardsOrderedByRank) => {
  const firstSuit = cardsOrderedByRank.join('').charAt(1)
  const newCardsOrdered = []
  cardsOrderedByRank.forEach((card, idx) => {
    if(idx === 0) {
      newCardsOrdered.push({card: card, order: 1})
    } else {
      if(card[1] === firstSuit) {
        newCardsOrdered.push({card: card, order: 2})
      } else {
        newCardsOrdered.push({card: card, order: 3})
      }
    }
  })
  return orderBy(newCardsOrdered, ['order'], ['asc']).map(item => item.card)
}

export const generateRealCardTemplateListFormConvertedCard = (convertedCard) => {
  let cardsOrderedByRank = orderCards(convertedCard)
  let ranksArr = cardsOrderedByRank.map(card => card[0])
  let suitsArr = cardsOrderedByRank.map(card => card[1])
  let templateList = []
  if(isPairedBoardwayRainbow(ranksArr, suitsArr)) {
    templateList = buildRealSuitsTemplateListForPBR(ranksArr, suitsArr)
  } else {
    const convertedKeys = ['s', 'd', 'c', 'h']
    SUIT_PATERN_LIST.forEach((suitPatern, idx) => {
      let template = {}
      convertedKeys.forEach((key, idx) => template[key] = suitPatern.split('')[idx])
      templateList.push(template)
    })
  }
  return templateList
}

const buildRealSuitsTemplateListForPBR = (ranksArr, suitsArr) => {
  let templateList = []
  let convertedKeys
  if(PAIRED_BOARDWAY_1.includes(ranksArr.join(''))) {
    convertedKeys = ['d', 'c', 's', 'h']
  } else if(PAIRED_BOARDWAY_2.includes(ranksArr.join(''))) {
    convertedKeys = ['c', 's', 'd', 'h']
  }
  SUIT_PATERN_LIST.forEach((suitPatern, idx) => {
    let template = {}
    convertedKeys.forEach((key, idx) => template[key] = suitPatern.split('')[idx])
    templateList.push(template)
  })
  return templateList
}

export const convertToRealFlopCards = (convertedCard, template) => {
  let flopCards = []

  orderCards(convertedCard).forEach((card) => {
    flopCards = flopCards.concat([`${card[0]}${template[card[1]]}`])
  })
  return flopCards
}