import { isEqual } from 'lodash'

export const filterCombosBySuit = (handItem, combos, suitsForFilter) => {
  switch(handItem[2]) {
    case 'o':
      return filterBySuitForOffSuited(combos, suitsForFilter)
    case 's':
      return filterBySuitForSuited(combos, suitsForFilter)
    default:
      return filterBySuitForPair(combos, suitsForFilter)
  }
}

const filterBySuitForOffSuited = (combos, suitsForFilter) => {
  const { included, excluded } = suitsForFilter
  const { first: includedFirst, second: includedSecond } = included
  const { first: excludedFirst, second: excludedSecond } = excluded
  if(!!includedFirst && !!includedSecond && isEqual(includedFirst, includedSecond)) {
    return filterFirstSuit(combos, includedFirst, excludedFirst).concat(filterSecondSuit(combos, includedSecond, excludedSecond)).filter(distinct)
  } else if(!!excludedFirst && !!excluded.second && isEqual(excludedFirst, excludedSecond)) {
    return filterExcluded(combos, excludedFirst)
  } else {
    combos = filterFirstSuit(combos, includedFirst, excludedFirst)
    return filterSecondSuit(combos, includedSecond, excludedSecond)
  }
}

const filterBySuitForSuited = (combos, suitsForFilter) => {
  const { included, excluded } = suitsForFilter
  const { first: includedFirst, second: includedSecond } = included
  const { first: excludedFirst, second: excludedSecond } = excluded
  if(!!includedFirst && !!includedSecond && !isEqual(includedFirst, includedSecond)) {
    return filterFirstSuit(combos, includedFirst, excludedFirst).concat(filterSecondSuit(combos, includedSecond, excludedSecond)).filter(distinct)
  } else if(!!excludedFirst && !!excludedSecond && isEqual(excludedFirst, excludedSecond)) {
    return filterExcluded(combos, excludedFirst)
  } else {
    combos = filterFirstSuit(combos, includedFirst, excludedFirst)
    return filterSecondSuit(combos, includedSecond, excludedSecond)
  }
}

const filterBySuitForPair = (combos, suitsForFilter) => {
  const { included, excluded } = suitsForFilter
  const { first: includedFirst, second: includedSecond } = included
  const { first: excludedFirst, second: excludedSecond } = excluded
  combos = includedFirst ? combos.filter(combo => combo.includes(includedFirst )) : combos
  combos = includedSecond ? combos.filter(combo => combo.includes(includedSecond )) : combos
  combos = excludedFirst ? combos.filter(combo => !isEqual(combo[1], excludedFirst) && !isEqual(combo[3], excludedFirst)) : combos
  combos = excludedSecond ? combos.filter(combo => !isEqual(combo[1], excludedSecond) && !isEqual(combo[3], excludedSecond)) : combos
  return combos
}

const filterFirstSuit = (combos, includedSuit, excludedSuit) => {
  combos = !!includedSuit ? combos.filter(combo => isEqual(combo[1], includedSuit)) : combos
  combos = !!excludedSuit ? combos.filter(combo => !isEqual(combo[1], excludedSuit)) : combos
  return combos
}

const filterSecondSuit = (combos, includedSuit, excludedSuit) => {
  combos = !!includedSuit ? combos.filter(combo => isEqual(combo[3], includedSuit)) : combos
  combos = !!excludedSuit ? combos.filter(combo => !isEqual(combo[3], excludedSuit)) : combos
  return combos
}

const filterExcluded = (combos, excludedSuit) => {
  return combos.filter(combo => !isEqual(combo[1], excludedSuit) && !isEqual(combo[3], excludedSuit))
}

const distinct = (value, index, self) => {
  return isEqual(self.indexOf(value), index)
}
