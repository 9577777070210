import React, {Component} from 'react'
import './stylesheet.scss'
import PropTypes from 'prop-types'
import {buildSchema} from './validations'
import {Formik} from 'formik';
import { Form } from 'react-bootstrap'
import LaddaButton from '../LaddaButton/component'
import { connect } from 'react-redux'
import {withRouter} from 'react-router'
import OdinLogo from '../OdinLogo/component'
import { signInUser } from 'actions/redux-token-auth'
import FormField from 'components/Shared/FormField/FormField'
import axios from 'axios'
import queryString from 'query-string'
import { toast } from 'react-toastify';
import { notify } from 'utils/utils'


class CResetPassword extends Component {

  privateForm = formikProps => {
    const t = this.context.t

    let {
      handleSubmit,
      handleChange,
      isSubmitting,
      errors,
      values,
      touched,
      handleBlur
    } = {...formikProps}

    return (
      <Form
        noValidate
        className='fadeIn'
        onSubmit={handleSubmit}>
        <FormField
          field={'password'}
          fieldType={'password'}
          autocomplete='off'
          error={touched.password && errors.password}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          values={values}
          isRequired={true}
          onBlur={handleBlur}
        />
        <FormField
          field={'confirm_password'}
          fieldType={'password'}
          autocomplete='off'
          classNames='mb-0'
          error={touched.confirm_password && errors.confirm_password}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          values={values}
          isRequired={true}
          onBlur={handleBlur}
        />
        <Form.Group>
          <LaddaButton
            className='w-100 submit py-2 btn-primary font-size-18 font-weight-600 mt-5'
            type='submit'
            text={t('save_change')}/>
        </Form.Group>
      </Form>
    )
  }

  queryParams(){
    return queryString.parse(this.props.location.search)
  }

  onSubmit(user, actions){
    let _this = this
    let queryParams = this.queryParams()
    let config = {
      headers: {
        'access-token': queryParams['access-token'],
        'client': queryParams.client,
        'uid': queryParams.uid
      }
    }
    const publicAxios = axios.create();
    publicAxios.put('/auth/password', {
      password: user.password,
      password_confirmation: user.confirm_password,
    }, config).then((res) => {
      notify("Your password has been updated successfully", toast.success, {time: 3000})
      _this.props.signInUser({email: res.data.data.email, password: user.password}).then((res) => {
        _this.props.history.push('/strategy-select')
      })
    }).catch((error) => {
      notify("Failed to update your password. Please try again", toast.error, {time: 3000})
    })
  }

  render(){
    let t = this.context.t
    let schemaValidation = buildSchema(this.props.i18nState.lang, t)
    let initialValues = {
      password: '',
      confirm_password: ''
    }

    return (
      <div className='auth-screen reset-password'>
        <div className='form-wrappper bounceInDown sign-in'>
          <div className='form'>
            <div className='text-center w-100 d-flex align-items-center justify-content-center'>
              <OdinLogo 
                direct="#"/>
            </div>
            <Formik
              component={this.privateForm}
              onSubmit={this.onSubmit.bind(this)}
              validationSchema={schemaValidation}
              initialValues={initialValues}/>
          </div>
        </div>
      </div>

    )
  }
}

CResetPassword.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  userData: store.userData,
})

const mapDispatchToProps = {
  signInUser,
}

const ResetPassword = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CResetPassword)

export default withRouter(ResetPassword)
