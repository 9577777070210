import { subscriptionActions } from '../constants/index';

const initialState = {
  plans: [],
  popUp: '',
  code: ''
};

export function subscriptions(state = initialState, action) {

  switch (action.type) {
    case subscriptionActions.FETCH_PLANS_SUCCEEDED:
      return {
        ...state,
        plans: action.payload
      }
    case subscriptionActions.TOGGLE_POPUP:
      return {
        ...state,
        popUp: action.payload,
      }
    case subscriptionActions.DISCOUNT:
      return {
        ...state,
        code: action.payload,
      }
    default:
      return state;
  }
}
