import React , { Component } from 'react'
import './stylesheet.scss'
import OdinPokerCodeLogo from '../../images/Logos/odin_pokercode_logo.png'
import OdinUserLogo from '../../images/Logos/odin_user_logo.png'
import { Link } from 'react-router-dom';
import {connect} from 'react-redux'
import { withRouter } from 'react-router'
import { EMAILS_ABLE_TO_VIEW_BLACK_DESIGN } from 'config/constants'

class CLogo extends Component {
  render() {
    let { role, email } = this.props.currentUser;
    let showPokercodeLogo = role === 'pokercode_user' || EMAILS_ABLE_TO_VIEW_BLACK_DESIGN.includes(email);

    return (
      <Link
        to={this.props.direct || '/'}
        className={`odin-logo ${this.props.className}`}
      >
        {
          showPokercodeLogo ?
            <img alt='logo' className='odin-pokercode-image ' src={OdinPokerCodeLogo}/>:
            <img alt='logo' className='odin-image' src={OdinUserLogo}/>
        }
      </Link>
    )
  }
}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser.attributes
})

const mapDispatchToProps = {}

const Logo = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CLogo)

export default withRouter(Logo)
