import React from 'react'
import loadable from '@loadable/component'
import Loading from '../../components/Custom/Loading.jsx'
const MainPage = loadable(() => import('components/MainPage/component'), {
 fallback: <Loading />,
})
const StrategySelect = loadable(() => import('components/StrategySelectionPage/StrategySelect/component'), {
 fallback: <Loading />,
})
const MySim = loadable(() => import('components/MySim/component'), {
 fallback: <Loading />,
})
const PreflopPage = loadable(() => import('components/PreflopPage/PreflopPage.jsx'), {
 fallback: <Loading />,
})
const FreePostflopSims = loadable(() => import('components/FreePostflopSims/FreePostflopSims'), {
 fallback: <Loading />,
})
const MyAccount = loadable(() => import('components/MyAccount/component'), {
 fallback: <Loading />,
})
const AdminPortal = loadable(() => import('components/AdminPortal/AdminPortal'), {
 fallback: <Loading />,
})
const SubscriptionPage = loadable(() => import('components/SubscriptionPage/component'), {
 fallback: <Loading />,
})
const PlayTheBot = loadable(() => import('components/PlayTheBot/components/PlayTheBotIndex'), {
 fallback: <Loading />,
})
const Result = loadable(() => import('components/PlayTheBot/components/Result/ResultIndex'), {
 fallback: <Loading />,
})
const SessionResults = loadable(() => import('components/PlayTheBot/components/SessionResults/component'), {
  fallback: <Loading />,
})
const CancelSub = loadable(() => import('components/MyAccount/SubscriptionsPage/CancelSubscriptionPage'), {
  fallback: <Loading />,
})

export default {
  MainPage: {
    component: MainPage,
    path: '/main-page'
  },
  StrategySelect: {
    component: StrategySelect,
    path: '/strategy-select'
  },
  MySim: {
    component: MySim,
    path: '/my-sims'
  },
  PreflopPage: {
    component: PreflopPage,
    path: '/preflop-page'
  },
  FreePostflopPage: {
    component: FreePostflopSims,
    path: '/free-postflop-page'
  },
  MyAccount: {
    component: MyAccount,
    path: '/my-account'
  },
  PlayTheBot: {
    component: PlayTheBot,
    path: '/trainer-selection'
  },
  AdminPortal: {
    component: AdminPortal,
    path: '/admin'
  },
  SubscriptionPage: {
    component: SubscriptionPage,
    path: ['/subscribe', '/Pokercode/subscribe']
  },
  Result: {
    component: Result,
    path: '/game-play'
  },
  SessionResults: {
    component: SessionResults,
    path: '/session-results'
  },
  CancelSub: {
    component: CancelSub,
    path: '/cancel'
  }
};
