import { cardsConvertingActions } from '../constants/index';

const initialState = { cardsConvertingTemplate: {} };

export function cardsConverting(state = initialState, action) {
  switch (action.type) {
    case cardsConvertingActions.UPDATE_CARDS_CONVERTING_TEMPLATE:
      return {
        ...state,
        cardsConvertingTemplate: action.payload
      }
    default:
      return state;
  }
}