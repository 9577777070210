import React, {Component, Fragment} from 'react'
import './AdsBanner.scss'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import styled from 'styled-components';
import mainBackgroundDesktop from '../../../images/ads-banner/main-background-desktop.png'
import mainBackgroundTablet from '../../../images/ads-banner/main-background-tablet.png'
import mainBackgroundTabletXl from '../../../images/ads-banner/main-background-tablet-xl.png'
import mainBackgroundMobile from '../../../images/ads-banner/main-background-mobile.png'

import mainImageDesktop from '../../../images/ads-banner/main-image-desktop.png'
import mainImageTablet from '../../../images/ads-banner/main-image-tablet.png'
import mainImageMobile from '../../../images/ads-banner/main-image-mobile.png'

import prometheusLogo from '../../../images/ads-banner/prometheus-logo.svg'
import googleImg from '../../../images/ads-banner/Google.svg'
import appleImg from '../../../images/ads-banner/Apple.svg'
import closeBannerIcon from '../../../images/ads-banner/close-banner-icon.svg'

import { userSubscriptionInvalid } from 'utils/utils'

const Container = styled.div`
  height: 120px;
  width: 100%;
  background-color: #1C102E;

  @media (max-width: 1024px) {
    height: 146px;
  }

  @media (max-width: 768px) {
    height: 146px;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(${props => props.mainBackgroundUrl});

  @media (max-width: 1024px) {
    background-image: url(${props => props.mainBackgroundTabletXl});
  }

  @media (max-width: 768px) {
    background-image: url(${props => props.mainBackgroundTablet});
  }

  @media (max-width: 500px) {
    background-image: url(${props => props.mainBackgroundTablet});
    background-position: right;
  }
`

const Wrapper = styled.div`
  max-width: 1800px;
  margin: auto;
  height: 100%;
  display: flex;
`

const MainImage = styled.div`
  width: 350px;
  height: 100%;
  background-image: url(${props => props.mainImageDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 10px;

  @media (min-width: 1800px) {
    margin-left: unset;
  }

  @media (max-width: 1024px) {
    width: 350px;
    margin-left: 5px;
    background-image: url(${props => props.mainImageTablet});
  }

  @media (max-width: 768px) {
    width: 170px;
    margin-left: unset;
    background-image: url(${props => props.mainImageMobile});
  }

  @media (max-width: 500px) {
    width:170px;
    margin-left: unset;
    background-image: url(${props => props.mainImageMobile});
  }
`

const MainContent = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .prometheus-section-mobile {
    width: 0px;
    height: 0px;
  }
  @media (max-width: 1024px) {
    margin: 0px 20px;
    max-width: 400px;
  }

  @media (max-width: 768px) {
    margin: 0px 10px;
    max-width: 400px;
  }

  @media (max-width: 500px) {
    margin: 0px 5px;
    max-width: 200px;
    .install-app {
      display: none;
    }
    .prometheus-section-mobile {
      display: block;
      width: 82px;
      height: auto
    }
  }

  @media (max-width: 375px) {
    margin: 0px 0px;
  }
`

const MainText = styled.p`
  font-family: 'Futura';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 0px;

  @media (max-width: 1366px) {
    font-size: 34px;
  }

  @media (max-width: 1200px) {
    font-size: 30px;
  }

  @media (max-width: 1024px) {
    font-size: 33px;
  }

  @media (max-width: 900px) {
    font-size: 26px;
    width: 260px;
  }

  @media (max-width: 500px) {
    font-size: 18px;
    width: 190px;
  }
`

const CTAButton = styled.button`
  box-sizing: border-box;
  width: 226px;
  height: 40px;
  background: rgba(231, 0, 42, 0.9);
  border: 1.03304px solid #FF0000;
  box-shadow: 0px 3.44713px 13.7885px rgba(238, 34, 61, 0.8);
  border-radius: 4.30891px;
  display: flex;
  justify-content: center;
  align-items: center;

  
  @media (max-width: 1024px) {
    width: 160px;
    height: 34px;
  }
`

const CTAText = styled.span`
  font-family: 'Futura';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #FFFFFF;

  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 15px;
  }
`


class AdsBanner extends Component {

  handleClickOnCTA(e) {
    const { currentUser } = this.props
    const { isSignedIn } = currentUser

    if(!isSignedIn) {
      this.props.history.push('/signup')
    }
  }

  openMarketingLink(e) {
    window.open("https://social.prometheuspoker.com/a/odin", "_blank")
  }

  render(){
    const { location: { pathname: path }, currentUser } = this.props
    const { attributes: user, isSignedIn } = currentUser
    const inValid = userSubscriptionInvalid(user)

    if (['/signin', '/signup'].includes(path)){
      return <Fragment />
    }
  
    return <div className='ads-banner-wrapper'>
      <Container onClick={this.openMarketingLink.bind(this)}>
        <Background
          mainBackgroundUrl={mainBackgroundDesktop}
          mainBackgroundTablet={mainBackgroundTablet}
          mainBackgroundTabletXl={mainBackgroundTabletXl}
          mainBackgroundMobile={mainBackgroundMobile}>
          <Wrapper>
            <MainImage
              mainImageDesktop={mainImageDesktop}
              mainImageTablet={mainImageTablet}
              mainImageMobile={mainImageMobile}
            />
            <MainContent>
              <img src={prometheusLogo} alt='prometheus-flames-mobile' className='prometheus-section-mobile'/>
              <MainText>CHECK OUT OUR NEW MOBILE BATTLE APP!</MainText>
              <div className='d-flex mt-2'>
                <CTAButton className='mr-2' onClick={this.handleClickOnCTA.bind(this)}><CTAText>Play for FREE</CTAText></CTAButton>
                <img src={appleImg} alt='install-app-store' className='mr-2 install-app'/>
                <img src={googleImg} alt='install-google-apk' className='install-app'/>
              </div>
            </MainContent>
          </Wrapper>
          <div className='prometheus-section'>
            <img src={prometheusLogo} alt='prometheus-flames'/>
          </div>
        </Background>
      </Container>
      <div className='right-section'>
        {((isSignedIn && !inValid)) &&
          <div className='close-banner-icon'
            onClick={this.props.closeAdsBanner}>
            <img src={closeBannerIcon} alt='close-banner-icon'/>
          </div>
        }
      </div>
    </div>
      
  }

}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser
})

const mapDispatchToProps = {

}

export default withRouter(
  connect(mapStoreToProps, mapDispatchToProps)(AdsBanner)
)
