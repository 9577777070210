import * as yup from 'yup';

export const buildSchema = (locale, translation) => {
  let t = translation
  let passwordRules = locale === 'en' ?
  yup.string()
    .required(t('required_field'))
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('validations.password.sign_up_rules')
      ):
  yup.string()
    .required(t('validation.required'))
    .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('validations.password.sign_up_rules')
      )

  let confirmPasswordRules = yup.string().oneOf([yup.ref('password'), null], "Passwords don't match")
                              .required(t('required_field'))
                              .matches(
                                /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                t('validations.password.sign_up_rules')
                              )

  return yup.object({
    password: passwordRules,
    confirm_password: confirmPasswordRules,
  })
}