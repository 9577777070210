import { userActions } from '../constants/index';

export const updateCurrentUserAttributesToReduxTokenAuth = (currentUser) => {
  return {
    type: userActions.UPDATE_CURRENT_USER_ATTRIBUTES_TO_REDUX_TOKEN_AUTH,
    payload: {
      userAttributes: currentUser ? {
        email: currentUser.email,
        strategy_selection: currentUser.strategy_selection,
        user_strategy_selection: currentUser.user_strategy_selection,
        alt_subscription_id: currentUser.alt_subscription_id,
        alt_plan: currentUser.alt_plan,
        preferences: currentUser.preferences,
        id: currentUser.id,
        role: currentUser.role,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        birthday: currentUser.birthday,
        country: currentUser.country,
        subscription: currentUser.subscription,
        universal_note: currentUser.universal_note,
        emailConfirmed: currentUser.email_confirmed || currentUser.emailConfirmed,
        ip: currentUser.last_ip || '',
        sims: currentUser.sims,
        user_type: currentUser.user_type,
        accessable_game_type: currentUser.accessable_game_type,
        admin_access: currentUser.admin_access,
        affiliate_code: currentUser.affiliate_code,
        full_access: currentUser.full_access,
        createdAt: currentUser.createdAt,
        allowAutoRenew: currentUser.allowAutoRenew
      } : {}
    }
  }
}

export const storeUserData = (userData) => {
  return {
    type: userActions.STORE_USER_DATA,
    payload: userData
  }
}

export const clearUserData = () => {
  return {
    type: userActions.CLEAR_USER_DATA,
  }
}

export const storeValidationMessages = (messages) => {
  return {
    type: userActions.STORE_VALIDATION_MESSAGES,
    payload: messages
  }
}
