import axios from 'axios';
import { HeaderService } from '../services/auth_service'
import Response from './response'
const AUTH_HEADER_KEYS = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid',
]

// Config defaults
//----------------------------
// Config baseURL
axios.defaults.baseURL = process.env.REACT_APP_API_URL
// Config authentication info
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const service = new HeaderService()
// Request interceptor
axios.interceptors.request.use(config => {
  config.meta = config.meta || {}
  config.meta.requestStartedAt = new Date().getTime()
  service.setAuthHeaders(config)
  return config
})
// Response interceptor
axios.interceptors.response.use( response => {
  service.persistAuthHeadersInLocalStorage(response.headers)
  console.log(`Execution time for: ${response.config.url} -> ${ new Date().getTime() - response.config.meta.requestStartedAt} ms`)
  return new Response({
          data: response.data,
          headers: response.headers,
          status: response.status,
          pages: response.headers['x-pagination-total-pages'],
          page: response.headers['x-pagination-page'],
          sorting: response.headers['x-sorting'],
          pageSize: response.headers['x-pagination-per-page'],
          requestStartedAt: response.config.meta.requestStartedAt
        });
}, error => {
  const { response } = error
  if ( response && response.status && response.status === 401) {
    AUTH_HEADER_KEYS.forEach( key => localStorage.setItem(key, ''))
    window.location.reload()
    return new Response({data: {}})
  }
})

// Public axios
//----------------------------
window.axios = axios
export default axios
