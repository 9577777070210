import axios from 'axios'
import { batch } from 'react-redux'
import { decisionActions } from '../constants/index';
import {
  gameDataGenerator,
  formatTurnStreetData,
  setBetLevelBaseOnValue,
  positionsForQueryBuilder
} from '../services/game_play_service'
import { updateDecisionFilter } from './hand-matrix.actions'
import { updateGameData, fetchDataForTurnRiverRound } from './game.actions'
import { objectWithoutProperties, simTypeMapping } from 'utils/utils'

export const addDecisionToTree = (decision) => {
  return {
    type: decisionActions.ADD_DECISION,
    payload: decision
  }
}

export const updateDecisionsTree = (decisions) => {
  return {
    type: decisionActions.UPDATE_DECISIONS_TREE,
    payload: decisions
  }
}

export const refreshDecisionsTree = () => {
  return {
    type: decisionActions.REFRESH_DECITIONS_TREE
  }
}

export const rollbackDecision = (dataParams, decisions) => {
  switch(dataParams.currentRound) {
    case 'flop':
      return rollbackDecisionForFlopRound(dataParams, decisions)
    case 'turn':
      return rollbackDecisionForTurnOrRiverRound(dataParams, decisions, 'turn')
    case 'river':
      return rollbackDecisionForTurnOrRiverRound(dataParams, decisions, 'river')
    default:
      return rollbackDecisionForFlopRound(dataParams, decisions)
  }
}

const rollbackDecisionForFlopRound = (dataParams, decisions) => {
  return dispatch => {
    // APP-FLOW-V2
    const strategy_selection = JSON.parse(sessionStorage.getItem("strategy_selections"))
    const positions = positionsForQueryBuilder(strategy_selection.positions)
    const simType = simTypeMapping(strategy_selection.sim_type)
    const params = {
      nodes: dataParams.nodes,
      request_number: '',
      channel_id: JSON.parse(sessionStorage.getItem("treeInfo")).channelId,
      sim: `${strategy_selection.stack_size}-${simType}-${positions}-${strategy_selection.convertedFlopCards.join('')}`,
    }
    let gameData = {}
    axios.post('/nodes/show_current_node_v2', params).then(res => {
      let data = res.data.data
      // currentFlop['currentPosition'] = dataParams.currentPosition
      // currentFlop['betCompleted'] = dataParams.betCompleted || false
      // currentFlop['currentRound'] = 'flop'
      // currentFlop['turnCard'] = ''
      // currentFlop['riverCard'] = ''
      // currentFlop['potMainDisplay'] = dataParams.potMainDisplay
      // currentFlop['bettedPot'] = {}
      // batch(() => {
      //   dispatch(updateGameData(gameDataGenerator(currentFlop.next_flops, currentFlop)))
      //   dispatch(updateDecisionsTree(decisions))
      //   dispatch(updateDecisionFilter(''))
      // })

      let formattedFlopData = formatTurnStreetData(data, dataParams);
      formattedFlopData['currentPosition'] = dataParams.currentPosition
      formattedFlopData['betCompleted'] = dataParams.betCompleted || false
      formattedFlopData['currentRound'] = 'flop'
      formattedFlopData['turnCard'] = ''
      formattedFlopData['riverCard'] = ''
      formattedFlopData['potMainDisplay'] = dataParams.potMainDisplay
      formattedFlopData['bettedPot'] = {}
      let flops = setBetLevelBaseOnValue({flops: formattedFlopData.flops, nodes: dataParams.nodes})
      formattedFlopData = objectWithoutProperties(formattedFlopData, ['id', 'action_display', 'action', 'bet_level', 'sort_order', 'position', 'order_num', 'action_value', 'active'])
      gameData = { ...formattedFlopData, flops: flops, fetching: false}
      batch(() => {
        dispatch(updateGameData(gameData))
        dispatch(updateDecisionsTree(decisions))
        dispatch(updateDecisionFilter(''))
      })
    })
  }
}

const rollbackDecisionForTurnOrRiverRound = (dataParams, decisions, currenRound) => {
  return dispatch => {
    let gameData = {}
    if(!dataParams.betCompleted) {
      dispatch(fetchDataForTurnRiverRound({fetching: true}))
      const strategy_selection = JSON.parse(sessionStorage.getItem("strategy_selections"))
      const positions = positionsForQueryBuilder(strategy_selection.positions)
      const simType = simTypeMapping(strategy_selection.sim_type)
      let params = {
        nodes: dataParams.nodes,
        request_number: JSON.parse(sessionStorage.getItem("treeInfo")).requestNumber,
        channel_id: JSON.parse(sessionStorage.getItem("treeInfo")).channelId,
        sim: `${strategy_selection.stack_size}-${simType}-${positions}-${strategy_selection.convertedFlopCards.join('')}`,
      }
      axios.post('/nodes/get_turn_river_data', params).then(res => {
        let data = res.data.data
        if(data.status === 1) {
          let formattedFlopData = formatTurnStreetData(data, dataParams);
          formattedFlopData['currentPosition'] = dataParams.currentPosition
          formattedFlopData['betCompleted'] = dataParams.betCompleted || false
          formattedFlopData['currentRound'] = currenRound
          if(currenRound === 'turn') formattedFlopData['riverCard'] = ''
          formattedFlopData['potMainDisplay'] = dataParams.potMainDisplay
          formattedFlopData['bettedPot'] = dataParams.bettedPot || {}
          let flops = setBetLevelBaseOnValue({flops: formattedFlopData.flops, nodes: dataParams.nodes})
          formattedFlopData = objectWithoutProperties(formattedFlopData, ['id', 'action_display', 'action', 'bet_level', 'sort_order', 'position', 'order_num', 'action_value', 'active'])
          gameData = { ...formattedFlopData, flops: flops, fetching: false}
          batch(() => {
            dispatch(updateGameData(gameData))
            dispatch(updateDecisionsTree(decisions))
          })
        } else {
          gameData = { ...gameDataGenerator(dataParams.game.flops, dataParams.game), fetching: false, shouldReloaded: true }
          dispatch(updateGameData(gameData))
        }
      })
    } else {
      if(currenRound === 'turn') dataParams['riverCard'] = ''
      gameData = {...gameDataGenerator([], dataParams), fetching: false}
      batch(() => {
        dispatch(updateGameData(gameData))
        dispatch(updateDecisionsTree(decisions))
      })
    }
  }
}
