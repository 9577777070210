const initialState = [];

export function roles(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_ROLES_FULFILLED':
      return action.payload
    default:
      return state;
  }
}
