import { preflopGameActions } from '../constants/index';

const initialState = {
  antes: 0,
  currentPosition: '',
  node: '',
  action_options: [],
  action_ranges: {},
  trackedData: initialTrackedData(),
  totalPot: 0,
  biggestBet: 0,
  game_ended: false,
  history: [],
};

export function preflopGame(state = initialState, action) {
  switch (action.type) {
    case preflopGameActions.UPDATE_PREFLOP_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case preflopGameActions.UPDATE_INITIAL_PREFLOP_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case preflopGameActions.RESET_PREFLOP_GAME:
      return {
        ...initialState,
        ...action.payload,
        action_options: state.action_options,
        action_ranges: state.action_ranges,
      }
    case preflopGameActions.RESET_TO_INITIAL_PREFLOP_DATA:
      return initialState;
    default:
      return state;
  }
}

function initialTrackedData() {
  let trackedData = {}
  let positions = ['b1', 'b2', 'b3', 'b4', 'b5', 'b', 'sb', 'bb'];

  positions.forEach(position => {
    trackedData[position] = {
      lastAction: '',
      bet: 0,
      remaining: 0,
      initialStack: 0,
      heightPercentages: {},
    };
  })

  return trackedData;
}
