import React, {Component} from 'react'
import './stylesheet.scss'
import PropTypes from 'prop-types'
import { buildSchema } from './validations'
import { Link } from 'react-router-dom'
import { Formik } from 'formik';
import { Form } from 'react-bootstrap'
import LaddaButton from '../LaddaButton/component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import OdinLogo from '../OdinLogo/component'
import { storeUserData, clearUserData } from 'actions/user.actions'
import axios from 'axios'
import { toast } from 'react-toastify'
import { notify } from 'utils/utils'
import queryString from 'query-string'

class CForgotPassword extends Component {

  componentDidMount() {
    const queryParams = this.queryParams()
    const t = this.context.t
    if(queryParams['status'] && queryParams['status'] === 'expired') {
      notify(t('forgot.link_expired'), toast.error)
    }
  }

  queryParams(){
    return queryString.parse(this.props.location.search)
  }

  privateForm = formikProps => {
    const t = this.context.t
    const {
      handleSubmit,
      handleChange,
      errors,
      values
    } = {...formikProps}

    return <Form
      noValidate
      className='fadeIn'
      onSubmit={handleSubmit}>
      <Form.Group controlId="forgot-password-form-email">
        <Form.Label>{t('email')}<span className='text-red'> *</span></Form.Label>
        <Form.Control
          isInvalid={!!errors.email}
          autoFocus
          onChange={handleChange}
          defaultValue={values.email}
          name='email'
          type="text"
          placeholder={t('email_placeholder')} />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <LaddaButton
          className='w-100 submit py-2 btn-primary font-size-18 font-weight-600'
          type='submit'
          text='Submit'/>
      </Form.Group>
    </Form>
  }

  onSubmit(user, actions){
    const _this = this
    const t = this.context.t
    this.props.storeUserData({ email: user.email })
    axios.post('/auth/password', {
      email: user.email,
      redirect_url: window.location.origin + '/reset-password'
    }).then((res) => {
      notify(t('forgot.notify_email_reset'), toast.success, {time: 3000})
      setTimeout(() => {
        _this.props.history.push('/')
      }, 3000)
    }).catch((error) => {
      if(error.response.status === 404) {
        notify(t('forgot.notify_error'), toast.error, {time: 3000})
      }
    })
  }

  render(){
    const t = this.context.t
    const schemaValidation = buildSchema(this.props.i18nState.lang, t)
    const initialValues = {
      email: '',
      ...this.props.userData
    }

    return (
      <div className='auth-screen forgot-password'>
        <div className='form-wrappper bounceInDown sign-in'>
          <div className='form'>
            <div className='text-center w-100 d-flex align-items-center justify-content-center'>
              <OdinLogo
                direct='#'/>
            </div>
            <Formik
              component={this.privateForm}
              onSubmit={this.onSubmit.bind(this)}
              validationSchema={schemaValidation}
              initialValues={initialValues}/>
            <div className='footer-sign-up-form'>
              <div className='pt-4 d-flex align-items-center justify-content-center'>
                {t('not_a_member_yet')}&nbsp;
                <Link to='/signup' className='text-blue' tabIndex="-1"><b>{t('signup')}</b></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

CForgotPassword.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  userData: store.userData,
})

const mapDispatchToProps = {
  storeUserData,
  clearUserData,
}

const ForgotPassword = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CForgotPassword)

export default withRouter(ForgotPassword)
