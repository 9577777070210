import { TONES, HIGH, MEDIUM, LOW, FULL_CARDS } from 'config/constants'
import { sample, range, isEmpty, filter, includes, xor, sampleSize } from 'lodash'

export const generateCards = (card) => {
  switch (card) {
    case 'high':
      return [...HIGH]
    case 'mid':
      return [...MEDIUM]
    default:
      return [...LOW]
  }
}

const suitRandom = () => sample(['rainbow', 'flush_draw', 'monotone'])

const getToneFromCardDup = (cards, itemDup) => {
  const item = filter(cards, (val, i) => val.slice(0, -1) !== itemDup)[0]
  return item.slice(-1)
}

const randomTone = (tonesClone) => {
  const tone = sample(tonesClone)
  tonesClone.splice(tonesClone.findIndex(i => i === tone), 1)
  return tone
}

const randomCard = (allCards) => {
  const card = sample(allCards)
  allCards.splice(allCards.findIndex(i => i === card), 1)
  return card
}

export const renderRainbowCards = (allCards) => {
  const tonesClone = [...TONES]
  console.log(`rain bow`)
  let cards = []
  allCards.forEach(card => {
    const tone = randomTone(tonesClone)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderFlushDrawCards = (allCards) => {
  const tonesClone = [...TONES]
  const tonesExists = []
  const cards = []
  console.log('flush draw')
  const itemDup = filter(allCards, (val, i, iteratee) => includes(iteratee, val, i + 1))[0] || ''
  allCards.forEach((card, index) => {
    let tone = sample(tonesClone)
    if (index === 2) {
      tone = getToneFromCardDup(cards, itemDup)
    } else {
      tone = randomTone(tonesClone)
      tonesExists.push(tone)
    }
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderMonotoneCards = (allCards) => {
  const tone = sample(TONES)
  const cards = []
  console.log('monotone')
  allCards.forEach((card) => {
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderNotPairedCards = (typeCard, suit, typeConnect) => {
  const allCards = generateCards(typeCard)
  let cards = []
  console.log('not paired')
  if (typeConnect === 'connected') {
    cards = renderConnectedCards(allCards)
  } else {
    range(0,3,1).forEach(() => {
      const card = randomCard(allCards)
      console.log(`${card}`)
      cards.push(card)
    })
  }
  return generateFlopCards(suit, cards)
}

export const renderPairedCards = (typeCard, suit, typeConnect) => {
  const allCards = generateCards(typeCard)
  const cardExists = []
  let cards = []
  console.log('paired')
  if ( ['high', 'mid'].includes(typeCard) && typeConnect === 'connected' ) {
    cards = allCards
  } else if (typeConnect === 'connected' && typeCard === 'low') {
    cards = renderConnectedCards(allCards)
  } else {
    range(0,3,1).forEach((item) => {
      let card = sample(allCards)
      if (item === 2) {
        card = sample(cardExists)
      } else {
        cardExists.push(card)
        allCards.splice(allCards.findIndex(i => i === card), 1)
      }
      console.log(`${card}`)
      cards.push(`${card}`)
    })
  }
  return generateFlopCards(suit, cards)
}

export const renderTripsCard = (typeCard, suit, typeConnect) => {
  console.log('trips')
  const allCards = generateCards(typeCard)
  const card = sample(allCards)
  return generateFlopCards(suit, [card, card, card])
}

export const renderCards = (typeCard, suit, typeConnect) => {
  console.log('cards')
  const allCards = generateCards(typeCard)
  let cards = allCards
  if (typeCard === 'low') {
    cards = renderCardConnnectedness(typeConnect, allCards)
  }
  return generateFlopCards(suit, cards)
}

export const renderMediumCards = (allCards) => {
  const cards = []
  let card = sample(allCards)
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  console.log('medium')
  range(0,3,1).forEach((item) => {
    if (item > 0) {
      if (idx === 0) {
        number = item === 1 ? 1 : sample(range(3, 5, 1))
      } else if (idx > 0 && idx < 3) {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          number = sample(range(index + 2, index + 4, 1))
        }
      } else if (idx === 6) {
        number = item === 1 ? 5 : sample(range(2, 4, 1))
      } else {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          const indexPre = index - 4 < 0 ? 0 : index - 4
          number = index === 6 ? sample(range(2, 4, 1)) : sample([indexPre, index - 3])
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}`)
    cards.push(`${card}`)
  })
  return cards
}

export const renderConnectedCards = (allCards) => {
  const cards = []
  let card = sample(allCards)
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  console.log('connected')
  range(0,3,1).forEach((item) => {
    if (item > 0) {
      if (idx === 2 && allCards.length === 3) {
        number = item === 1 ? 1 : 0
      } else {
        switch (idx) {
          case 0:
            number = item === 1 ? 1 : 2
            break
          case 1:
            number = item === 1 ? 0 : 2
            break
          case 5:
            number = item === 1 ? 4 : 6
            break
          case 6:
            number = item === 1 ? 5 : 4
            break
          default:
            if (item === 1) {
              number = sample([idx - 1, idx + 1])
            } else {
              const index = number > idx ? number : idx
              number = index + 1
            }
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}`)
    cards.push(`${card}`)
  })
  return cards
}

export const renderJustRainbowCards = () => {
  const allCards = [...FULL_CARDS]
  const tonesClone = [...TONES]
  const cards = []
  range(0,3,1).forEach(() => {
    const card = randomCard(allCards)
    const tone = randomTone(tonesClone)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustFlushDrawCards = () => {
  const allCards = [...FULL_CARDS]
  const tonesClone = [...TONES]
  const tonesExists = []
  const cards = []
  range(0,3,1).forEach((item) => {
    const card = randomCard(allCards)
    let tone = sample(tonesClone)
    if (item === 2) {
      tone = sample(tonesExists)
    } else {
      tonesExists.push(tone)
      tonesClone.splice(tonesClone.findIndex(i => i === tone), 1)
    }
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustMonotoneCards = () => {
  const allCards = [...FULL_CARDS]
  const tone = sample(TONES)
  const cards = []
  range(0,3,1).forEach((item) => {
    const card = randomCard(allCards)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustCards = (option) => {
  const allCards = generateCards(option)
  const tonesClone = [...TONES]
  const cardExists = []
  const cards = []
  range(0,3,1).forEach((item) => {
    const card = sample(allCards)
    let tone = sample(tonesClone)
    if (cardExists.includes(card)) {
      const itemCard = cards.filter(i => i.slice(0, -1) === card)[0]
      const itemTone = itemCard.slice(-1)
      const newTones = xor(TONES, [itemTone])
      tone = sample(newTones)
    } else {
      cardExists.push(card)
    }
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustDisconnectedCards = (values=[]) => {
  if (!isEmpty(values)) {
    console.log('disconnected')
    return [2, 5, 8]
  }
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = sample(allCards)
  let idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      if (idx < 9 && idx > 3 ) {
        if (item === 1) {
          number = sample(range(0, idx - 3, 1))
        } else {
          number = sample(range(idx + 4, 13, 1))
        }
      } else if (idx < 4) {
        if (item === 1) {
          number = sample(range(idx + 4, 9, 1))
        } else {
          number = sample(range(number + 4, 13, 1))
        }
      } else if (idx > 8) {
        if (item === 1) {
          number = sample(range(5, idx - 3, 1))
        } else {
          number = sample(range(0, number - 3, 1))
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustMediumCards = () => {
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = sample(allCards)
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      if (idx === 0) {
        number = item === 1 ? 1 : sample(range(3, 5, 1))
      } else if (idx > 0 && idx < 3) {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          number = sample(range(index + 2, index + 4, 1))
        }
      } else if (idx > 9 && idx < 12) {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? idx : number
          number = sample(range(index - 3, index - 1, 1))
        }
      } else if (idx === 12) {
        number = item === 1 ? 11 : sample(range(8, 10, 1))
      } else {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          number = sample([index + 2, index - 3])
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustConnectedCards = () => {
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = sample(allCards)
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      number = positionCard(item, idx, number)
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

const positionCard = (item, idx, num) => {
  switch (idx) {
    case 0:
      return item === 1 ? 1 : 2
    case 1:
      return item === 1 ? 0 : 2
    case 11:
      return item === 1 ? 10 : 12
    case 12:
      return item === 1 ? 11 : 10
    default:
      if (item === 1) {
        return sample([idx - 1, idx + 1])
      } else {
        const index = num > idx ? num : idx
        return index + 1
      }
  }
}

export const renderJustNotPairedCards = () => {
  const allCards = [...FULL_CARDS]
  const cards = []
  range(0,3,1).forEach(() => {
    const card = randomCard(allCards)
    const tone = sample(TONES)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustPairedCards = (flushDraw=false) => {
  const allCards = [...FULL_CARDS]
  const tonesClone = [...TONES]
  const cardsSample = sampleSize(allCards, 2)
  const cardExists = flushDraw ? cardsSample.concat(sample(cardsSample)) : sampleSize(allCards, 3)
  const tonesExists = []
  const cards = []
  const itemDup = filter(cardExists, (val, i, iteratee) => includes(iteratee, val, i + 1))[0] || ''
  cardExists.forEach((card, index) => {
    let tone = sample(tonesClone)
    if (index === 2) {
      tone = getToneFromCardDup(cards, itemDup)
    } else {
      tone = randomTone(tonesClone)
      tonesExists.push(tone)
    }
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })

  return cards
}

export const renderJustTripsCard = () => {
  const allCards = [...FULL_CARDS]
  const card = sample(allCards)
  const tonesClone = [...TONES]
  const cards = []
  range(0,3,1).forEach((item) => {
    const tone = randomTone(tonesClone)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderSuitCard = (options) => {
  const { suit, card } = options
  let cards = generateCards(card)
  if (card === 'low') cards = sampleSize(cards, 3)
  return generateFlopCards(suit, cards)
}

export const renderSuitConnectedness = (options) => {
  const { suit, connectedness } = options
  let cards = renderJustCardConnectedness(connectedness)
  const sliceCards = cards.map(i => i.slice(0, -1))
  return generateFlopCards(suit, sliceCards)
}

export const renderSuitPaired = (options) => {
  const { suit, paired } = options
  let cards = []
  if (['rainbow', 'flush_draw'].includes(suit)) {
    switch (paired) {
      case 'not_paired':
        cards = renderJustNotPairedCards()
        break
      case 'paired':
        cards = renderJustPairedCards(true)
        break
      default:
        cards = renderJustTripsCard()
        break
    }
  } else {
    cards = renderJustNotPairedCards()
  }
  const sliceCards = cards.map(i => i.slice(0, -1))
  return generateFlopCards(suit, sliceCards)
}

export const renderCardConnectedness = (options) => {
  const { connectedness, card } = options
  let cards = generateCards(card)
  if (['high', 'mid'].includes(card)) {
    cards = renderConnectedCards(cards)
  } else {
    cards = renderCardConnnectedness(connectedness, cards)
  }
  return generateFlopCards(suitRandom(), cards)
}

export const renderCardPaired = (options) => {
  const { paired, card } = options
  const typeConnectRandom = sample(['disconnected', 'medium', 'connected'])
  switch (paired) {
    case 'not_paired':
      return renderNotPairedCards(card, suitRandom(), typeConnectRandom)
    case 'paired':
      return renderPairedCards(card, sample(['rainbow', 'flush_draw']), '')
    default:
      return renderTripsCard(card, 'rainbow', typeConnectRandom)
  }
}

export const renderConnectednessPaired = ({ connectedness }) => {
  return renderJustCardConnectedness(connectedness)
}

const renderCardConnnectedness = (connectedness, allCards) => {
  switch (connectedness) {
    case 'disconnected':
      return renderJustDisconnectedCards(allCards)
    case 'medium':
      return renderMediumCards(allCards)
    default:
      return renderConnectedCards(allCards)
  }
}

const renderJustCardConnectedness = (connectedness) => {
  switch (connectedness) {
    case 'disconnected':
      return renderJustDisconnectedCards()
    case 'medium':
      return renderJustMediumCards()
    default:
      return renderJustConnectedCards()
  }
}

export const generateFlopCards = (suit, cards) => {
  switch (suit) {
    case 'rainbow':
      return renderRainbowCards(cards)
    case 'flush_draw':
      return renderFlushDrawCards(cards)
    default:
      return renderMonotoneCards(cards)
  }
}




