import React, {Component, Fragment} from 'react'
import './style.scss'
import {
  Button,
  ButtonToolbar,
  Nav} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import Logo from 'components/OdinLogo/component'
import { refreshDecisionsTree } from 'actions/decision.actions'
import { refeshGameData } from 'actions/game.actions'
import {signOutUser} from 'actions/redux-token-auth'
import { updateCurrentUserAttributesToReduxTokenAuth } from 'actions/user.actions'
import { refeshHandMatrix } from 'actions/hand-matrix.actions'
import { userSubscriptionInvalid, fullAccessWithFreeAWeek } from 'utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import loadable from '@loadable/component'
import { isEmpty, isEqual, toLower } from 'lodash'
import { removeBot } from 'actions/bot.actions'
import axios from 'axios'

const ReportBugModal = loadable(() => import('components/Header/ReportBugModal/ReportBugModal'))
const PopupAuth = loadable(() => import('../BasicAuth/PopupAuth'))

const AUTH_HEADER_KEYS = [
  'access-token',
  'token-type',
  'client',
  'expiry',
  'uid',
]

class CHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReportBugModal: false,
      dropdownOpen: false,
      isShowMobileMenu: false,
      open: false,
      disabled: false
    }
    const { affiliate_code, createdAt, email } = props.data.attributes
    this.freeAWeek = fullAccessWithFreeAWeek(affiliate_code, createdAt, email)
    this.toggle = this.toggle.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
    this.handleLogout = this.handleLogout.bind(this)
    this.handleShowMobileMenu = this.handleShowMobileMenu.bind(this)
    this.handleResetData = this.handleResetData.bind(this)
    this.handleOpenTutorial = this.handleOpenTutorial.bind(this)
  }

  componentDidUpdate() {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  handleLogout() {
    const { attributes: user } = this.props.data;
    const headers = {}
    AUTH_HEADER_KEYS.forEach((key) => {
      headers[key] = localStorage.getItem(key)
    })
    const requestOne = window.axios.put(`/users/${user.id}/update_info_current_login`, {online: false})
    const requestTwo = window.axios.get('/auth/sign_out', headers)
    this.props.history.push('/') //Move to this page to trigger free tree request before call logout request
    this.props.removeBot()

    setTimeout(() => {
      window.axios.all([requestOne, requestTwo]).then(window.axios.spread((...response) => {
        localStorage.removeItem('first-sign-in')
        sessionStorage.removeItem('bot_strategy_selection')
        if (response[1].data && response[1].data.success) {
          AUTH_HEADER_KEYS.forEach((key) => localStorage.removeItem(key))
          this.props.updateCurrentUserAttributesToReduxTokenAuth()
        }
      }))
    }, 500)
  }

  handleShowMyAccount = () => {
    this.props.history.push('/my-account')
  }

  handleShowMobileMenu() {
    this.setState({isShowMobileMenu: !this.state.isShowMobileMenu})
  }

  handleSignup = () => {
    const pathname = window.location.pathname
    const userData = JSON.parse(localStorage.getItem('basicAuthData') || '{}')

    if (process.env.REACT_APP_BASIC_AUTH_ENVIRONMENT === 'production' ||
        !isEmpty(userData) ||
        isEqual(toLower(pathname), '/pokercode')) {
      this.props.history.push('signup')
    } else {
      this.setState({open: true})
    }
  }

  handleSignin = () => {
    const { history } = this.props
    const userData = JSON.parse(localStorage.getItem('basicAuthData') || '{}')
    if (process.env.REACT_APP_BASIC_AUTH_ENVIRONMENT === 'production') {
      history.push('/signin')
    } else {
      isEmpty(userData) ? history.push('/get-access') : history.push('/signin')
    }
  }

  handleOpenTutorial() {
    window.location.replace('/tutorial')
  }

  renderAuthentication(){
    const t = this.context.t;
    const { subscriptions: { code }, data } = this.props
    const { attributes: currentUser, isSignedIn } = data;
    const { emailConfirmed, subscription, alt_plan } = currentUser
    const link = code === 'pokercode' ? '/pokercode/subscribe' : '/subscribe'
    const shouldShowSubscribeButton = (isEmpty(subscription) || (subscription.status !== 'active' && subscription.status !== 'canceled')) && isEmpty(alt_plan)

    return isSignedIn && emailConfirmed ?
      <Fragment>
        <ButtonToolbar className='auth-controls fadeIn float-right'>
          <Button variant='default' className="tut-vids-btn btn-transparent font-size-16 text-effect" onClick={this.handleOpenTutorial}>{t('tutorials')}</Button>
        </ButtonToolbar>
        {
          shouldShowSubscribeButton && <div
            className='subscribe-now-btn mr-3'
            onClick={() => { this.props.history.push(link) }}
          >
            {t('subscribe.button')}
          </div>
        }
        <Nav className="header-nav-menu row">
          <NavLink to='/my-account' className="my-nav-link text-effect" onClick={this.handleShowMyAccount}>
            {t('my_account')}
          </NavLink>
          <NavLink to='#' className="my-nav-link text-effect" onClick={this.handleLogout}>
            {t('sign_out')}
          </NavLink>
        </Nav>
        <label htmlFor='hamburger-chk' className='hamburger-icon-label' onClick={this.handleShowMobileMenu}>
          <FontAwesomeIcon icon={faBars} className='hamburger-icon'/>
        </label>
      </Fragment>
    :
      <ButtonToolbar className='auth-controls fadeIn float-right'>
        <Button variant='default' className="tut-vids-btn btn-transparent" onClick={this.handleOpenTutorial}>{t('tutorials')}</Button>
        <Button variant='default' className="log-in-btn btn-transparent text-effect" onClick={this.handleSignin}>{t('log_in')}</Button>
        <Button className="sign-up-btn" onClick={this.handleSignup}>{t('signup')}</Button>
      </ButtonToolbar>
  }

  setModalShow(modalName) {
    this.setState({ showReportBugModal: modalName });
  }

  shouldHide(){
    let path = this.props.location.pathname
    return ['/signin', '/signup'].includes(path)
  }

  handleResetData() {
    this.props.refreshDecisionsTree()
    this.props.refeshGameData()
    this.props.refeshHandMatrix()
  }

  newTabReport = () => {
    if (!this.state.isShowMobileMenu)
      this.props.displayLoading()
    axios.get("/agg_reports/authenticate_user").then(res => {
      const data = res.data.data
      if(data['status'] === 1) {
        const { redirect_url } = data
        window.location.replace(redirect_url)
        if (this.state.isShowMobileMenu) {
          this.setState({ isShowMobileMenu: false})
        } else {
          this.props.hideLoading()
        }
      } else {
        console.log(data['message'])
      }
    })
  }

  renderHeaderMobile() {
    const t = this.context.t
    const { attributes: currentUser, isSignedIn } = this.props.data
    if (!currentUser)
      return null
    const { emailConfirmed, role, email } = currentUser
    const isAdmin = role === 'admin'
    const inValid = userSubscriptionInvalid(currentUser)

    return (
      <div className='header-mobile d-flex flex-column'>
          <Nav className="header-nav-menu-mobile">
              <p className='user-email'> {email} </p>
              <NavLink to='/my-account' className="my-nav-link text-effect" onClick={this.handleShowMobileMenu}>
                {t('my_account')}
              </NavLink>
            { 
              isSignedIn && emailConfirmed && (this.freeAWeek || !inValid) &&
              <Fragment>
                <NavLink to='strategy-select' className="my-nav-link text-effect" onClick={() => {this.handleResetData(); this.handleShowMobileMenu()}}>
                  {t('new_sim')}
                </NavLink>
                <NavLink to='/my-sims' className="my-nav-link text-effect" onClick={this.handleShowMobileMenu}>
                  {t('mysim')}
                </NavLink>
                {
                  isAdmin &&
                  <NavLink to='/admin' className="my-nav-link text-effect" onClick={this.handleShowMobileMenu}>
                    {t('admin_portal')}
                  </NavLink>
                }
                <Button disabled={this.state.disabled} className="my-nav-link text-effect header-button" onClick={this.newTabReport}>
                  {t('aggReports')}
                </Button>
              </Fragment>
            }
            { !isEmpty(currentUser) && isSignedIn && !this.freeAWeek && inValid &&
              <Fragment>
                <NavLink to='/strategy-select' className="my-nav-link text-effect" onClick={this.handleShowMobileMenu}>{t('free_preflop_sim')}</NavLink>
                <NavLink to='/free-postflop-page' className="my-nav-link text-effect" onClick={this.handleShowMobileMenu}>{t('free_postflop_sims')}</NavLink>
              </Fragment>
            }
            <p className='w-100 btn-sign-out' onClick={this.handleLogout}> {t('sign_out')} </p>
          </Nav>
      </div>
    )
  }

  render(){
    if (this.shouldHide()){
      return <React.Fragment />
    }

    const t = this.context.t
    const { attributes: currentUser, isSignedIn } = this.props.data
    if (!currentUser)
      return null
    const { emailConfirmed, role } = currentUser
    const isAdmin = role === 'admin'
    const inValid = userSubscriptionInvalid(currentUser)
    const { isShowMobileMenu, open, disabled } = this.state
    return <div className="header-container">
      <PopupAuth
        open={open}
        onClose={() => this.setState({ open: false })}
      />
      <div className='padding-content mx-auto view-port-width header-content-container'>
        <div className='header row no-gutters'>
          <div className="header-menu row no-gutters">
            <div className="header-logo">
              <Logo className='' />
            </div>
            { isSignedIn && emailConfirmed && (this.freeAWeek || !inValid) &&
              <Nav className="header-nav-menu row">
                <NavLink to='strategy-select' className="my-nav-link text-effect" onClick={this.handleResetData}>
                  {t('new_sim')}
                </NavLink>
                <NavLink to='/my-sims' className="my-nav-link text-effect">
                  {t('mysim')}
                </NavLink>

                <NavLink to='/trainer-selection' className="my-nav-link text-effect">{t('playTheBot')}</NavLink>
                {
                  isAdmin &&
                  <NavLink to='/admin' className="my-nav-link text-effect">
                    {t('admin_portal')}
                  </NavLink>
                }
                <Button disabled={disabled} className="header-button my-nav-link text-effect" onClick={this.newTabReport}>
                  {t('aggReports')}
                </Button>
              </Nav>
            }
            { !isEmpty(currentUser) && isSignedIn && !this.freeAWeek && inValid &&
              <Nav className="header-nav-menu row">
                <NavLink to='/strategy-select' className="my-nav-link text-effect">{t('free_preflop_sim')}</NavLink>
                <NavLink to='/free-postflop-page' className="my-nav-link text-effect">{t('free_postflop_sims')}</NavLink>
                <NavLink to='/trainer-selection' className="my-nav-link text-effect">{t('playTheBot')}</NavLink>
              </Nav>
            }
          </div>
          <div className="header-btn text-right">
            {this.renderAuthentication()}
          </div>
        </div>
      </div>
      <input type='checkbox' className='hamburger-chk' id='hamburger-chk' />
      {
        isSignedIn && emailConfirmed && isShowMobileMenu &&
        <div className={`header-mobile-container ${isShowMobileMenu && 'show'}`}>
          {this.renderHeaderMobile()}
        </div>
      }

      <ReportBugModal
        isShow={this.state.showReportBugModal === 'report_bug'}
        setModalShow={this.setModalShow}
      />
    </div>
  }
}

CHeader.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  i18nState: store.i18nState,
  data: store.reduxTokenAuth.currentUser,
  subscriptions: store.subscriptions,
})

const mapDispatchToProps = {
  refeshGameData,
  refreshDecisionsTree,
  signOutUser,
  updateCurrentUserAttributesToReduxTokenAuth,
  refeshHandMatrix,
  removeBot
}

const Header = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CHeader)

export default withRouter(Header)
