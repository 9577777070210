import React, { Component } from 'react'
import './stylesheet.scss'
import Logo from 'components/OdinLogo/component'
import { NavLink } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import {connect} from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { EMAILS_ABLE_TO_VIEW_BLACK_DESIGN } from 'config/constants'
import discordLogo from 'images/Logos/discord_logo_white.svg'

class CFooter extends Component {

  isShouldHide() {
    let path = this.props.location.pathname
    return ['/', '/signin', '/signup'].includes(path)
  }

  render() {
    let t = this.context.t
    let { email } = this.props.currentUser;
    let backgroundImageClass = EMAILS_ABLE_TO_VIEW_BLACK_DESIGN.includes(email) ? 'footer-black-bg-image' : 'footer-bg-image';

    return (
      <div className='app-footer'>
        <div className={`footer ${backgroundImageClass}`}>
          <div className='padding-content footer-content-container'>
            <div className='footer-content row no-gutters justify-content-between'>
              <div className='content-left col-5'>
                <div className='logo'>
                  <Logo />
                </div>
                <div className='footer-description'></div>
              </div>
              <div className='content-right col-7 row'>
                <Nav className="list-content col-2 col-md-4 flex-column">
                </Nav>
                <Nav className="list-content col-10 col-md-8 flex-column">
                  <NavLink to='/terms-of-service' className="">
                    {t('termCondition')}
                  </NavLink>
                  <NavLink to='/privacy-policy' className="">
                    {t('privatePolicy')}
                  </NavLink>
                  <NavLink to='/contact-us' className="">
                    {t('contactUs')}
                  </NavLink>
                </Nav>
              </div>
            </div>
            <div className="seperate"></div>
            <div className="footer-bottom row no-gutters justify-content-between align-items-center">
              <div className='text-half-transparent'>
               <p> {t('companyName')} </p>
               <p> {t('companyAddress')} </p>
              </div>
              <div className='mb-0 contact-link'>
                <a href={t('contact.discord_link')} target='blank' className='join-discord text-primary-color text-decoration-none'>
                  {t('join_our_discord')}
                  <div className="discord-logo-container">
                    <img src={discordLogo} alt='discord-logo' className='h-100 discord-logo' />
                  </div>
                </a>
                <span className='mail-address'>
                  <a href={`mailto:${t('odin_support_email')}`}> {t('odin_support_email')} </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CFooter.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser.attributes
})

const mapDispatchToProps = {
}

const Footer = connect(
  mapStoreToProps,
  mapDispatchToProps
)(CFooter)

export default withRouter(Footer)
