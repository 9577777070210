import React, { Component } from 'react'
import './ConfirmDialog.scss'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'

class CConfirmDialog extends Component {

  render() {
    let { isShown, agreeBtnText, denyBtnText, dialogMessage, handleClose, handleAgree, disableDenyBtn } = this.props;

    return (
      <Modal
        show={isShown}
        onHide={handleClose}
        dialogClassName='confirm-dialog'
        aria-labelledby='confirm-dialog'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>{dialogMessage}</Modal.Body>
        <Modal.Footer>
          { !disableDenyBtn && (
              <Button variant="secondary" onClick={handleClose}>
                {denyBtnText}
              </Button>
            )}
          <Button variant="primary" onClick={handleAgree}>
            {agreeBtnText}
          </Button>
        </Modal.Footer>
      </Modal>    )
  }
}

CConfirmDialog.contextTypes = {
  t: PropTypes.func
}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser.attributes,
})

const mapDispatchToProps = {
}

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(CConfirmDialog)

