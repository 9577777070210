import { userActions } from '../constants/index';

export function userData(state={}, action) {
  switch (action.type) {
    case userActions.STORE_USER_DATA:
      return {
        ...state,
        ...action.payload
      }
    case userActions.CLEAR_USER_DATA:
      return {};
    case userActions.STORE_VALIDATION_MESSAGES:
      return {
        ...state,
        validation_messages: action.payload,
      };
    default:
      return state;
  }
}
