import { isEmpty, isEqual, map, cloneDeep, findIndex, sum, max } from 'lodash';
import {filterCombosBySuit} from 'services/filter_combos_by_suit_service'
import { COMBOS } from 'config/constants'
import { convertHandCards } from 'services/convert_cards_services'

export const handCombinationsGenerator = (hand) => {
  const firstLetter = hand[0]
  const secondLetter = hand[1]
  const isSameType = isEmpty(hand[2]) && isEqual(firstLetter, secondLetter)
  if(isSameType) {
    return [
      {index: 1, first: {rank: firstLetter, suit: 's'}, second: {rank: firstLetter, suit: 'h'}},
      {index: 2, first: {rank: firstLetter, suit: 's'}, second: {rank: firstLetter, suit: 'd'}},
      {index: 3, first: {rank: firstLetter, suit: 'h'}, second: {rank: firstLetter, suit: 'd'}},
      {index: 4, first: {rank: firstLetter, suit: 's'}, second: {rank: firstLetter, suit: 'c'}},
      {index: 5, first: {rank: firstLetter, suit: 'h'}, second: {rank: firstLetter, suit: 'c'}},
      {index: 6, first: {rank: firstLetter, suit: 'd'}, second: {rank: firstLetter, suit: 'c'}},
    ]
  } else if(isEqual(hand[2], 's')) {
    return [
      {index: 1, first: {rank: firstLetter, suit: 's'}, second: {rank: secondLetter, suit: 's'}},
      {index: 2, first: {rank: firstLetter, suit: 'h'}, second: {rank: secondLetter, suit: 'h'}},
      {index: 3, first: {rank: firstLetter, suit: 'd'}, second: {rank: secondLetter, suit: 'd'}},
      {index: 4, first: {rank: firstLetter, suit: 'c'}, second: {rank: secondLetter, suit: 'c'}},
    ]
  } else {
    return [
      {index: 1, first: {rank: firstLetter, suit: 's'}, second: {rank: secondLetter, suit: 'h'}},
      {index: 2, first: {rank: firstLetter, suit: 's'}, second: {rank: secondLetter, suit: 'd'}},
      {index: 3, first: {rank: firstLetter, suit: 's'}, second: {rank: secondLetter, suit: 'c'}},
      {index: 4, first: {rank: firstLetter, suit: 'h'}, second: {rank: secondLetter, suit: 's'}},
      {index: 5, first: {rank: firstLetter, suit: 'h'}, second: {rank: secondLetter, suit: 'd'}},
      {index: 6, first: {rank: firstLetter, suit: 'h'}, second: {rank: secondLetter, suit: 'c'}},
      {index: 7, first: {rank: firstLetter, suit: 'd'}, second: {rank: secondLetter, suit: 's'}},
      {index: 8, first: {rank: firstLetter, suit: 'd'}, second: {rank: secondLetter, suit: 'h'}},
      {index: 9, first: {rank: firstLetter, suit: 'd'}, second: {rank: secondLetter, suit: 'c'}},
      {index: 10, first: {rank: firstLetter, suit: 'c'}, second: {rank: secondLetter, suit: 's'}},
      {index: 11, first: {rank: firstLetter, suit: 'c'}, second: {rank: secondLetter, suit: 'h'}},
      {index: 12, first: {rank: firstLetter, suit: 'c'}, second: {rank: secondLetter, suit: 'd'}},
    ]
  }
}

const positionsClass = () => {
  return {
    12: { 3: 'top-right', 8: 'bottom-left', 11: 'bottom-right' },
    6: { 2: 'top-right', 3: 'bottom-left', 5: 'bottom-right' },
    4: { 1: 'top-right', 2: 'bottom-left', 3: 'bottom-right'}
  }
}

export const getPositionClass = (matrixLength, index) => {
  const positionClass = index === 0 ? 'top-left' : ''
  const pClass = positionsClass()[matrixLength][index]
  if (isEmpty(pClass))
    return positionClass
  return pClass
}

export const combosGenerator = (hand) => {
  let combos = []
  handCombinationsGenerator(hand).forEach(combination => {
    const {first, second} = combination
    combos = combos.concat(first.rank + first.suit + second.rank + second.suit)
  })
  return combos
}

export const calcAvgStrategy = (combos, strategyForEachCombos, combosWithIndex = {}, game = {}, weightedStrategy, view) => {
  const { currentPosition, ranges_ip, ranges_oop } = game
  const avgStrategy = {}
  const strategyForEachCombosCopy = cloneDeep(strategyForEachCombos)
  const nodes = map(strategyForEachCombosCopy[`${combos[0]}`], (value, key) => key)
  const total = {}
  nodes.forEach(node => {
    let sum = 0
    combos.forEach(combo => {
      const valueFloat = ['', 'strategy-ev'].includes(view) ? parseFloat(strategyForEachCombosCopy[combo][node]['rounded']) : parseFloat(strategyForEachCombosCopy[combo][node])
      if (!weightedStrategy) {
        sum = sum + valueFloat
      } else {
        const ranges = isEqual(currentPosition, 'ip') ? ranges_ip : ranges_oop
        const index = combosWithIndex[combo]
        const value = roundValueToNearest(parseFloat(ranges.split(' ')[index]), 0.05)
        if (value > 0) {
          sum = sum + valueFloat
        } else {
          const comboData = strategyForEachCombosCopy[combo]
          const rounded = view === '' ? {'basic': '0', 'rounded': '0'} : '0'
          comboData[node] = rounded
          strategyForEachCombosCopy[combo] = comboData
        }
      }
    })
    total[`${node}`] = sum
  })
  let num = combos.filter(combo => calcSumStrat(strategyForEachCombosCopy[combo], nodes, view) > 0 ).length
  if (num === 0) num = 1
  Object.keys(total).forEach(i => {
    const value = total[i] / num
    const rounded = ['', 'strategy-ev'].includes(view) ? {'basic': value, 'rounded': value} : value
    return avgStrategy[i] = rounded
  })
  return avgStrategy
}

export const calcSumStrat = (strategyForEachCombo, nodes, view) => {
  let tempSum = 0
  nodes.forEach(node => {
    const value = ['', 'strategy-ev'].includes(view) ? parseFloat(strategyForEachCombo[node]['rounded']) : parseFloat(strategyForEachCombo[node])
    tempSum += value
  })
  return tempSum
}

export const buildItemDisplayForRangeMatrix = (handItem, suitsForFilter) => {
  const firstLetter = handItem[0]
  const secondLetter = handItem[1]
  const isSameType = !isEmpty(handItem[2]) && isEqual(firstLetter, secondLetter)
  const { included } = suitsForFilter
  const filteredCombos = filterCombosBySuit(handItem, combosGenerator(handItem), suitsForFilter)

  if(filteredCombos.length === 1) {
    const combo = filteredCombos[0]
    return handDisplayTemplate(firstLetter, secondLetter, combo[1], combo[3])
  } else {
    if(isSameType) {
      return filteredCombos.length === 2 || filteredCombos.length === 3 ? buildDisplayForSameType(firstLetter, secondLetter, included, filteredCombos) : ''
    } else if(handItem[2] === 's') {
      return ''
    } else if(handItem[2] === 'o') {
      return filteredCombos.length === 2 || filteredCombos.length === 3 ? buildDisplayForOffSuited(firstLetter, secondLetter, included, filteredCombos) : ''
    }
  }
}

 const buildDisplayForSameType = (firstLetter, secondLetter, included, filteredCombos) => {
  const { first, second } = included
  const suit = first || second
  if (suit) {
    return handDisplayTemplate(firstLetter, secondLetter, suit, '')
  }
  return ''
}

const buildDisplayForOffSuited = (firstLetter, secondLetter, included, filteredCombos) => {
  const { first, second } = included
  if(first && !second) {
    return handDisplayTemplate(firstLetter, secondLetter, first, '')
  } else if(!first && second) {
    return handDisplayTemplate(firstLetter, secondLetter, '', second)
  }
}

const handDisplayTemplate = (firstLetter, secondLetter, suitFirst, suitSecond) => {
  return {
    first: {rank: firstLetter, suit: suitFirst},
    second: {rank: secondLetter, suit: suitSecond}
  }
}

export const roundValueToNearest = (num, precision) => { //use to round value with nearest precision
  num = parseFloat(num)
  if(!precision || precision === 0 ) return num
  return Math.round(num / precision) * precision
}
// This func and the convertHandCards service need to refactor
// Complexity of the algorithm is pretty high and this func loop the calculate value too much
export const renderCombosWithIndexToRedux = (flopCards) => {
  const combosWithIndex = {}
  COMBOS.forEach((combo) => {
    const convertedCombo = convertHandCards(flopCards, combo)
    return combosWithIndex[`${combo}`] = findIndex(COMBOS, (c) => isEqual(c, convertedCombo))
  })
  return combosWithIndex
}

export const renderDataComboRouded = (strategy) => {
  const value = strategy
  const keys = Object.keys(strategy)
  const roundeds = keys.map(item => parseFloat(strategy[item]['rounded']))
  const totalRounded = sum(roundeds)
  const distanceRounded = {}
  keys.map(item => distanceRounded[item] = Math.abs(parseFloat(strategy[item]['rounded']) - parseFloat(strategy[item]['basic'])))
  const maxDistanceRounded = max(Object.values(distanceRounded))
  const nodeMaxDistanceRounded = keys.find(key => distanceRounded[key] === maxDistanceRounded)
  if (totalRounded > 0) {
    keys.forEach(item => {
      const temp = 1 - totalRounded
      const round = strategy[item]['rounded']
      const rounded = { 'basic': strategy[item]['basic'], 'rounded': nodeMaxDistanceRounded === item ? parseFloat(round) + temp : round }
      value[item] = rounded
    })
  }
  return value
}
