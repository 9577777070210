import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { i18nState } from "redux-i18n"
import { reduxTokenAuthReducer } from 'redux-token-auth'
import reducers from "../reducers";

export default configureStore({
  reducer: {
    i18nState,
    reduxTokenAuth: reduxTokenAuthReducer,
    ...reducers
  },
  middleware: getDefaultMiddleware().concat(logger)
});
