export const updateBot = (bot) => {
  return {
    type: 'UPDATE_BOT',
    payload: bot
  }
}

export const addHoldcards = (value) => {
  return {
    type: 'ADD_HOLDCARDS',
    payload: value
  }
}

export const removeBot = (bot) => {
  return {
    type: 'REMOVE_BOT'
  }
}
